import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Login } from '../Auth/Login';
import { Link } from "react-router-dom";
import { dateFormation, getLocalStringNumber, isEmpty } from '../../Utils';
import { siteActions, userActions } from '../../store';
import { LogoutModal } from '../Common/modal';
import { resource_url } from '../../Utils/keys';

export { SideBar };
function SideBar() {

    const mainPageList = useSelector(state => state.site.mainPageList)
    const mainBannerImages = useSelector(state => state.site.mainBannerImages)
    const noticeLists = mainPageList.noticeLists
    const RecentArticleLists = mainPageList.RecentArticleLists
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const userData = useSelector(state => state.user.data)
    const dispatch = useDispatch();
    const [showLogout, setShowLogout] = useState(false);
    const onLogout = () => {
        setShowLogout(!showLogout)
    }

    const logout = () => {
        dispatch(userActions.logout());
        setShowLogout(!showLogout);
    }

    const getList = () => {
        dispatch(siteActions.getMainPageList());
        dispatch(siteActions.getMainBannerImages());
    }
    useEffect(() => {
        getList();
    }, []);
    return (
        <div className="sidebar_area">
            <div className="sidebar">
                {/************************사이드바 시작**************************** */}
                <div className="point_banner">
                    <Link to="/liveevent" title="이벤트 게시판 바로가기"><img src="../imgs/event_banner.gif" alt="이벤트 게시판이 추가되었습니다." /></Link>
                </div>
                <div className="latest_board">
                    <div className="tit_area">

                        <div className="tit">
                            <img src='../imgs/login/layer10.png' alt='notice' />
                            <span className="txt" style={{ marginLeft: "10px" }}>공지사항</span>
                        </div>
                    </div>
                    <ul>

                        <div className="main_box1 ">
                            <div className="table-box">
                                <ul>
                                    {!isEmpty(noticeLists) ?

                                        noticeLists.map((list, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="txt">
                                                        <Link to="/notice" title={list.title}>{list.title}</Link>
                                                        {/* <span className="latest_comment_count"> +{isEmpty(list.answerCnt) ? 0 : list.answerCnt}</span> */}
                                                    </div>
                                                    <div className="date">{dateFormation(list.created_date, 4)}</div>
                                                </li>
                                            )
                                        })
                                        :
                                        <li>
                                            <div className="txt">
                                                <div className="date" style={{ float: "left" }}>내역이 없습니다.</div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div>
                {/***********************login start***************************** */}
                {!isAuthenticated &&
                    <Login />
                }
                {/********************login end****************** */}
                {/**********************welcome start********************** */}
                {isAuthenticated &&
                    <div className="welcome">
                        <div className="headline">
                            <div className="nickname">
                                <div className="txt">
                                    <span><div className="user_levelname"><span className="lv_icon_box"><div className={`lv_icon lv_icon${userData.level > 6 ? 1 : userData.level}`}></div></span><span className="user_name">{userData.name}</span></div></span>
                                    <span style={{ paddingTop: "3px" }}>님 환영합니다</span>
                                    <span style={{ paddingTop: "3px" }}><Link to="/notification" className="msg_icon" title="쪽지함"><i className="fa-solid fa-envelope" aria-hidden="true"></i></Link></span>
                                    <button className="logout_icon" title="로그아웃" onClick={onLogout}>로그아웃</button>
                                </div>

                            </div>
                            <div className="currentvisitor">
                                <strong>포인트</strong>&nbsp;
                                <Link to="/point" className="admin_login" title="나의 포인트" id="mem_point">
                                    {getLocalStringNumber(userData.point)}
                                </Link>
                                &nbsp;<strong>골드</strong>
                                &nbsp;
                                <Link to="/pointchange" className="admin_login" title="포인트전환">{getLocalStringNumber(Math.floor(userData.gold))}</Link>
                                &nbsp;<strong>빅휠이용권</strong>
                                &nbsp;
                                <Link to="/roulette" className="admin_login" title="빅휠">{userData.bigwhellCnt}</Link>
                            </div>
                        </div>
                        <div className="bigwheel_img">
                            <Link to="/roulette" title="출석 빅휠 이벤트 바로가기">
                                <img src="../imgs/bt_bigwheel.gif" alt="빅휠이벤트" style={{ width: "100%" }} />
                            </Link>
                        </div>
                        <Link to="/pointmall" title="포인트몰"><div className="point_mall_btn">POINT MALL (포인트몰)</div></Link>
                        <ul>
                            <li><Link to="/mypage" title="마이페이지" className="menu">마이페이지</Link></li>
                            <li><Link to="/attendance" title="출석체크" className="menu"><div className="attendance_icon"><i className="fas fa-user-check" aria-hidden="true"></i></div>출석체크</Link></li>
                            <li><Link to="/notice" title="공지" className="menu"><div className="attendance_icon badge notification_num"></div>공지</Link></li>
                            <li><Link to="/couponhistory" title="쿠폰내역" className="menu">쿠폰내역</Link></li>
                        </ul>
                    </div>}
                {/************************sidebar end*****************************/}



                {/* <div className="latest_board event_winner_latest_board">
                    <div className="tit_area">
                        <div className="tit">
                            <span className="txt">이벤트 당첨자</span>
                        </div>
                    </div>
                    <div className="arrow_box">
                        <span className="left"><i className="fa-sharp fa-solid fa-square-chevron-left" aria-hidden="true"></i></span>
                        <span className="right"><i className="fa-sharp fa-solid fa-square-chevron-right" aria-hidden="true"></i></span>
                    </div>
                    <div className="main_box main_box1" >
                        <div className="table-box side_event_winner">

                            <div className="tit">리뷰이벤트&nbsp;&nbsp;<font style={{ color: "#3bc117", fontSize: "11px" }}>(04월01일 당첨)</font></div>
                            <ul className="event-winners">
                                <li>
                                    <span className="name">🏆 돈조항</span>
                                    <span className="item">빅휠 1회권</span>
                                </li>
                                <li>
                                    <div className="none_conts">당첨자가 없습니다</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="main_box main_box2" style={{ display: "none" }}>
                        <div className="table-box side_event_winner">
                            <div className="tit">맥스윈3월이벤트&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(04월01일 당첨)</font></div>
                            <ul className="event-winners">
                                <li>
                                    <span className="name">🏆 큰우주</span>
                                    <span className="item">100만 포인트</span>
                                </li>

                                <li>
                                    <div className="none_conts">당첨자가 없습니다</div>
                                </li>

                            </ul>
                        </div>
                    </div><div className="main_box main_box3" style={{ display: "none" }}>	<div className="table-box side_event_winner">
                        <div className="tit">크리스마스이벤트&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(12월26일 당첨)</font></div>
                        <ul className="event-winners">
                            <li>
                                <span className="name">🏆 가즈아앗</span>
                                <span className="item">10만포인트</span>
                            </li>

                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>

                            <li>
                                <span className="name">🏆 어화둥둥</span>
                                <span className="item">10만포인트</span>
                            </li>

                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>

                        </ul>
                    </div>
                    </div><div className="main_box main_box5" style={{ display: "none" }}>	<div className="table-box side_event_winner">
                        <div className="tit">탑3이벤트&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(01월15일 당첨)</font></div>
                        <ul className="event-winners">
                            <li>
                                <span className="name">🏆 가두리</span>
                                <span className="item">100만 포인트</span>
                            </li>

                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>

                        </ul>
                    </div>
                    </div><div className="main_box main_box6" style={{ display: "none" }}>	<div className="table-box side_event_winner">
                        <div className="tit">미션 임파서블_미션1&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(02월16일 당첨)</font></div>
                        <ul className="event-winners">
                            <li>
                                <span className="name">🏆 가두리</span>
                                <span className="item">100만 포인트 지급</span>
                            </li>
                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>

                        </ul>
                    </div>
                    </div><div className="main_box main_box7" style={{ display: "none" }}>	<div className="table-box side_event_winner">
                        <div className="tit">미션 임파서블_미션2&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(02월16일 당첨)</font></div>
                        <ul className="event-winners">
                            <li>
                                <span className="name">🏆 철통</span>
                                <span className="item">200만 포인트 지급</span>
                            </li>

                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>
                        </ul>
                    </div>
                    </div><div className="main_box main_box9" style={{ display: "none" }}>	<div className="table-box side_event_winner">
                        <div className="tit">탑배당이벤트&nbsp;&nbsp;<font style={{ color: "#ff7916", fontSize: "11px" }}>(04월01일 당첨)</font></div>
                        <ul className="event-winners">
                            <li>
                                <span className="name">🏆 뚠뚠이</span>
                                <span className="item">300만 포인트</span>
                            </li>
                            <li>
                                <div className="none_conts">당첨자가 없습니다</div>
                            </li>
                        </ul>
                    </div>
                    </div>
                </div> */}


                {/* <div className="latest_board">
                    <div className="tit_area">
                        <div className="tit">
                            <img src='../imgs/login/layer11.png' alt='notice' />
                            <span className="txt" style={{ marginLeft: "10px" }}>최근게시글</span>
                        </div>
                    </div>
                    <ul>
                        <div className="main_box1 ">
                            <div className="table-box">
                                <ul>
                                    {!isEmpty(RecentArticleLists) ?
                                        RecentArticleLists.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="txt">
                                                        <Link to="/freearticle" title={item.title}>{item.title}</Link>
                                                    </div>
                                                    <div className="date">{dateFormation(item.created_date, 4)}</div>
                                                </li>
                                            )
                                        })
                                        :
                                        <li>
                                            <div className="txt">
                                                내역이 없습니다.
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div> */}

                <div className="telegram">
                    <ul>
                        {/* <li><a href="https://t.me/slotCT" title="슬롯모아 텔레그램 바로가기">slotCT</a></li> */}
                        <li>pgslot-xx.com</li>
                    </ul>
                </div>

                <div className="point_banner">
                    {mainBannerImages &&
                        mainBannerImages.map((item, index) => {
                            return (
                                <div className='main-banner-images' key={index} title={item.title}><img src={`${resource_url}/${item.url}`} alt={item.title} /></div>
                            )
                        })
                    }
                </div>
                {/****************사이드바 끝******************* */}

            </div>
            {showLogout &&
                <LogoutModal showLogout={showLogout} onLogout={onLogout} logout={logout} />
            }
            {

            }
        </div>
    );


}