import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from '../../store';
import { Pagination } from '../Common';
import { dateFormation, isEmpty } from '../../Utils';

export { MyLogsContent };
function MyLogsContent() {
    const dispatch = useDispatch();
    const loginHistory = useSelector(state => state.user.loginHistory);
    const userData = useSelector(state => state.user.data);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
            loginId: userData.loginId,
        }
        dispatch(userActions.getLoginHistory(params));
    }
    useEffect(() => {
        if (userData.loginId) {
            getList();
        }
    }, [userData]);



    return (
        <>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center' }}>로그인여부</th>
                        <th style={{ textAlign: 'center' }}>IP</th>
                        <th style={{ textAlign: 'center' }}>날짜</th>
                        <th style={{ textAlign: 'center' }}>사유</th>
                    </tr>
                </thead>
                <tbody>
                    {loginHistory &&
                        loginHistory.map((list, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <span
                                            className={`label ${list.status === 2 ? "label-success" : (list.memo === "로그아웃되었습니다." ? "label-warning" : "label-danger")}`}
                                            style={{ color: list.status === 2 ? "#3bc11c" : (list.memo === "로그아웃되었습니다." ? "#ffbd20" : "#d72a30") }}
                                        >
                                            {list.status === 2 ? "로그인성공" : (list.memo === "로그아웃되었습니다." ? "로그아웃" : "로그인실패")}
                                        </span>
                                    </td>
                                    <td>{list.ip_address}</td>
                                    <td>{dateFormation(list.created_date, 9)}</td>
                                    <td>{list.memo}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
                {
                    isEmpty(loginHistory) &&
                    <tbody>
                        <tr>
                            <td colSpan="4" className="nopost">내역이 없습니다</td>
                        </tr>
                    </tbody>
                }
            </table>
            {!isEmpty(loginHistory) &&
                <Pagination pageInfo={pageInfo} getList={getList} />
            }
        </>
    );
}
