import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from '../../store';
import { Pagination } from '../Common';
import { dateFormation, isEmpty } from '../../Utils';

export { PointContent };

function PointContent() {

    const dispatch = useDispatch();
    const pointHistory = useSelector(state => state.user.pointHistory);
    const userData = useSelector(state => state.user.data);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(userActions.getPointHistory(params));
    }
    useEffect(() => {
        if (userData.loginId) {
            getList();
        }
    }, [userData]);

    let totalUsedPoint = 0;
    pointHistory.forEach(element => {
        if (element.amount < 0) {
            totalUsedPoint += Math.abs(element.amount)
        }
    });

    return (
        <>
            <div className="page-header">
                <h4>포인트 내역 <small>보유 포인트 : {userData.point}점</small></h4>
            </div>

            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th style={{ whiteSpace: "nowrap" }}>번호</th>
                        <th style={{ whiteSpace: "nowrap" }}>일시</th>
                        <th style={{ whiteSpace: "nowrap" }}>내용</th>
                        <th style={{ whiteSpace: "nowrap" }}>지급 포인트</th>
                        <th style={{ whiteSpace: "nowrap" }}>사용 포인트</th>
                    </tr>
                </thead>
                <tbody>
                    {pointHistory &&
                        pointHistory.map((list, index) => {
                            return (
                                <tr key={index}>
                                    <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                    <td>{dateFormation(list.date, 9)}</td>
                                    <td>{list.memo.description}</td>
                                    <td><span className="label label-success">{list.amount >= 0 && `+${list.amount}`}</span></td>
                                    <td>{list.amount < 0 && Math.abs(list.amount)}</td>
                                </tr>
                            )
                        })
                    }

                    <tr className="success" style={{ color: "rgb(159 31 235)" }}>
                        <td>소계</td>
                        <td></td>
                        <td></td>
                        <td>+{userData.point}</td>
                        <td>{totalUsedPoint}</td>
                    </tr>
                </tbody>
            </table>
            <Pagination pageInfo={pageInfo} getList={getList} />
        </>
    );
}
