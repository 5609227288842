import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { LeaveContent, MyPageMenu } from '../../Components/Account';

export { Leave };

function Leave() {
    return (
        <Fragment>
            <Navbar type="leave" />
            <div className='main'>
                <MyPageMenu>
                    <LeaveContent />
                </MyPageMenu>
            </div>
        </Fragment>
    );
}
