import React, { useCallback, useState, useMemo, useRef, } from 'react';
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";
import { isEmpty, toastr } from '../../Utils';
import { resource_url } from '../../Utils/keys';

export { Edit };


const Edit = (props) => {
    const { showModal, onShowModal, onEditComplete, titleType, type, logoList } = props;

    const logoImages = isEmpty(logoList) ? [] : logoList

    const [inputs, setInputs] = useState({
        articleList: "",
        title: "",
        titleImg: null,
        siteImg: "",
    });
    const { articleList, title, titleImg, siteImg, } = inputs;

    const quill = useRef();

    const onSendArticle = (e) => {
        e.preventDefault();
        if (isEmpty(title)) {
            return toastr.warning("제목을 입력해주세요.")
        }
        if (isEmpty(titleImg)) {
            return toastr.warning("이미지를 선택해주세요.")
        }
        if (isEmpty(type != "freeArticle" && siteImg)) {
            return toastr.warning("사이트이미지를 선택해주세요.")
        }
        if (articleList === "<p><br></p>") {
            return toastr.warning("내용을 입력해주세요.")
        }
        onEditComplete(inputs)
    }

    const imageHandler = useCallback(() => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // When a file is selected
        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            // Read the selected file as a data URL
            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                // Get the current selection range and insert the image at that index
                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];

    // ------------------------------------------------- //
    return (
        <div className="main_area">
            <div className="board">
                <h3>{titleType}</h3>
                <div className="form-horizontal" name="fwrite" id="fwrite"
                    encType="multipart/form-data" method="post" acceptCharset="utf-8"
                    noValidate="novalidate">

                    <div className="form-horizontal box-table">
                        <div className="form-group">
                            <div className="col-sm-12" style={{ display: "flex" }}>
                                <label htmlFor="post_title" className="control-label">제목</label>
                                <div className="" style={{ display: "table", width: "100%", marginLeft: "15px" }}>
                                    <input type="text" className="form-control"
                                        name="post_title" id="post_title" value={title} onChange={(e) => setInputs({ ...inputs, title: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group edit-form-group">
                            <div className="col-sm-12" style={{ display: "flex" }}>
                                <label htmlFor="post_title" className="control-label">이미지</label>
                                <div className=" position-relative d-flex justify-content-start ml40" style={{ width: "50%" }}>
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg, .gif, .webp"
                                        style={{ display: "none" }}
                                        id="titleImg"
                                        name="titleImg"
                                        onChange={(e) => {
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                const imageUrl = reader.result;
                                                setInputs({ ...inputs, titleImg: imageUrl });
                                            }
                                            reader.readAsDataURL(e.target.files[0]);
                                        }} />
                                    <label htmlFor='titleImg'
                                        className="cursor-pointer"
                                        style={{
                                            width: "50%", aspectRatio: "3/2",
                                            position: "relative", borderRadius: "5px",
                                            backgroundColor: !isEmpty(titleImg) ? "unset" : "rgb(31 34 55)",
                                            backgroundImage: !isEmpty(titleImg) && `url(${titleImg})`,
                                            backgroundSize: "contain", backgroundPosition: "center",
                                            border: "1px solid #313557",
                                            backgroundRepeat: "no-repeat"
                                        }}
                                    >
                                        <i className="fa fa-pencil" style={{ position: "absolute", right: "-7px", top: "-7px", borderRadius: "50%", backgroundColor: "rgb(181 57 255 / 21%)", color: 'rgb(159 31 235)', padding: "5px", zIndex: 1, }}></i>
                                    </label>
                                </div>
                            </div>
                            {type === "slotGameReview" &&
                                <div className="col-sm-12 edit-form-group-content " style={{ display: "flex" }}>
                                    <div className="board_form_box edit-board_form_box">
                                        <div className="array_area">
                                            <select
                                                name="siteImg"
                                                value={siteImg}
                                                onChange={(e) => setInputs({ ...inputs, siteImg: e.target.value, })}
                                            >
                                                <option value="" style={{ display: "none" }}>사이트이미지...</option>
                                                {logoImages.map((img, index) => {
                                                    return (
                                                        <option key={index} value={img.logoImg.logoImg}>{isEmpty(img.siteNameKor) ? img.siteNameEn : img.siteNameKor}</option>
                                                    )
                                                })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="ml40 edit-images position-relative d-flex justify-content-center" style={{ width: "50%", display: "block" }}>
                                        <label htmlFor='siteImg'
                                            className="cursor-pointer"
                                            style={{
                                                width: "50%", aspectRatio: "3/2",
                                                position: "relative", borderRadius: "5px",
                                                backgroundColor: !isEmpty(siteImg) ? "unset" : "rgb(31 34 55)",
                                                backgroundImage: `url(${resource_url}/${siteImg})`,
                                                backgroundSize: "contain", backgroundPosition: "center",
                                                border: "1px solid #313557",
                                                backgroundRepeat: "no-repeat"
                                            }}
                                        >
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="form-group">
                            <div className="col-sm-12" style={{ display: "flex" }}>
                                <label htmlFor="post_title" className="control-label">내용</label>
                                <QuillEditor
                                    ref={(el) => (quill.current = el)}
                                    className={"form-control"}
                                    style={{ minHeight: "300px", height: "auto", padding: "0px", marginLeft: "15px", overflow: "hidden" }}
                                    theme="snow"
                                    value={articleList}
                                    formats={formats}
                                    modules={modules}
                                    onChange={(value) => setInputs({ ...inputs, articleList: value })}
                                />
                            </div>
                        </div>

                        <div className="border_button text-center mt20">
                            <button className="btn btn-primary btn-sm btn-history-back mr10" onClick={onShowModal}>취소</button>
                            <button className="btn btn-success btn-sm" onClick={onSendArticle}>작성완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
