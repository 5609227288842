
import React from 'react';
import { changeEditorValue, isEmpty } from '../../../Utils';

export { CasinoInfoDetail };



const CasinoInfoDetail = ({
    onBtnClick,
    casinoInfoList,
    setActive,
    active,
    length,
}) => {

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }

    return (
        <div className="magazine_board">
            <div className="post_title_box">
                <h1>{casinoInfoList.title}</h1>
            </div>
            <div className="contents-view">
                <div id="post-content">
                    <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(casinoInfoList.detail) ? changeEditorValue(casinoInfoList.detail): "" }}></div>
                    <div className="hidden_keyword"></div>
                </div>
            </div>
            <div className="pull-right mb20">
            </div>
            <div className="clearfix"></div>

            <div className="border_button mt20 mb20">
                <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                    <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                </div>
            </div>
        </div>
    );
};
