import React from 'react';
import { TabBoardBox1, TabBoardBox2 } from './HomeTab';


export { TabBoard };

function TabBoard({
    slotReviewLists,
    slotVideoLists,
    casinoInfoLists,
    freeArticleLists,
    cooperationReviewLists
}) {
    return (
        <div className="tab_board_area">
            <TabBoardBox1
            slotReviewLists={slotReviewLists}
            slotVideoLists={slotVideoLists}
            casinoInfoLists={casinoInfoLists}
            cooperationReviewLists={cooperationReviewLists}
            />
            <TabBoardBox2 freeArticleLists={freeArticleLists} />
        </div>
    );
}