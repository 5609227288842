import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { DieEventContent } from '../../Components/SiteEvent';

export { DieEvent };

function DieEvent() {
    return (
        <Fragment>
            <Navbar type="dieevent" />
            <div className='main'>
                <DieEventContent />
            </div>
        </Fragment>
    );

}