import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { MyPageContent, MyPageMenu } from '../../Components/Account';


export { MyPage };

function MyPage() {
    return (
        <Fragment>
            <Navbar type="mypage" />
            <div className='main'>
                <MyPageMenu>
                    <MyPageContent />
                </MyPageMenu>
            </div>
        </Fragment>
    );
}