
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Pagination } from '../../Common';
import { changeEditorValue, dateFormation, getGameLaunch, getGameList, getLocalStringNumber, getLocalstringNumber, isEmpty } from '../../../Utils';
import { pageActions } from '../../../store';
import { api_url, resource_url } from '../../../Utils/keys';
import { request } from '../../../Utils/api';

export { FreeSlotDetail };



const FreeSlotDetail = ({
    onBtnClick,
    lists,
    setActive,
    active,
    length,
    menuLists,
}) => {

    const dispatch = useDispatch();

    const userData = useSelector(state => state.user.data)

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [search, setSearch] = useState("");
    const onChange = (e) => {
        const { name, value } = e.target;
        setSearch(value);
    }
    const [gameLists, setGameLists] = useState([]);
    const [showSlotDetail, setShowSlotDetail] = useState(false);
    const [slotActive, setSlotActive] = useState(0);


    const onShowSlotDetail = (index) => {
        setShowSlotDetail(true)
        setSlotActive(index)
        const params = {
            typeId: gameLists[index].item.id,
        }
        request.post(api_url + "/api/FreeSlot/newListDistinction", params)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }

    const onSetSlotActive = (key) => {
        if (key < 0 || key >= gameLists.length) {
            setSlotActive(0);
        } else {
            setSlotActive(key);
        }
    }

    const onSlotBtnClick = () => {
        setShowSlotDetail(!showSlotDetail)
        setSlotActive(-1)
    }

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            providerId: lists.id,
        }
        getGameList(params)
            .then(data => {
                !isEmpty(data.list) && data.list.sort((a, b) => {
                    return b.viewCnt - a.viewCnt;
                })
                let gameList = [];
                data.list.forEach((item, index) => {
                    if (index < data.list.length * 0.1) {
                        gameList.push({ "item": item, "best": true })
                    } else {
                        gameList.push({ "item": item, "best": false })
                    }
                })
                gameList.sort((a, b) => {
                    return b.item.created_date - a.item.created_date;
                })
                setGameLists(gameList);
                dispatch(pageActions.setLoading(false));
            })
        // const params = {
        //     api: lists.api,
        //     code: lists.code,
        //     vendor: lists.vendor,
        // }
        // getGameList(params)
        //     .then(data => {
        //         setGameLists(data.data.result);
        //         dispatch(pageActions.setLoading(false));
        //     })

    }

    useEffect(() => {
        getList();
    }, [lists]);

    const onSetActive = (key) => {
        setActive(key);
    }

    const getNormalImg = e => {
        // console.log(e.target.src);
        e.target.src = `../imgs/slotgame1.webp`;
    };

    const onClickList = (list) => {
        dispatch(pageActions.setLoading(true));
        // console.log(localStorage);
        const params = {
            ...list,
            type: "Slot",
            username: userData.loginId,
            ipADD: localStorage.getItem('client-ip')
        }
        getGameLaunch(params);
    }

    return (
        <div className="freegame_board_list">

            <div className="page_seo_tit_box">
                <h2>{lists.name.name_kor} 무료 슬롯</h2>
                <p>{lists.detail}
                </p>
            </div>

            {/* <div className="game_banner">
                <img style={{height: "150px"}} src={`${resource_url}/${lists.detailImg}`} alt="프라그마틱플레이 무료슬롯" />
            </div>
            <div className="contents-view">
                <div id="post-content">
                    <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(lists.detail) ? changeEditorValue(lists.detail) : "" }}></div>
                    <div className="hidden_keyword"></div>
                </div>
            </div> */}
            <div className="board">
                <div className="freegame_search_area">
                    <div className="game_title">
                        <dl>
                            <dt>
                                {lists.name.name_kor}
                            </dt>
                            <dd>총 <strong>{gameLists.length}</strong>개의 게임이 있습니다.</dd>
                        </dl>
                    </div>

                    <div className="searchbox">
                        <div >
                            <span>
                                <input type="text" placeholder="게임명 검색" name="search" value={search} onChange={onChange} /></span>
                            <span>
                                <button type="submit">
                                    <i className="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>


                <div className="freegame_category game_menu_box">
                    <ul style={{ display: "flex", overflowX: "auto", alignItems: "center", justifyContent: "start", height: "100%", padding: "0 10px" }}>
                        {menuLists &&
                            menuLists.map((item, index) => {
                                return (
                                    <li key={index} className={active === index ? "active" : ""}>
                                        <div className='cursor-pointer' title={item.name.name_kor} onClick={() => onSetActive(index)}>
                                            {item.name.name_kor}
                                        </div>
                                    </li>
                                )
                            })

                        }
                    </ul>
                </div>


                {/* <div className="freegame_category">
                    <ul>
                        <li role="presentation" className="active"><a href="https://slotbuff2.com/board/fg_ppg">전체슬롯</a></li>
                        <li role="presentation"><a
                            href="https://slotbuff2.com/board/fg_ppg?findex=post_like%20desc&amp;category_id=1"><span>인기슬롯</span></a>
                        </li>
                        <li role="presentation"><a
                            href="https://slotbuff2.com/board/fg_ppg?findex=post_like%20desc&amp;category_id=7"><img
                                src="https://slotbuff2.com/imgs/reelkindom_icon.png" style={{ width: "88px", marginTop: "-5px" }}
                                alt="릴킹덤" /></a></li>

                        <li role="presentation"><a
                            href="https://slotbuff2.com/board/fg_ppg?findex=post_like%20desc&amp;category_id=3"><span>메가웨이</span></a>
                        </li>
                        <li role="presentation"><a
                            href="https://slotbuff2.com/board/fg_ppg?findex=post_like%20desc&amp;category_id=4"><span>프리구매</span></a>
                        </li>
                        <li role="presentation"><a
                            href="https://slotbuff2.com/board/fg_ppg?findex=post_like%20desc&amp;category_id=2"><span>출시예정</span></a>
                        </li>
                    </ul>
                </div> */}

                {!showSlotDetail &&
                    <>
                        <div>
                            <div className="gallery_list free_game_list">
                                <ul>
                                    {gameLists &&
                                        gameLists.filter(gameList => { const patt = new RegExp(search, "i"); return patt.test(gameList.item.title) || isEmpty(search); }).map((gameList, index) => {
                                            return (
                                                <li key={index} onClick={() => onShowSlotDetail(index)} >
                                                    <div className="box">

                                                        {gameList.item.newList ?
                                                            <div className="point_icon">
                                                                <img src="../imgs/freegame_new.gif" alt="new게임" />
                                                            </div>
                                                            :
                                                            (gameList.best &&
                                                                <div className="point_icon">
                                                                    <img src="../imgs/freegame_best.gif" alt="best게임" />
                                                                </div>
                                                            )
                                                        }
                                                        <div className=" cursor-pointer upper_link"
                                                            title={`${gameList.item.title}`}></div>
                                                        <div className="imgs">
                                                            {gameList.item.type === 1 ?
                                                                <img
                                                                    src={gameList.item.mainImg}
                                                                    alt={`${gameList.item.title}`}
                                                                    onError={getNormalImg}
                                                                />
                                                                :
                                                                <img
                                                                    src={`${resource_url}/${gameList.item.mainImg}`}
                                                                    alt={`${gameList.item.title}`}
                                                                    onError={getNormalImg}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="tit">{`${gameList.item.title}`}</div>
                                                        {/* <div className="tit en">{gameList.names.en}</div> */}

                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="border_button mt20 mb20">
                            <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                                <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                                {/* <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button> */}
                            </div>
                        </div>
                    </>
                }
                {(showSlotDetail && !isEmpty(gameLists[slotActive].item)) &&
                    <>
                        <div className="contents-view">
                            <div id="post-content">
                                <div className="post_content">
                                    <section className="section_box" id="section3" style={{ margin: "0px" }}>
                                        <ul>
                                            <li>
                                                <p>
                                                    <iframe src={gameLists[slotActive].item.gameLink}>
                                                        {/* <img style={{ width: "100%" }} src={`${resource_url}/${gameLists.item[slotActive].mainImg}`} alt="" title="" /> */}
                                                    </iframe>
                                                </p>
                                                {!gameLists[slotActive].item.show &&
                                                    <>
                                                        <div className="magazine_btn_box">
                                                            <h3 style={{ color: "#c9c9c9" }}>게임정보</h3>
                                                        </div>
                                                        <table className="table table-bordered table_custom4" style={{ position: "relative", marginTop: "5px" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <th>타이틀</th>
                                                                    <td>{gameLists[slotActive].item.title}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>제작사</th>
                                                                    <td>{gameLists[slotActive].item.provider.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>출시일</th>
                                                                    <td>{dateFormation(gameLists[slotActive].item.start_date, 3)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>지원기기</th>
                                                                    <td>{gameLists[slotActive].item.assister}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>페이라인</th>
                                                                    <td>{gameLists[slotActive].item.payline}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>최소 베팅</th>
                                                                    <td>{getLocalStringNumber(gameLists[slotActive].item.minBet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>최대 베팅</th>
                                                                    <td>{getLocalStringNumber(gameLists[slotActive].item.maxBet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>맥스윈</th>
                                                                    <td>{getLocalstringNumber(gameLists[slotActive].item.maxWin)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>RTP</th>
                                                                    <td>{gameLists[slotActive].item.RTP}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="magazine_btn_box">
                                                            <p style={{ fontSize: "13px", color: "#c9c9c9" }}>{gameLists[slotActive].item.comment}</p>
                                                        </div>
                                                    </>
                                                }
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                                <div className="hidden_keyword"></div>
                            </div>
                        </div>
                        <div className="border_button mt20 mb20">
                            <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                                <button className="btn btn-primary btn-sm" onClick={onSlotBtnClick}>목록</button>
                                <button className="btn btn-primary btn-sm" onClick={() => onSetSlotActive(slotActive + 1)}>다음</button>
                                <button className="btn btn-primary btn-sm" onClick={() => onSetSlotActive(slotActive - 1)}>이전</button>
                            </div>
                        </div>
                    </>
                }
                {/* <Pagination pageInfo={pageInfo} /> */}
            </div>
        </div>
    );
};
