import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { pageActions } from "../../store";

export { Pagination };

function Pagination(props) {
    const dispatch = useDispatch();
    const totalPage = useSelector(state => state.page.totalPage);

    const { pageInfo, setPageInfo, getList } = props;

    useEffect(() => {
        dispatch(pageActions.setPerPage(pageInfo.perPage));
    }, [pageInfo.perPage]);

    const pageIndexCount = 5;

    let leftLimit = Number(pageInfo.curPage) - Math.floor(pageIndexCount / 2);
    let rightLimit = Number(pageInfo.curPage) + Math.floor(pageIndexCount / 2);
    if (leftLimit < 1) {
        rightLimit = rightLimit - leftLimit + 1;
        leftLimit = 1;
    }
    if (rightLimit > totalPage) {
        leftLimit -= (rightLimit - totalPage + 1);
        rightLimit = totalPage;
    }
    if (leftLimit < 1) leftLimit = 1;
    let pageIndexes = [];
    for (let i = leftLimit; i <= rightLimit; i++) pageIndexes.push(i);

    const onChange = (e) => {
        const { name, value } = e.target;
        getList(1, value);
        dispatch(pageActions.setCurPage(1));
    }


    const onGetPage = (page) => {
        if ((0 < page) && (page <= totalPage)) {
            getList(page, pageInfo.perPage);
            dispatch(pageActions.setCurPage(page));
        }
    }
    return (
        <nav>
            <ul className="pagination">
                <li>
                    <a className="cursor-pointer" onClick={() => onGetPage(1)}>시작</a>
                </li>
                {
                    pageIndexes.map((list, index) => {
                        return (
                            <li className={`page-item cursor-pointer ${list === pageInfo.curPage && "active"}`} key={index}>
                                <a className={`cursor-pointer page-link`} onClick={() => onGetPage(Number(list))} >{list}</a>
                            </li>
                        )
                    })
                }
                <li>
                    <a className="cursor-pointer" rel="next" onClick={() => onGetPage(Number(pageInfo.curPage) + 1)}>&gt;</a>
                </li>
                <li>
                    <a className="cursor-pointer" onClick={() => onGetPage(Number(totalPage))}>맨끝 ›</a>
                </li>
            </ul>
        </nav>
    );

}