import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { RouletteContent } from '../../Components/Account';


export { Roulette };

function Roulette() {
    return (
        <Fragment>
            <Navbar />
            <div className='main'>
                <RouletteContent />
            </div>
        </Fragment>
    );

}