import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { NotificationContent } from '../../Components/Account';


export { Notification };

function Notification() {
    return (
        <Fragment>
            <Navbar />
            <div className='main'>
                <NotificationContent />
            </div>
        </Fragment>
    );

}