import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { BestReviewContent } from '../../Components/Community';


export { BestReview };

function BestReview() {
    return (
        <Fragment>
            <Navbar type="" />
            <div className='main'>
                <BestReviewContent />
            </div>
        </Fragment>
    );

}