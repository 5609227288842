import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { SlotGameReviewContent } from '../../Components/Community';


export { SlotGameReview };

function SlotGameReview() {
    return (
        <Fragment>
            <Navbar type="slotGameReview" />
            <div className='main'>
                <SlotGameReviewContent />
            </div>
        </Fragment>
    );

}