import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user.slice';
import { siteReducer } from './site.slice';
import { pageReducer } from './page.slice';
import { communityReducer } from './community.slice';
import { eventReducer } from './event.slice';
import { miniReducer } from './mini.slice';

export * from './user.slice';
export * from './site.slice';
export * from './page.slice';
export * from './community.slice';
export * from './event.slice';
export * from './mini.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        site: siteReducer,
        page: pageReducer,
        community: communityReducer,
        event: eventReducer,
        mini: miniReducer,
    }
});