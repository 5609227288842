import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { request } from '../../../Utils/api';
import { api_url, resource_url } from '../../../Utils/keys';
import { ChangeNum_Letter, changeEditorValue, dateFormation, isEmpty, toastr } from '../../../Utils';
import { Pagination } from '../../Common';

export { FakeDetail };


const FakeDetail = ({
    pageInfo,
    setPageInfo,
    onBtnClick,
    getList,
    fakeList,
    setActive,
    active,
    length,
}) => {

    const userData = useSelector(state => state.user.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const fakeArticleList = useSelector(state => state.site.answerList);

    const [editArticle, setEditArticle] = useState("");

    const onChange = (e) => {
        setEditArticle(e.target.value)
    }

    const onArticleSend = () => {
        if (isEmpty(editArticle)) {
            return toastr.warning("댓글을 입력해주세요.")
        }
        const params = {
            type: "FakeSites",
            answerList: editArticle,
            typeId: fakeList.id,
        }
        request.post(api_url + "/api/Answer/answerArticle", params)
            .then(data => {
                if (data.success) {
                    toastr.success(data.message)
                    getList(1, 10, active)
                    setEditArticle("")
                }
            })
    }

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
            getList(1, 10, 0)
        } else {
            setActive(key);
            getList(1, 10, key)
        }
    }

    const onOpposite = () => {
        if (!isAuthenticated) {
            toastr.warning("로그인하고 이용해주세요")
        } else {
            const params = {
                type: "FakeSites",
                typeId: fakeList.id,
            }
            request.post(api_url + "/api/Article/oppList", params)
                .then(data => {
                    if (data.success) {
                        toastr.success(data.message)
                    }
                })
        }
    }

    return (
        <div className="board item_board mukti_board">

            <div className="post_title">
                {fakeList.siteNameKor} ({fakeList.siteNameEng}) 먹튀사이트 {fakeList.siteNameKor} ({fakeList.siteNameEng}) 먹튀확정 {fakeList.siteNameKor} ({fakeList.siteNameEng}) 먹튀 </div>
            <ul className="info">
                {/* <li>
                    <div className="user_levelname">
                        <span className="lv_icon_box">
                            <div className="lv_icon lv_admin"></div>
                        </span>
                        <span className="user_name">버프하루</span>
                    </div>
                </li> */}
                <li><i className="fa fa-eye" aria-hidden="true"></i> {fakeList.viewCnt}</li>
                <li><i className="fa fa-thumbs-down" aria-hidden="true"></i> <span className="post-dislike">{fakeList.oppCnt}</span></li>
                <li>
                    <button type="button" id="btn-scrap">
                        <i className="fas fa-thumbtack" aria-hidden="true"></i>
                        <span className="post-scrap"></span>
                    </button>
                </li>
                {/* <li className="pull-right">
                    <button type="button" id="btn-blame" className="police">글신고
                        <span className="post-blame"></span>
                    </button>
                </li> */}
                <li className="pull-right time"><i className="fa fa-clock-o mr10" aria-hidden="true"></i>{dateFormation(fakeList.created_date, 3)}</li>
            </ul>



            <div className="contents-view">
                <div className="contents-view-img">
                </div>

                <div id="post-content">
                    <div className="list-group">
                        <ul>
                            <li>
                                <div className="left_con"> <strong>설명</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>홍콩 (HONGKONG) 먹튀사이트 확정 카지노 사이트 먹튀검증은 PG슬롯와함께
                                    하세요.</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>먹튀날짜</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>{dateFormation(fakeList.created_date, 3)}</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>업체명</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>{fakeList.siteNameKor} ({fakeList.siteNameEng})</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>사이트주소</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>{fakeList.siteAddress}</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>먹튀(피해)금액</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>{ChangeNum_Letter(fakeList.fakeMoney)}</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>고객센터</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>*</div>
                            </li>
                            <li>
                                <div className="left_con"> <strong>계좌번호</strong></div>
                                <div className="right_con" style={{ minHeight: "20px" }}>*</div>
                            </li>
                        </ul>
                    </div>
                    <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(fakeList.detail) ? changeEditorValue(fakeList.detail) : "" }}>
                    </div>
                    <div className="new_recommand">
                        <button className="bad btn btn-sm " id="btn-post-dislike" title="비추하기" onClick={onOpposite}>
                            <i className="fa fa-thumbs-o-down fa-lg" aria-hidden="true"></i>
                            {/* <span className="post-dislike"></span> */}
                        </button>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            {isAuthenticated &&
                <div id="comment_write_box">
                    <div className="well comment_write_box_inner">
                        <div id="fcomment" acceptCharset="utf-8"
                            noValidate="novalidate">
                            <div className="comment_area">
                                <ul>
                                    <li>
                                        <textarea
                                            className="comment_write"
                                            placeholder="광고, 심한욕설의 댓글은 경고없이 삭제됩니다."
                                            name="editArticle"
                                            id="editArticle"
                                            value={editArticle}
                                            onChange={onChange}
                                        >
                                        </textarea>
                                    </li>
                                    <li>
                                        <div role="group" aria-label="...">
                                            <button type="button" className="commet_btn" id="cmt_btn_submit" onClick={onArticleSend}>댓글등록</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="comment_write_button_area">
                            </div>
                        </div>
                    </div>
                </div>}
            <div id="viewcomment">
                <div className="alert alert-auto-close alert-dismissible alert-comment-list-message" style={{ display: "none" }}><button
                    type="button" className="close alertclose">×</button><span
                        className="alert-comment-list-message-content"></span></div>


                {fakeArticleList &&
                    fakeArticleList.map((list, index) => {
                        return (
                            <div className="media" key={index} id="comment_199609" style={{ paddingLeft: "0%" }}>
                                <div className="media-body">
                                    <div className="media-heading">
                                        <span className="name mr10">
                                            <div className="user_levelname"><span className="lv_icon_box">
                                                <div className={`lv_icon lv_icon${list.level > 6 ? 1 : list.level}`}></div>
                                            </span><span className="user_name">{list.loginId}</span></div>
                                        </span>
                                        <span className="time"><i className="fa fa-clock-o mr10" aria-hidden="true"></i>{dateFormation(list.created_date, 3)}</span>
                                    </div>
                                    <div className="media-content">{list.answerList}</div>
                                </div>
                            </div>
                        )
                    })
                }

                <Pagination
                    pageInfo={pageInfo}
                    setPageInfo={(params) => setPageInfo(params)}
                    getList={getList} />

            </div>

            <div className="border_button mt20 mb20">
                <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                    <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                </div>
            </div>
        </div>
    );
};
