import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFormation, isEmpty } from '../../../Utils';
import { resource_url } from '../../../Utils/keys';



export { TabBoardBox2 };

function TabBoardBox2({ freeArticleLists }) {
    const dayFovorArticleLists = !isEmpty(freeArticleLists) ? (!isEmpty(freeArticleLists.DayFovorArticleLists) ? freeArticleLists.DayFovorArticleLists : []) : []
    const monthFovorArticleLists = !isEmpty(freeArticleLists) ? (!isEmpty(freeArticleLists.MonthFovorArticleLists) ? freeArticleLists.MonthFovorArticleLists : []) : []
    const weekFovorArticleLists = !isEmpty(freeArticleLists) ? (!isEmpty(freeArticleLists.WeekFovorArticleLists) ? freeArticleLists.WeekFovorArticleLists : []) : []


    const [showArticle, setShowArticle] = useState("dayArticle");

    const onShowArticle = (type) => {
        setShowArticle(type)
    }

    return (
        <div className="tab_board_box tab_board_box2">
            <div className="tab_menu">
                <div className={`menu_list ${showArticle === "dayArticle" ? "active" : ""}`} rel="latest_05" onClick={() => onShowArticle("dayArticle")}>일간인기글</div>
                <div className={`menu_list ${showArticle === "weekArticle" ? "active" : ""}`} rel="latest_06" onClick={() => onShowArticle("weekArticle")}>주간인기글</div>
                <div className={`menu_list ${showArticle === "monthArticle" ? "active" : ""}`} rel="latest_07" onClick={() => onShowArticle("monthArticle")}>월간인기글</div>
            </div>

            {showArticle === "dayArticle" &&
                <div className="board_conts" id="latest_05">

                    <div className="img_box">
                        {(dayFovorArticleLists) &&
                            dayFovorArticleLists.map((list, index) => {
                                if (index > 2) {
                                    return;
                                } else {
                                    return (
                                        <div className="best_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}>
                                                <div className="num">{index + 1}</div>
                                                <div className="imgs">
                                                    <img loading="lazy" src={list.titleImg} alt={list.title} title={list.title} className="img-responsive" />
                                                </div>
                                                <div className="txt">{list.title}</div>

                                            </Link>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                    <div className="normal_list_box">
                        {dayFovorArticleLists &&
                            dayFovorArticleLists.map((list, index) => {
                                if (index < 3) {
                                    return;
                                } else {
                                    return (
                                        <div className="normal_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}></Link>
                                            <div className="num">{index + 1}</div>
                                            <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                            <div className="date">{dateFormation(list.created_date, 4)}</div>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                </div>
            }

            {showArticle === "weekArticle" &&
                <div className="board_conts" id="latest_06">

                    <div className="img_box">
                        {monthFovorArticleLists &&
                            monthFovorArticleLists.map((list, index) => {
                                if (index > 2) {
                                    return;
                                } else {
                                    return (
                                        <div className="best_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}>
                                                <div className="num">{index + 1}</div>
                                                <div className="imgs">
                                                    <img loading="lazy" src={list.titleImg} alt={list.title} title={list.title} className="img-responsive" />
                                                </div>
                                                <div className="txt">{list.title}</div>

                                            </Link>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                    <div className="normal_list_box">
                        {monthFovorArticleLists &&
                            monthFovorArticleLists.map((list, index) => {
                                if (index < 3) {
                                    return;
                                } else {
                                    return (
                                        <div className="normal_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}></Link>
                                            <div className="num">{index + 1}</div>
                                            <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                            <div className="date">{dateFormation(list.created_date, 4)}</div>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                </div>
            }

            {showArticle === "monthArticle" &&
                <div className="board_conts" id="latest_07">

                    <div className="img_box">
                        {weekFovorArticleLists &&
                            weekFovorArticleLists.map((list, index) => {
                                if (index > 2) {
                                    return;
                                } else {
                                    return (
                                        <div className="best_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}>
                                                <div className="num">{index + 1}</div>
                                                <div className="imgs">
                                                    <img loading="lazy" src={list.titleImg} alt={list.title} title={list.title} className="img-responsive" />
                                                </div>
                                                <div className="txt">{list.title}</div>

                                            </Link>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                    <div className="normal_list_box">
                        {weekFovorArticleLists &&
                            weekFovorArticleLists.map((list, index) => {
                                if (index < 3) {
                                    return;
                                } else {
                                    return (
                                        <div className="normal_list" key={index}>
                                            <Link to="/freearticle" className="upper_link" title={list.title}></Link>
                                            <div className="num">{index + 1}</div>
                                            <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                            <div className="date">{dateFormation(list.created_date, 4)}</div>
                                        </div>
                                    )
                                }
                            })

                        }
                    </div>
                </div>
            }
        </div>
    );
}