import { createSlice } from '@reduxjs/toolkit';
// create slice

const name = 'page';
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

// exports

export const pageActions = { ...slice.actions };
export const pageReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        totalPage: 1,
        perPage: 10,
        curPage: 1,
        pageInfo: "",
        loading: false,
    }
}

function createReducers() {
    return {
        setPage,
        setPerPage,
        setCurPage,
        setLoading,
        setPageData,
        setPageInfo,
    };

    function setPage(state, action) {
        state.totalPage = action.payload.totalPage;
    }

    function setCurPage(state, action) {
        state.curPage = action.payload;
    }

    function setPerPage(state, action) {
        state.perPage = action.payload;
    }

    function setLoading(state, action) {
        state.loading = action.payload;
    }
    function setPageInfo(state, action) {
        state.pageInfo = action.payload;
    }
    function setPageData(state, action) {
        state.curPage = action.payload.page;
        state.totalPage = action.payload.totalPage;
    }
}