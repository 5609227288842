import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../Utils/api';

import { api_url } from '../Utils/keys';
import { showMessage, toastr } from '../Utils';
import { pageActions } from './page.slice';
import { store } from '.';

// create slice

const name = 'event';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const eventActions = { ...slice.actions, ...extraActions };
export const eventReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        liveEventList: [],
        dieEventList: [],
        casinoNewsList: [],
        casinoInfoList: [],
        pointSettingList: [],
        noticeList: [],
        inquireList: [],
        notificationList: [],
        roulette: {
            history: [],
            leftCount: 0,
            roulette: {},
            bonus: 0,
            onlyClick: true,
        },
        showResults: false,
        pointConvertHistory: [],
        couponList: [],
        couponHistoryList: [],
        slotDictionary: [],
    }
}

function createReducers() {
    return {
        setLiveEventList,
        setDieEventList,
        setCasinoInfoList,
        setCasinoNewsList,
        setPointSettingList,
        setCouponList,
        setNoticeList,
        setNotificationList,
        setInquireList,
        setRouletteData,
        setRouletteBonus,
        setRouletteRules,
        setShowResults,
        setOnlyClick,
        setPointConvertHistory,
        setCouponHistoryList,
        setSlotDictionary,

    };

    function setShowResults(state, action) {
        state.showResults = action.payload;
    }
    function setLiveEventList(state, action) {
        state.liveEventList = action.payload;
    }
    function setDieEventList(state, action) {
        state.dieEventList = action.payload;
    }
    function setCasinoInfoList(state, action) {
        state.casinoInfoList = action.payload;
    }
    function setCasinoNewsList(state, action) {
        state.casinoNewsList = action.payload;
    }
    function setSlotDictionary(state, action) {
        state.slotDictionary = action.payload;
    }
    function setPointSettingList(state, action) {
        state.pointSettingList = action.payload;
    }
    function setCouponList(state, action) {
        state.couponList = action.payload;
    }
    function setNoticeList(state, action) {
        state.noticeList = action.payload;
    }
    function setNotificationList(state, action) {
        state.notificationList = action.payload;
    }
    function setInquireList(state, action) {
        state.inquireList = action.payload;
    }
    function setOnlyClick(state, action) {
        state.onlyClick = action.payload;
    }
    function setRouletteData(state, action) {
        state.roulette.history = action.payload.history
        state.roulette.leftCount = action.payload.leftCnt
        state.roulette.bonus = action.payload.bonus
    }

    function setRouletteBonus(state, action) {
        state.roulette.bonus = action.payload.winPoint
    }
    function setPointConvertHistory(state, action) {
        state.pointConvertHistory = action.payload
    }
    function setCouponHistoryList(state, action) {
        state.couponHistoryList = action.payload
    }

    function setRouletteRules(state, action) {
        action.payload.roulette[0].list.sort((a, b) => {
            return a.bonus - b.bonus
        })
        state.roulette.roulette = action.payload.roulette[0]
    }

}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getLiveEventList: getLiveEventList(),
        getDieEventList: getDieEventList(),
        getCasinoInfoList: getCasinoInfoList(),
        getCasinoNewsList: getCasinoNewsList(),
        getPointSettingList: getPointSettingList(),
        getCouponList: getCouponList(),
        getNoticeList: getNoticeList(),
        getInquireList: getInquireList(),
        getNotificationList: getNotificationList(),
        sendInquire: sendInquire(),
        getRouletteHistory: getRouletteHistory(),
        doRoulette: doRoulette(),
        getRouletteRules: getRouletteRules(),
        getPointConvertHistory: getPointConvertHistory(),
        getCouponHistoryList: getCouponHistoryList(),
        getSlotDictionary: getSlotDictionary()
    };

    function getLiveEventList() {
        return createAsyncThunk(
            `${name}/getLiveEventList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Event/liveEvents", params)
                        .then(data => {
                            dispatch(eventActions.setLiveEventList(data.data.list));
                            dispatch(pageActions.setLoading(false));

                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getDieEventList() {
        return createAsyncThunk(
            `${name}/getDieEventList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Event/dieEvents")
                        .then(data => {
                            dispatch(eventActions.setDieEventList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getCasinoInfoList() {
        return createAsyncThunk(
            `${name}/getCasinoInfoList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Casino/slotInfo", params)
                        .then(data => {
                            dispatch(eventActions.setCasinoInfoList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getCasinoNewsList() {
        return createAsyncThunk(
            `${name}/getCasinoNewsList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Casino/casinoNews", params)
                        .then(data => {
                            dispatch(eventActions.setCasinoNewsList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getSlotDictionary() {
        return createAsyncThunk(
            `${name}/getSlotDictionary`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Casino/slotvocabularies")
                        .then(data => {
                            dispatch(eventActions.setSlotDictionary(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getPointSettingList() {
        return createAsyncThunk(
            `${name}/getPointSettingList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Point/pointsetting")
                        .then(data => {
                            dispatch(eventActions.setPointSettingList(data.data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getCouponList() {
        return createAsyncThunk(
            `${name}/getCouponList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/coupon")
                        .then(data => {
                            dispatch(eventActions.setCouponList(data.data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getNoticeList() {
        return createAsyncThunk(
            `${name}/getNoticeList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/notice", params)
                        .then(data => {
                            dispatch(eventActions.setNoticeList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getNotificationList() {
        return createAsyncThunk(
            `${name}/getNotificationList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/message", params)
                        .then(data => {
                            dispatch(eventActions.setNotificationList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getInquireList() {
        return createAsyncThunk(
            `${name}/getInquireList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/questionList", params)
                        .then(data => {
                            dispatch(eventActions.setInquireList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function sendInquire() {
        return createAsyncThunk(
            `${name}/sendInquire`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/questions", params)
                        .then(data => {
                            if (data.success) {
                                toastr.success(data.message);
                                dispatch(eventActions.getInquireList({ page: 1, perPage: 10, type: "", key: "" }))
                                dispatch(pageActions.setLoading(false));
                            }
                            else
                                toastr.warning(data.message);
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function getRouletteHistory() {
        return createAsyncThunk(
            `${name}/getRouletteHistory`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/rouletteHist", params)
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(eventActions.setRouletteData(data.data));
                                dispatch(pageActions.setPage(data.data));
                                dispatch(pageActions.setLoading(false));

                            }
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    };
    function getPointConvertHistory() {
        return createAsyncThunk(
            `${name}/getPointConvertHistory`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Mypage/pointConvertHists", params)
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(eventActions.setPointConvertHistory(data.data.list));
                                dispatch(pageActions.setPage(data.data));
                                dispatch(pageActions.setLoading(false));

                            }
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    };

    function doRoulette() {
        return createAsyncThunk(
            `${name}/doRoulette`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/bigwheel", {})
                        .then(data => {
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(eventActions.setRouletteBonus(data.data.winPoint))
                            }
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    };

    function getRouletteRules() {
        return createAsyncThunk(
            `${name}/getRouletteRules`,
            async function (params, { dispatch }) {
                const pageData = store.getState().page
                dispatch(pageActions.setLoading(true));
                try {
                    dispatch(pageActions.setLoading(true));
                    request.post(api_url + "/api/Mypage/info", {})
                        .then(data => {
                            dispatch(pageActions.setLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(eventActions.setRouletteRules(data.data));
                                dispatch(pageActions.setLoading(false));
                            }
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    };
    function getCouponHistoryList() {
        return createAsyncThunk(
            `${name}/getCouponHistoryList`,
            async function (params, { dispatch }) {
                const pageData = store.getState().page
                dispatch(pageActions.setLoading(true));
                try {
                    dispatch(pageActions.setLoading(true));
                    request.post(api_url + "/api/Mypage/couponHist", {})
                        .then(data => {
                            dispatch(pageActions.setLoading(false));
                            showMessage(data.message);
                            if (!data.status) {
                                dispatch(eventActions.setCouponHistoryList(data.data.list));
                                dispatch(pageActions.setPage(data.data));
                                dispatch(pageActions.setLoading(false));
                            }
                        })
                }
                catch (err) {
                    console.log(err)
                }
            }
        )
    };
}