import React, { useState, useEffect } from 'react';
import { MiniGamePanel, MiniGameVideo, MiniBettingHist } from './components';
import { getMinigameUrl } from '../../Utils';

export { EVOPowerballContent };


function EVOPowerballContent() {

    const [url, setUrl] = useState("");

    useEffect(() => {
        getMinigameUrl("EVOPowerball").then(url => setUrl(url)).catch(err => {
            console.error('err: ', err);
        })
    }, [])
    return (
        <div className="main_area">
            {/* <div className="pointgame_text">우측 상단 사운드버튼 클릭시 사운드가 재생됩니다 ▼</div> */}
            <MiniGameVideo type="EVOPowerball" url={url}/>
            <MiniGamePanel type="EVOPowerball" />
            <MiniBettingHist type="EVOPowerball" />
        </div>
    );
}
