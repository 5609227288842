import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { FollowArticleContent } from '../../Components/Community';

export {FollowArticle} ;

function FollowArticle() {
    return (
        <Fragment>
            <Navbar type="followArticle"/>
            <div className='main'>
                <FollowArticleContent />
            </div>

        </Fragment>
    );
}