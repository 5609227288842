import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export { PrivateRoute };

function PrivateRoute(props) {
  const { children } = props;
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  } else {
    return children
  }
}