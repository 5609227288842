/* eslint-disable no-loop-func */
import { autoLogout, convertObj, isEmpty, toastr, history } from ".";
import { eventActions, miniActions, pageActions, store, userActions } from "../store";
import { ws_api_url } from "./keys";


/****************************접속상태 확인하는 허트비트************************* */

// const connectTime = setTimeout(() => {
//   ConnectionStatus();
// }, 3000);
let HeartBitTime = 0;
let reConnectedCnt = 1;
export const ConnectionStatus = () => {

  // console.log('HeartBitTime: ', HeartBitTime);
  if ((HeartBitTime !== 0) && ((Date.now() - HeartBitTime) > 20000)) {
    // clearTimeout(connectTime);
    try {
      window.ws.close();
      // toastr.success("소켓이 타임아웃");
    } catch (err) {
      console.log('err: ', err);
    }
    // window.ws = null;
    HeartBitTime = 0;
    return;
  }
  else {
    // console.log('HeartBitTime: ', HeartBitTime, window.ws.readyState);
    try {
      if (window.ws.readyState === 1 || window.ws.readyState === 0) {

        window.ws.send(JSON.stringify({ MessageType: 'Connect', data: "Status" }));
        setTimeout(() => {
          ConnectionStatus();
        }, 5000)
        // console.log("8*******")
      }

    } catch (err) {

      console.log('err: ', err);
      // toastr.error(" 에로 " + window.ws.readyState || "");
    }
  }
}

export const initSocket = token => {

  window.ws = new WebSocket(ws_api_url);

  window.ws.onopen = event => {
    while (window.ws.readyState !== 2 && window.ws.readyState !== 3) {
      if (window.ws.readyState === 1) {
        ConnectionStatus();
        if (history.location.pathname === "/minigame/EOS1") {
          const roomdata = {
            MessageType: "RoomType", data: {
              pageType: "EOSPowerball1",
              RoomType: "EOSPowerball1",
              sportType: 'all',
              gameType: 'all',
              sportId: 'all'
            }
          };
          window.ws.send(JSON.stringify(roomdata));
        }
        if (localStorage.jwtToken) {

          window.ws.send(
            JSON.stringify({
              MessageType: "PublicLogin",
              token: localStorage.jwtToken
            })
          );
          store.dispatch(userActions.getUserInfo());
          autoLogout();
          reConnectedCnt = 0;
        }

        // send sports list to the server for the price rate

        break;
      }
    }
  };

  window.ws.onerror = event => {
    // console.log("error", event);
  };

  window.ws.onclose = event => {
    // toastr.info("소켓이 끊어졌습니다.");

    if (window.ws.readyState === 1 || window.ws.readyState === 0) {
      return;
    } else {
      // //console.log(window.ws.readyState);
      if (window.ws.readyState === 1 || window.ws.readyState === 0) {
        return;
      }
      setTimeout(() => {
        initSocket();
      }, 10000);
    }
  };


  window.ws.onmessage = async (event) => {
    let result = await window.decompressBinaryData(event.data);
    const data = convertObj(result);
    processData(data.MessageType, convertObj(data.data));
  };

};

export const processData = (messageType, data) => {
  if (!isEmpty(data.data)) {
    data = convertObj(data.data);
  }
  switch (messageType) {
    case "Question":
      const params = {
        page: 1,
        perPage: 10,
        type: "",
      }
      toastr.info("답변이 도착햇습니다.")
      store.dispatch(eventActions.getInquireList(params));
      break;
    case "Notice":
      const param = {
        page: 1,
        perPage: 10,
        type: "",
      }
      toastr.info("공지가 도착햇습니다.")
      store.dispatch(eventActions.getNoticeList(param));
      break;

    case "powerball_status":
      powerball_status(data);
      break;
    case "powerball_finished_bets":
      powerball_finished_bets(data);
      break;
    default:
      break;
  }
};

export const powerball_status = data => {
  //파워볼자료
  data = { ...data, state: convertObj(data.state) };
  //console.log("powerball_status: ", convertObj(data));
  store.dispatch(miniActions.setPowerBallRates(data));
  const mini = store.getState().mini;
  // //console.log('Check: ', data.status, mini);
  if (mini.miniCheck != data.state.state) {
    if (data.state.state == 0) toastr.warning("점검중입니다.");
  }
  store.dispatch(miniActions.setMiniCheck(Number(data.state.state)));
};

export const powerball_finished_bets = data => {
  //console.log("powerball_finished_bets: ", data);
  const miniStore = store.getState().mini;
  const miniList = miniStore.bettingList;
  if (data.bets.length > 0) {
    toastr.info("" + data.type + "분게임이 끝났습니다.");
  }
  if (data.type == miniStore.type) {
    data.bets.forEach(bet => {
      const betIndex = miniList.findIndex(item => item._id === bet._id);
      if (betIndex >= 0) {
        const finishedBet = { ...miniList[betIndex], ...bet };
        store.dispatch(
          miniActions.getPowerBallBettingList(
            miniList
              .slice(0, betIndex)
              .concat(
                [finishedBet].concat(
                  miniList.slice(betIndex + 1, miniList.length)
                )
              )
          )
        );
      }
    });
  }
};