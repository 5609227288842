import React from 'react';
import { Helmet } from 'react-helmet';
import moment from "moment";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import $ from "jQuery";
import { pageActions, store, userActions } from "../store";
import { api_url, resource_url } from "./keys";


export const isMobilePage = window.isMobilePage;
export const ResizePowerballFrame = window.ResizePowerballFrame;
export const ResizeGopickFrame = window.ResizeGopickFrame;
export const isEmpty = (value) => {
    return value === null ||
        value === undefined ||
        (typeof (value) === "string" && value.trim().length === 0) ||
        (typeof (value) === "object" && Object.keys(value).length === 0)
}

export const toastr = {
    info: message => {
        NotificationManager.info(message, "", 5000);
    },
    success: message => {
        NotificationManager.success(message, "", 5000);
    },
    warning: message => {
        NotificationManager.warning(message, "", 5000);
    },
    error: message => {
        NotificationManager.error(message, "", 5000);
    }
};

// Show message after get the response from backend.
export const showMessage = message => {
    if (!message) return;
    if (message.warning) NotificationManager.warning(message.warning, "", 5000);
    if (message.info) NotificationManager.info(message.info, "", 5000);
    if (message.success) NotificationManager.success(message.success, "", 5000);
    if (message.error) NotificationManager.error(message.error, "", 5000);
};

export const dateFormation = (date, type = 1) => {
    switch (type) {
        case 0:
            return moment(new Date(date)).format("YY-MM-DD HH:mm:ss");
        case 1:
            return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
        case 2:
            return moment(new Date(date)).format("YY-MM-DD");
        case 3:
            return moment(new Date(date)).format("YYYY-MM-DD");
        case 4:
            return moment(new Date(date)).format("MM-DD");
        case 5:
            return moment(new Date(date)).format("HH:mm");
        case 6:
            return moment(new Date(date)).format("HH:mm:ss");
        case 7:
            return moment(new Date(date)).format("hh:mm a");
        case 8:
            return moment(new Date(date)).format("YYYY-MM-DD HH:mm");
        case 9:
            return moment(new Date(date)).format("MM/DD HH:mm");
        case 10:
            return moment(new Date(date)).format("DD");
        case 11:
            return moment(new Date(date)).format("MM");
        case 12:
            return moment(new Date(date)).format("HH");
        default:
            return "";
    }
};

export const setAuthToken = (token) => {
    if (token) {
        // Apply to every request
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    } else {
        // Delete Auth header
        delete axios.defaults.headers.common['Authorization']
    }
}

// custom history object to allow navigation outside react components
export const history = {
    navigate: null,
    location: null
};

export const convertObj = value => {
    if (typeof value === "object") return value;
    if (typeof value === "string") return JSON.parse(value);
    return value;
};

export const getLocalStringNumber = (value) => {
    if (isNaN(Number(value))) {
        return 0;
    }
    else {
        return Number(value).toLocaleString();
    }
}

// export const getIpAddr = () => {
//     try {
//         let theUrl = 'https://www.cloudflare.com/cdn-cgi/trace'
//         // let theUrl = 'https://172.16.88.156:14998'
//         let xmlHttp = new XMLHttpRequest();
//         xmlHttp.open("GET", theUrl, false) // false for synchronous request
//         xmlHttp.send(null);

//         let responseText = xmlHttp.responseText;
//         let ip = responseText.split('ip=')[1].split('ts=')[0];
//         localStorage.setItem('client-ip', ip);
//         localStorage.setItem('client-ip', responseText);
//     } catch (e) {
//         //console.log('couldFlareCatch==>', e)
//         ipify()
//     }


//     function ipify() {
//         try {
//             $.getJSON("https://api.ipify.org?format=json", function (data) {
//                 //console.log('ipify===>', data)
//                 if (data.ip)
//                     localStorage.setItem('client-ip', data.ip)
//                 else
//                     amazon()
//             })
//                 .catch(err => {
//                     console.error('err: ', err);
//                     //console.log('ipifyCatch==>', err)
//                     amazon()
//                 })
//         } catch (e) {
//             //console.log('ipifyCatch==>', e)
//             amazon()
//         }
//     }

//     function amazon() {
//         try {
//             fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => {
//                 //console.log('amazon', data);
//                 localStorage.setItem('client-ip', data)
//             })
//                 .catch(err => {
//                     console.error('err: ', err);
//                     //console.log('amazonCatch==>', err)
//                 })
//         } catch (e) {
//             //console.log('amazonCatch==>', e)
//         }
//     }

// }

export const ChangeNum_Letter = (data) => {

    let unit = [100000000, 10000, 1000, 100, 10], letter = ['억', '만', '천', '백', '십']
    let result = ``
    let index = 0;
    while (data) {
        if (index == 5) {
            if (data > 0) {
                result += data
            }
            break;
        }
        if (data / unit[index] >= 1) {
            result += (parseInt(data / unit[index]) + letter[index]).toString()
            data %= unit[index]
        }
        index++;
    }
    return result;
}

export const navBarTitle = (type) => {
    switch (type) {
        case "home":
            return <div className="headline">
                <Helmet>
                    <title>신뢰할 수 있는 슬롯사이트 소개</title>
                    <meta name="description" content="최고의 슬롯 커뮤니티에서 인기 있는 온라인 슬롯 사이트의 깊이 있는 정보를 탐색하세요.
                    슬롯 이용자들의 상세한 리뷰, 언제나 이용가능한 무료 슬롯, 그리고 슬롯 게임을 더욱 즐겁게 만들어줄 최신 전략과 팁까지! 슬롯 게임의 세계에 빠져보세요.
                    PG슬롯와 함께라면 언제나 최고의 선택을 할 수 있습니다." />
                    <meta name="keywords" content="최고의 슬롯 커뮤니티 - 온라인 슬롯 사이트 추천 PG슬롯" />
                </Helmet>
                <h1>최고의 슬롯 커뮤니티 - 온라인 슬롯 사이트 추천 PG슬롯</h1>
                <p>
                    최고의 슬롯 커뮤니티에서 인기 있는 온라인 슬롯 사이트의 깊이 있는 정보를 탐색하세요.
                    슬롯 이용자들의 상세한 리뷰, 언제나 이용가능한 무료 슬롯, 그리고 슬롯 게임을 더욱 즐겁게 만들어줄 최신 전략과 팁까지! 슬롯 게임의 세계에 빠져보세요.
                    PG슬롯와 함께라면 언제나 최고의 선택을 할 수 있습니다.
                </p>
            </div>;
        case "verifiedSite":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 보증된 안전한 슬롯사이트 목록</title>
                    <meta name="description" content="PG슬롯에서 보증된 안전한 슬롯사이트 목록을 확인하세요.
                    신뢰할 수 있는 슬롯사이트 정보를 통해 안전한 온라인 슬롯 경험을 보장합니다.
                    최고의 사이트에서만 제공되는 다양한 슬롯 게임을 만나보세요." />
                    <meta name="keywords" content="신뢰할 수 있는 슬롯사이트 소개" />
                </Helmet>
                <h1>PG슬롯 보증된 안전한 슬롯사이트 목록</h1>
                <p>
                    PG슬롯에서 보증된 안전한 슬롯사이트 목록을 확인하세요.
                    신뢰할 수 있는 슬롯사이트 정보를 통해 안전한 온라인 슬롯 경험을 보장합니다.
                    최고의 사이트에서만 제공되는 다양한 슬롯 게임을 만나보세요.
                </p>
            </div>;
        case "casinoNews":
            return <div className="headline">
                <Helmet>
                    <title>최신 카지노 슬롯 뉴스와 업데이트 - PG슬롯</title>
                    <meta name="description" content="PG슬롯에서는 카지노 슬롯의 최신 뉴스, 트렌드, 업데이트를 제공해 드리고 있습니다.
                    새로운 슬롯 머신의 출시 정보부터 인기 게임의 전략까지, 슬롯 게임 애호가들을 위한 필수 정보가 가득합니다.
                    최신 트렌드와 업계 소식을 빠르게 접하고 싶다면, PG슬롯를 통해 항상 최전선의 슬롯 뉴스를 경험하세요." />
                    <meta name="keywords" content="최신 카지노 슬롯 뉴스와 업데이트 - PG슬롯" />
                </Helmet>
                <h1>최신 카지노 슬롯 뉴스와 업데이트 - PG슬롯</h1>
                <p>PG슬롯에서는 카지노 슬롯의 최신 뉴스, 트렌드, 업데이트를 제공해 드리고 있습니다.
                    새로운 슬롯 머신의 출시 정보부터 인기 게임의 전략까지, 슬롯 게임 애호가들을 위한 필수 정보가 가득합니다.
                    최신 트렌드와 업계 소식을 빠르게 접하고 싶다면, PG슬롯를 통해 항상 최전선의 슬롯 뉴스를 경험하세요.</p>
            </div>;
        case "point":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 포인트몰</title>
                    <meta name="description" content="PG슬롯 포인트몰에서 슬롯 게임을 통해 적립한 포인트로 다양한 상품을 교환하세요.
                    즐거운 슬롯 게임과 함께 실질적인 보상을 받아보세요.
                    포인트로 교환할 수 있는 다양한 상품과 특별한 혜택을 제공합니다." />
                    <meta name="keywords" content="포인트로 교환 가능한 상품" />
                </Helmet>
                <h1>PG슬롯 포인트몰</h1>
                <p>
                    PG슬롯 포인트몰에서 슬롯 게임을 통해 적립한 포인트로 다양한 상품을 교환하세요.
                    즐거운 슬롯 게임과 함께 실질적인 보상을 받아보세요.
                    포인트로 교환할 수 있는 다양한 상품과 특별한 혜택을 제공합니다.
                </p>
            </div>;
        case "event":
        case "dieevent":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 최신 슬롯 이벤트 정보</title>
                    <meta name="description" content="PG슬롯에서 제공하는 최신 슬롯 이벤트 정보를 확인하세요.
                    다양한 슬롯 게임 이벤트와 프로모션을 통해 더 많은 보상을 받아보세요.
                    새로운 이벤트와 특별한 혜택을 놓치지 마세요." />
                    <meta name="keywords" content="슬롯 게임 이벤트 및 프로모션" />
                </Helmet>
                <h1>PG슬롯 최신 슬롯 이벤트 정보</h1>
                <p>
                    PG슬롯에서 제공하는 최신 슬롯 이벤트 정보를 확인하세요.
                    다양한 슬롯 게임 이벤트와 프로모션을 통해 더 많은 보상을 받아보세요.
                    새로운 이벤트와 특별한 혜택을 놓치지 마세요.
                </p>
            </div>;
        case "freeArticle":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 슬롯 애호가들을 위한 자유게시판</title>
                    <meta name="description" content="PG슬롯의 자유게시판에서 슬롯 애호가들과 소통하고 정보를 공유하세요.
                    다양한 슬롯 게임에 대한 의견과 정보를 나눌 수 있는 공간입니다.
                    슬롯 게임에 대한 질문과 답변을 통해 더 나은 게임 경험을 쌓아보세요." />
                    <meta name="keywords" content="슬롯 커뮤니티 소통 공간" />
                </Helmet>
                <h1>PG슬롯 슬롯 애호가들을 위한 자유게시판</h1>
                <p>
                    PG슬롯의 자유게시판에서 슬롯 애호가들과 소통하고 정보를 공유하세요.
                    다양한 슬롯 게임에 대한 의견과 정보를 나눌 수 있는 공간입니다.
                    슬롯 게임에 대한 질문과 답변을 통해 더 나은 게임 경험을 쌓아보세요.
                </p>
            </div>;
        case "fakeSite":
            return <div className="headline">
                <Helmet>
                    <title>온라인 카지노 먹튀 사이트 목록 - PG슬롯</title>
                    <meta name="description" content="온라인 카지노 먹튀 사이트 목록 - PG슬롯에서는 신뢰할 수 없는 온라인 카지노 사이트를 모아서 제공합니다.
                    이 목록은 유저 제보와 전문 검증을 통해 업데이트 되며, 여러분이 안전하게 게임을 즐길 수 있도록 돕습니다.
                    PG슬롯은 먹튀 사이트에 대한 신뢰할 수 있는 정보를 제공하고, 피해를 최소화하려고 노력합니다." />
                    <meta name="keywords" content="온라인 카지노 먹튀 사이트 목록 - PG슬롯" />
                </Helmet>
                <h1>온라인 카지노 먹튀 사이트 목록 - PG슬롯</h1>
                <p>온라인 카지노 먹튀 사이트 목록 - PG슬롯에서는 신뢰할 수 없는 온라인 카지노 사이트를 모아서 제공합니다.
                    이 목록은 유저 제보와 전문 검증을 통해 업데이트 되며, 여러분이 안전하게 게임을 즐길 수 있도록 돕습니다.
                    PG슬롯은 먹튀 사이트에 대한 신뢰할 수 있는 정보를 제공하고, 피해를 최소화하려고 노력합니다.
                </p>
            </div>;
        case "casinoInfo":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 최신 온라인 슬롯 정보</title>
                    <meta name="description" content="PG슬롯의 최신 온라인 슬롯 정보를 확인하세요.
                    인기 있는 온라인 슬롯 게임에 대한 자세한 정보를 제공하여 최고의 슬롯 경험을 돕습니다.
                    최신 슬롯 게임의 출시 정보와 업데이트 소식을 놓치지 마세요." />
                    <meta name="keywords" content="인기 온라인 슬롯 게임 소개" />
                </Helmet>
                <h1>PG슬롯 최신 온라인 슬롯 정보</h1>
                <p>
                    PG슬롯의 최신 온라인 슬롯 정보를 확인하세요.
                    인기 있는 온라인 슬롯 게임에 대한 자세한 정보를 제공하여 최고의 슬롯 경험을 돕습니다.
                    최신 슬롯 게임의 출시 정보와 업데이트 소식을 놓치지 마세요.
                </p>
            </div>;
        case "SlotPhrase":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 슬롯 게임 용어집</title>
                    <meta name="description" content="PG슬롯의 슬롯 게임 용어집을 통해 슬롯 게임에서 자주 사용되는 용어와 그 의미를 확인하세요.
                    슬롯 게임 이해도를 높여줍니다.
                    초보자부터 고급자까지 모두에게 유용한 슬롯 용어를 쉽게 찾아볼 수 있습니다." />
                    <meta name="keywords" content="슬롯 용어와 의미" />
                </Helmet>
                <h1>PG슬롯 슬롯 게임 용어집</h1>
                <p>
                    PG슬롯의 슬롯 게임 용어집을 통해 슬롯 게임에서 자주 사용되는 용어와 그 의미를 확인하세요.
                    슬롯 게임 이해도를 높여줍니다.
                    초보자부터 고급자까지 모두에게 유용한 슬롯 용어를 쉽게 찾아볼 수 있습니다.
                </p>
            </div>;
        case "slotvideo":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 흥미진진한 슬롯 게임 플레이 영상</title>
                    <meta name="description" content="PG슬롯에서 제공하는 흥미진진한 슬롯 게임 플레이 영상을 감상하세요.
                    최고의 슬롯 게임 플레이를 생생하게 경험해보세요.
                    다양한 전략과 팁을 담은 영상으로 슬롯 게임 실력을 향상시켜 보세요." />
                    <meta name="keywords" content="최고의 슬롯 게임 플레이 영상 모음" />
                </Helmet>
                <h1>PG슬롯 흥미진진한 슬롯 게임 플레이 영상</h1>
                <p>
                    PG슬롯에서 제공하는 흥미진진한 슬롯 게임 플레이 영상을 감상하세요.
                    최고의 슬롯 게임 플레이를 생생하게 경험해보세요.
                    다양한 전략과 팁을 담은 영상으로 슬롯 게임 실력을 향상시켜 보세요.
                </p>
            </div>;
        case "slotGameReview":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 최신 슬롯 게임 리뷰</title>
                    <meta name="description" content="PG슬롯의 최신 슬롯 게임 리뷰를 확인하세요.
                    인기 슬롯 게임의 장단점을 파악하고, 회원들의 솔직한 후기를 통해 최고의 슬롯 게임을 선택하세요.
                    다양한 슬롯 게임에 대한 깊이 있는 분석과 평가를 제공합니다." />
                    <meta name="keywords" content="인기 슬롯 게임 평가 및 후기" />
                </Helmet>
                <h1>PG슬롯 최신 슬롯 게임 리뷰</h1>
                <p>
                    PG슬롯의 최신 슬롯 게임 리뷰를 확인하세요.
                    인기 슬롯 게임의 장단점을 파악하고, 회원들의 솔직한 후기를 통해 최고의 슬롯 게임을 선택하세요.
                    다양한 슬롯 게임에 대한 깊이 있는 분석과 평가를 제공합니다.
                </p>
            </div>;
        case "friendsitereview":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 신뢰할 수 있는 제휴 사이트 리뷰</title>
                    <meta name="description" content="PG슬롯의 신뢰할 수 있는 제휴 사이트 리뷰를 확인하세요.
                    안전한 제휴 사이트를 통해 안전한 슬롯 게임을 즐기세요.
                    사용자들의 평가와 전문가의 리뷰를 통해 최고의 제휴 사이트를 선택하세요." />
                    <meta name="keywords" content="안전한 제휴 사이트 평가" />
                </Helmet>
                <h1>PG슬롯 신뢰할 수 있는 제휴 사이트 리뷰</h1>
                <p>
                    PG슬롯의 신뢰할 수 있는 제휴 사이트 리뷰를 확인하세요.
                    안전한 제휴 사이트를 통해 안전한 슬롯 게임을 즐기세요.
                    사용자들의 평가와 전문가의 리뷰를 통해 최고의 제휴 사이트를 선택하세요.
                </p>
            </div>;
        case "followArticle":
            return <div className="headline">
                <Helmet>
                    <title>온오프라인 카지노 배경으로 펼쳐지는 다양하고 드라마틱한 이야기! - 연재게시판</title>
                    <meta name="description" content="연재게시판은 유저들의 실제 다양한 경험을 바탕으로 제작된 연재물입니다.
                    온라인 카지노와 오프라인 카지노 및 다양한 유흥, 여행 경험 등 흥미로운 사건과 이야기를 중심으로 펼쳐지는 이 연재에서는 카지노 유저들의 실제 경험을 통해 스릴과 재미를 느낄 수 있습니다.
                    각기 다른 배경과 상황 속에서 일어나는 믿기 힘든 사건들을 통해 카지노의 짜릿한 세계를 함께 탐험해보세요." />
                    <meta name="keywords" content="온오프라인 카지노 배경으로 펼쳐지는 다양하고 드라마틱한 이야기! - 연재게시판" />
                </Helmet>
                <h1>온오프라인 카지노 배경으로 펼쳐지는 다양하고 드라마틱한 이야기! - 연재게시판</h1>
                <p>연재게시판은 유저들의 실제 다양한 경험을 바탕으로 제작된 연재물입니다.
                    온라인 카지노와 오프라인 카지노 및 다양한 유흥, 여행 경험 등 흥미로운 사건과 이야기를 중심으로 펼쳐지는 이 연재에서는 카지노 유저들의 실제 경험을 통해 스릴과 재미를 느낄 수 있습니다.
                    각기 다른 배경과 상황 속에서 일어나는 믿기 힘든 사건들을 통해 카지노의 짜릿한 세계를 함께 탐험해보세요.
                </p>
            </div>;
        case "inquires":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 고객센터</title>
                    <meta name="description" content="PG슬롯 고객센터에서 다양한 문의 사항을 해결하세요.
                    슬롯 게임 관련 문제 해결과 고객 지원 서비스를 제공합니다.
                    신속하고 친절한 고객 지원을 통해 최상의 서비스 경험을 누리세요." />
                    <meta name="keywords" content="고객 지원 및 문의" />
                </Helmet>
                <h1>PG슬롯 고객센터</h1>
                <p>
                    PG슬롯 고객센터에서 다양한 문의 사항을 해결하세요.
                    슬롯 게임 관련 문제 해결과 고객 지원 서비스를 제공합니다.
                    신속하고 친절한 고객 지원을 통해 최상의 서비스 경험을 누리세요.
                </p>
            </div>;
        case "freeslot":
            return <div className="headline">
                <Helmet>
                    <title>PG슬롯 무료 슬롯 게임</title>
                    <meta name="description" content="PG슬롯에서 제공하는 다양한 무료 슬롯 게임을 즐기세요.
                    무료로 슬롯 게임을 연습하고 전략을 세우세요.
                    비용 부담 없이 다양한 슬롯 게임을 경험하고, 실력을 쌓아보세요." />
                    <meta name="keywords" content="무료 슬롯 게임으로 연습하기" />
                </Helmet>
                <h1>PG슬롯 무료 슬롯 게임</h1>
                <p>
                    PG슬롯에서 제공하는 다양한 무료 슬롯 게임을 즐기세요.
                    무료로 슬롯 게임을 연습하고 전략을 세우세요.
                    비용 부담 없이 다양한 슬롯 게임을 경험하고, 실력을 쌓아보세요.
                </p>
            </div>;
        default:
            return <div className="headline">
                <Helmet>
                    <title>최고의 슬롯 커뮤니티 - 온라인 슬롯 사이트 추천 PG슬롯</title>
                    <meta name="description" content="최고의 슬롯 커뮤니티에서 인기 있는 온라인 슬롯 사이트의 깊이 있는 정보를 탐색하세요.
                    슬롯 이용자들의 상세한 리뷰, 언제나 이용가능한 무료 슬롯, 그리고 슬롯 게임을 더욱 즐겁게 만들어줄 최신 전략과 팁까지! 슬롯 게임의 세계에 빠져보세요.
                    PG슬롯와 함께라면 언제나 최고의 선택을 할 수 있습니다." />
                    <meta name="keywords" content="최고의 슬롯 커뮤니티 - 온라인 슬롯 사이트 추천 PG슬롯" />
                </Helmet>
                <h1>최고의 슬롯 커뮤니티 - 온라인 슬롯 사이트 추천 PG슬롯</h1>
                <p>
                    최고의 슬롯 커뮤니티에서 인기 있는 온라인 슬롯 사이트의 깊이 있는 정보를 탐색하세요.
                    슬롯 이용자들의 상세한 리뷰, 언제나 이용가능한 무료 슬롯, 그리고 슬롯 게임을 더욱 즐겁게 만들어줄 최신 전략과 팁까지! 슬롯 게임의 세계에 빠져보세요.
                    PG슬롯와 함께라면 언제나 최고의 선택을 할 수 있습니다.
                </p>
            </div>;
    }
};

export const fixedNumber = number => {
    if (isNaN(Number(number))) return "VS";
    // //console.log(number, Math.floor(Number(number) * 100) / 100, Math.floor(Number(number) * 100))
    // return (Math.floor(Number(number) * 100) / 100).toFixed(2);
    return Number(number).toFixed(2);
};

export const autoLogout = () => {
    $(document).ready(() => {
        $('#root').mousemove(() => {
            localStorage.setItem("isMove", true);
        });

        $('#root').mousedown(() => {
            localStorage.setItem("isMove", true);
        });

        $('#root').keydown(() => {
            localStorage.setItem("isMove", true);
        });

        $('#root')[0].addEventListener("touchstart", () => {
            localStorage.setItem("isMove", true);
        });

        const AutoLogoutWarning = () => {
            let isMove = localStorage.getItem("isMove")
            if (isMove == "true" && store.getState().user.isAuthenticated) {
                // if (window.ws.readyState === 1) {
                //   let data = JSON.stringify({ is_move: isMove })
                //   window.ws.send(JSON.stringify({ MessageType: 'HeartBit', data: data }));

                // }
            }
            // isMove = false;
            localStorage.setItem("isMove", false);
            const sendTime = setTimeout(() => {
                AutoLogoutWarning();
            }, 5000);
            if (!store.getState().user.isAuthenticated) {
                clearTimeout(sendTime);
            }
        }

        AutoLogoutWarning();
    })
}

//----------------minigame----------------------//


export const getMinigameUrl = type => {
    // //console.log(type)
    let url = "";
    let params = { gameName: "" };
    switch (type) {
        case 1:
            params = { gameName: `eos_powerball${type}` };
            break;

        case 2:
            params = { gameName: `eos_powerball${type}` };
            break;

        case 3:
            params = { gameName: `eos_powerball${type}` };
            break;

        case 4:
            params = { gameName: `eos_powerball${type}` };
            break;

        case 5:
            params = { gameName: `eos_powerball${type}` };
            break;

        case "1":
            params = { gameName: `eos_powerball${type}` };
            break;

        case "2":
            params = { gameName: `eos_powerball${type}` };
            break;

        case "3":
            params = { gameName: `eos_powerball${type}` };
            break;

        case "4":
            params = { gameName: `eos_powerball${type}` };
            break;

        case "5":
            params = { gameName: `eos_powerball${type}` };
            break;

        default:
            params = { gameName: type };
            break;
    }
    // //console.log(params)

    return new Promise(resolve => {
        axios
            .post(api_url + "/api/Minigame/getGameList", params) // eos_powerball${powerBalltype}
            .then(res => {
                //console.log(params, res.data.gameUrl);
                url = res.data.gameUrl;
                // store.dispatch(setMiniCheck(res.data.status));
                // if(!res.data.status) toastr.warning("점검중입니다.")
                //console.log(url);
                resolve(url);
            })
            .catch(err => {
                console.error('err: ', err);
                if (store.getState().user.isAuthenticated)
                    return store.dispatch(userActions.logout());
                if (err.response && err.response.status === 401) return;
                // store.dispatch(logoutUser());
                else toastr.warning("잠시후 다시 시도해주세요.");
                //console.log(err);
            });
    });

    // return url;
};

export const getMiniType = type => {
    switch (type) {
        case 1:
            return "EOSPowerball1";

        case 2:
            return "EOSPowerball2";

        case 3:
            return "EOSPowerball3";

        case 4:
            return "EOSPowerball4";

        case 5:
            return "EOSPowerball5";

        case "1":
            return "EOSPowerball1";

        case "2":
            return "EOSPowerball2";

        case "3":
            return "EOSPowerball3";

        case "4":
            return "EOSPowerball4";

        case "5":
            return "EOSPowerball5";

        default:
            return type;
    }
};

export const getId = type => {
    switch (type) {
        case 1:
            return "powerball_iframe_parent";

        case 2:
            return "powerball_iframe_parent";

        case 3:
            return "powerball_iframe_parent";

        case 4:
            return "powerball_iframe_parent";

        case 5:
            return "powerball_iframe_parent";

        case "1":
            return "powerball_iframe_parent";

        case "2":
            return "powerball_iframe_parent";

        case "3":
            return "powerball_iframe_parent";

        case "4":
            return "powerball_iframe_parent";

        case "5":
            return "powerball_iframe_parent";

        default:
            return "gopick_iframe_parent";
    }
};

// ----------------------------- Infinite Scroll --------------------------------------------------  //

let isloadList = false;

export const pcSportScrollHandler = () => {
    if (($('#scroll_main')[0].scrollHeight - 20) <= $('#scroll_parent')[0].clientHeight + $('#scroll_parent')[0].scrollTop) {
        const pageData = store.getState().page;
        if (!pageData.loading &&
            pageData.totalPage > 1 &&
            !isloadList
        ) {
            isloadList = true;
            if (pageData.curPage < pageData.totalPage) {
                store.dispatch(pageActions.setPageData({ page: pageData.curPage + 1, totalPage: pageData.totalPage }));
            }
        }
    }
    else {
        isloadList = false;
    }
}

export const mobileSportsWindowScrollHandler = () => {
    const pageData = store.getState().page
    if (window.document.body.scrollHeight > 938 &&
        (document.body.scrollHeight - 20) <= window.scrollY + window.innerHeight) {
        if (!pageData.loading &&
            pageData.totalPage > 1 &&
            !isloadList) {
            isloadList = true;
            if (pageData.curPage < pageData.totalPage) {
                store.dispatch(pageActions.setPageData({ page: pageData.curPage + 1, totalPage: pageData.totalPage }));
            }
        }
    }
    else {
        isloadList = false;
    }
}

export const windowscrollHandler = () => {
    const pageData = store.getState().page
    //console.log('document.body.scrollHeight: ', document.body.scrollHeight);
    if (window.document.body.scrollHeight > 938 &&
        (document.body.scrollHeight - 20) <= window.scrollY + window.innerHeight) {
        if (!pageData.loading &&
            pageData.totalPage > 1 &&
            !isloadList) {
            isloadList = true;
            //   if (pageData.curPage < pageData.totalPage) {
            //     store.dispatch(SetPerPage(pageData.perPage + 10));
            // }
        }
    }
    else {
        isloadList = false;
    }
}

export const pcScrollHandler = () => {
    if (($('#scroll_main')[0].scrollHeight - 20) <= $('#scroll_parent')[0].clientHeight + $('#scroll_parent')[0].scrollTop) {
        const pageData = store.getState().page;
        if (!pageData.loading &&
            pageData.totalPage > 1 &&
            !isloadList
        ) {
            isloadList = true;
            // if (pageData.curPage < pageData.totalPage) {
            //   store.dispatch(SetPerPage(pageData.perPage + 10));
            // }
        }
    }
    else {
        isloadList = false;
    }
}

export const infiniteScroll = () => {
    $(document).ready(() => {
        window.addEventListener("scroll", windowscrollHandler);

        if (!isEmpty($('#scroll_parent')[0])) {
            $('#scroll_parent')[0].addEventListener("scroll", pcScrollHandler);
        }
    })

}


export const clearInfiniteScroll = (type) => {
    //console.log('type: ', type);
    $(document).ready(() => {
        $('html').scrollTop(0)
        if (!isEmpty($('#scroll_parent')[0])) {
            $('#scroll_parent').scrollTop(0)
        }
    });
}


// ----------------------------------- Infinite End ----------------------------------- //


export const getLobbylist = (isshowMessage = true) => {

    return new Promise(resolve => {
        store.dispatch(pageActions.setLoading(true));
        axios
            .post(api_url + `/api/FreeSlot/slotProviders`)
            .then(res => {
                if (!isEmpty(res.data.message)) {
                    store.dispatch(pageActions.setLoading(false));
                }
                if (!isEmpty(res.data.data.list)) {
                    resolve(res.data.data.list);
                    // setApi(res.data.api);
                    // setLists(res.data.result);
                    store.dispatch(pageActions.setLoading(false));
                }
            })
            .catch(err => {
                console.error('err: ', err);
                if (store.getState().user.isAuthenticated)
                    return store.dispatch(userActions.logout());
                store.dispatch(pageActions.setLoading(false));
                if (err.response && err.response.status === 401)
                    store.dispatch(userActions.logout());
                else toastr.warning("잠시후 다시 시도해주세요.");
                //console.log(err);
            });
    });
    // return new Promise(resolve => {
    //     store.dispatch(pageActions.setLoading(true));
    //     axios
    //         .post(api_url + `/api/Slot/getLobbyList`)
    //         .then(res => {
    //             if (!isEmpty(res.data.message)) {
    //                 store.dispatch(pageActions.setLoading(false));
    //                 if (isshowMessage) {
    //                     showMessage(res.data.message);
    //                 }
    //             }
    //             if (!isEmpty(res.data.data.list.lobbyList)) {
    //                 resolve(res.data);
    //                 // setApi(res.data.api);
    //                 // setLists(res.data.result);
    //                 store.dispatch(pageActions.setLoading(false));
    //             }
    //         })
    //         .catch(err => {
    //             console.error('err: ', err);
    //             if (store.getState().user.isAuthenticated)
    //                 return store.dispatch(userActions.logout());
    //             store.dispatch(pageActions.setLoading(false));
    //             if (err.response && err.response.status === 401)
    //                 store.dispatch(userActions.logout());
    //             else toastr.warning("잠시후 다시 시도해주세요.");
    //             //console.log(err);
    //         });
    // });
};
export const getLoginLobbylist = (isshowMessage = true) => {

    return new Promise(resolve => {
        store.dispatch(pageActions.setLoading(true));
        axios
            .post(api_url + `/api/Slot/getLoginLobbyList`)
            .then(res => {
                if (!isEmpty(res.data.message)) {
                    store.dispatch(pageActions.setLoading(false));
                    if (isshowMessage) {
                        showMessage(res.data.message);
                    }
                }
                if (!isEmpty(res.data.lobbyList)) {
                    resolve(res.data);
                    // setApi(res.data.api);
                    // setLists(res.data.result);
                    store.dispatch(pageActions.setLoading(false));
                }
            })
            .catch(err => {
                console.error('err: ', err);
                if (store.getState().user.isAuthenticated)
                    return store.dispatch(userActions.logout());
                store.dispatch(pageActions.setLoading(false));
                if (err.response && err.response.status === 401)
                    store.dispatch(userActions.logout());
                else toastr.warning("잠시후 다시 시도해주세요.");
                //console.log(err);
            });
    });
};

export const getGameList = params => {
    // //console.log(params);
    return new Promise(resolve => {
        store.dispatch(pageActions.setLoading(true));
        axios
            .post(api_url + `/api/FreeSlot/slotgameLists`, params)
            .then(res => {
                if (!isEmpty(res.data.data)) {
                    resolve(res.data.data);
                } else if (!isEmpty(res.data.message)) {
                    store.dispatch(pageActions.setLoading(false));
                }
            })
            .catch(err => {
                console.error('err: ', err);
                if (store.getState().user.isAuthenticated)
                    return store.dispatch(userActions.logout());
                store.dispatch(pageActions.setLoading(false));
                if (err.response && err.response.status === 401)
                    store.dispatch(userActions.logout());
                else toastr.warning("잠시후 다시 시도해주세요.");
                //console.log(err);
            });
    });
    // return new Promise(resolve => {
    //     store.dispatch(pageActions.setLoading(true));
    //     axios
    //         .post(api_url + `/api/Slot/getGameList`, params)
    //         .then(res => {
    //             if (!isEmpty(res.data.data.result)) {
    //                 resolve(res.data);
    //             } else if (!isEmpty(res.data.message)) {
    //                 store.dispatch(pageActions.setLoading(false));
    //                 toastr.warning(res.data.message);
    //             }
    //         })
    //         .catch(err => {
    //             console.error('err: ', err);
    //             if (store.getState().user.isAuthenticated)
    //                 return store.dispatch(userActions.logout());
    //             store.dispatch(pageActions.setLoading(false));
    //             if (err.response && err.response.status === 401)
    //                 store.dispatch(userActions.logout());
    //             else toastr.warning("잠시후 다시 시도해주세요.");
    //             //console.log(err);
    //         });
    // });
};

export const getGameLaunch = params => {
    store.dispatch(pageActions.setLoading(true));
    axios
        .post(api_url + `/api/${params.type}/gameLaunch`, {
            ...params,
            platform: isMobilePage() ? "MOBILE" : "PC"
        })
        .then(res => {
            // //console.log(res);
            store.dispatch(pageActions.setLoading(false));
            if (!isEmpty(res.data.message)) toastr.warning(res.data.message);
            if (!isEmpty(res.data.err)) toastr.warning(res.data.err);

            if (isEmpty(res.data.result)) return;

            // //console.log(res.data.result.link);
            gameOpen(res.data, params.apiName);
        })
        .catch(err => {
            console.error('err: ', err);
            if (store.getState().user.isAuthenticated)
                return store.dispatch(userActions.logout());
            store.dispatch(pageActions.setLoading(false));
            if (err.response && err.response.status === 401)
                store.dispatch(userActions.logout());
            else toastr.warning("잠시후 다시 시도해주세요.");
            //console.log(err);
        });
};

export const getlistImgPath = (list, api) => {
    // //console.log("-----------------------Union List--------------------------", list);
    // console.log(`${resource_url}/casino-slot/${list.img}`)
    return `${resource_url}/images/casino-slot/${list.img}?version=${Date.now()}`;
};

export const gameOpen = (list, api) => {
    const user = store.getState().user.data;
    if (isEmpty(user)) return toastr.warning("로그인하시고 이용해주세요.");

    var width = 1920;
    var height = 1219;
    var toppx = window.screen.height / 2 - height / 2;
    var leftpx = window.screen.width / 2 - width / 2;

    var popup_name = "newWindow";
    //console.log("========팝업 링크 :", list.result.link);
    window.windowHandle = window.open(list.result.link, popup_name, "top=" + toppx + ", left=" + leftpx + ", width=" + width + ", height=" + height + " popup");
    //console.log("========팝업 :", window.windowHandle);
    if (!window.windowHandle) {
        toastr.warning("팝업이 블록되었습니다.");
    }
};

export const convertTypeString = type => {
    switch (type) {
        case "waiting":
            return "대기중";
        case "bet":
            return "대기중"; // slot & casino
        case "lose":
            return "낙첨";
        case "win":
            return "당첨";
        case "refund":
            return "취소됨";
        case "cancel":
            return "취소됨"; // slot & casino
        case "jok":
            return "적특";
        case "draw":
            return "무승부";
        default:
            return type;
    }
};

export const convertClass = type => {
    switch (type) {
        case "waiting":
            return "green-color";
        case "bet":
            return "green-color"; // slot & casino
        case "lose":
            return "red-color";
        case "win":
            return "blue-color";
        case "refund":
            return "grey-color";
        case "cancel":
            return "grey-color"; // slot & casino
        case "jok":
            return "yellow-color";
        case "draw":
            return "yellow-color";
        case 0:
            return "green-color";
        case 1:
            return "red-color";
        case 2:
            return "grey-color";
        case 3:
            return "blue-color";
        case 4:
            return "yellow-color";
        case 5:
            return "half-blue-color";
        case 6:
            return "half-red-color";
        case 7:
            return "draw-color";
        default:
            return "_";
    }
};

export const reComType = type => {
    switch (type) {
        case "slotReviewVideo":
            return "슬롯리뷰";
        case "slotGameReviewLists":
            return "슬롯리뷰"; // slot & casino
        case "cooperationLists":
            return "제휴사이트";
        case "freeArticleList":
            return "자유게시판";
        default:
            return type;
    }
};

export const no_comma = data => {
    var tmp = "";
    var comma = ",";
    var i;

    for (i = 0; i < data.length; i++) {
        if (data.charAt(i) != comma) tmp += data.charAt(i);
    }
    return tmp;
};

export const getLocalstringNumber = (value) => {
    if (isNaN(Number(value))) {
        return 0;
    }
    else {
        return Number(value).toLocaleString();
    }
}

export const checkLoginIdstr = (data) => {

    var re = /[^a-z^A-Z^0-9]/g;
    if (re.test(data)) {
        return true;
    }
    else {
        return false;
    }
}

export const LinkFreeSlot = (type) => {
    switch (type) {
        case "프라그마틱":
            return "https://demogamesfree.pragmaticplay.net/gs2c/html5Game.do?extGame=1&symbol=vs20fruitswx&gname=Sweet%20Bonanza%201000&jurisdictionID=99&lobbyUrl=https%3A%2F%2Fclienthub.pragmaticplay.com%2Fslots%2Fgame-library%2F&mgckey=stylename@generic~SESSION@f7550bbd-3e24-4ac9-bf04-78d48371105c";
        case "노리밋시티":
            return "https://casino.nolimitcdn.com/loader/game-loader.html?game=LandOfTheFree&operator=VAVADA&language=en&device=desktop";
        case "피지소프트":
            return "https://m.pgsoft-games.com/1623475/index.html?l=en&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://www.pgsoftslots.com/&_refer=m.pg-redirect.net&or=static.pgsoft-games.com&_hv=1f8e1d3b";
        case "플래이앤고":
            return "https://qapromo01-cw.playngonetwork.com/casino/ContainerLauncher?gid=coltlightningfirestorm&pid=4&lang=en_GB&practice=1&demo=2&channel=desktop";
        case "릴렉스게이밍":
            return "https://d2drhksbtcqozo.cloudfront.net/casino/games-mt/linebusters/index.html?gameid=linebustersdd&jurisdiction=MT&channel=web&moneymode=fun&partnerid=10&beyondplay=true&fullscreen=false";
        case "하바네로":
            return "https://app-test.insvr.com/games/?brandid=a9d542ac-c9bb-e311-93f6-80c16e0883f4&brandgameid=64651d29-f15e-405d-9758-bef5547e9d64&mode=fun&locale=en&ifrm=1&lobbyurl=pm-closeGame";
        case "씨큐나인":
            return "https://h5c.cqgame.games/h5/242/?language=en&token=guest123";
        case "부운고":
            return "https://bng.games/play/golden_teapot/?lang=ko";
        case "썬더킥":
            return "https://forfun.thunderkick.com/gamelauncher/play/generic?device=desktop&gameId=tk-s1-g76-94&operatorId=2&playMode=demo";
        case "푸쉬게이밍":
            return "https://player.eu.regulated.pushgaming.com/hive/b2c/game/retrosweets-01/client/index.html?igpCode=wildz&guest=true&mode=demo&lang=en&country=GB&meshSessionId=e227135b-7221-46c4-bea7-0c564d95d084&ccyCode=EUR&jurisdiction=NA";
        default:
            return type;
    }
}

const entitys = {
    "&lt;": "<",
    "&gt;": ">",
}

export const changeEditorValue = (value) => {
    let entityValue = value.replace(/(&lt;|&gt;)/g, (s) => entitys[s]);
    return entityValue;
}