import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { navBarTitle, toastr } from '../../Utils';
import { Login } from '../Auth/Login';

export { Navbar };

function Navbar(props) {
    const { type } = props;

    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    const [showModal, setShowModal] = useState(false)

    const onShowModal = () => {
        setShowModal(!showModal)
    }

    useEffect(() => {
        if (isAuthenticated) {
            setShowModal(false)
        }
    }, [isAuthenticated])

    const ifAuthenticated = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            return toastr.warning("로그인하고 이용해주세요.")
        }
    }

    return (
        <nav className="navbar navbar-default" >
            <div className="container">
                {/* <div className="snow_bg">
                    <video className="snow_bg_src" src="../imgs/top_bg_sakura.webm?44114" muted autoPlay="" loop=""></video>
                </div> */}

                {/* <div className="sns_box">
                    <ul>
                        <li><Link to="https://twitter.com/slotbuff4" title="PG슬롯 트위터 바로가기" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://slotbuff3.blogspot.com/" title="PG슬롯 블로그스팟 바로가기" target="_blank"><i className="fab fa-blogger-b" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/uPMf6g" title="PG슬롯 레딧 바로가기" target="_blank"><i className="fab fa-reddit"></i></Link></li>
                        <li><Link to="https://medium.com/@tgbuff01/" title="PG슬롯 미디움 바로가기" target="_blank"><i className="fab fa-medium-m" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/8IrBrNa" title="PG슬롯 핀터레스트 바로가기" target="_blank"><i className="fab fa-pinterest" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://www.youtube.com/channel/UCTpJ7c4nxO6CS-fk8bl0UpQ?app=desktop" title="PG슬롯 유튜브 바로가기" target="_blank"><i className="fab fa-youtube" aria-hidden="true"></i></Link></li>
                    </ul>
                </div> */}

                {/*************-- 모바일 로고 상단메뉴 ---************/}
                <div className="m_logo_box">
                    <div className="m_top_menu">
                        <div className="m_rj">
                            <div className="m_telegram"><Link to="https://t.me/slotbuff" target="_blank" title="텔레그램문의"><img src="../imgs/m_telegram.png" width="38px" height="38px" alt="텔레그램" /></Link></div>

                        </div>
                        <div className="m_logo"><Link to="/" title="PG슬롯"><img src="../imgs/logo_slotbuff_m_240.png" alt="슬롯커뮤니티 먹튀검증 카지노사이트 PG슬롯" /></Link></div>
                        <div className="m_lj">
                            {isAuthenticated ?
                                <Link to="/mypage" title="로그인" className="mobile_top_btn">
                                    <div className="mypage_btn"><img width="38px" height="38px" src="../imgs/m_login_before.png" alt="로그인" /></div>
                                </Link> :
                                <div title="로그인" className="mobile_top_btn" onClick={onShowModal}>
                                    <div className="mypage_btn"><img width="38px" height="38px" src="../imgs/m_login_before.png" alt="로그인" /></div>
                                </div>
                            }
                        </div>
                        <div className="m_search" style={{ display: "none" }}>
                            <div className="search_box">
                                <form name="header_search" id="header_search" action="https://slotbuff1.com/search">
                                    <input type="text" placeholder="검색어를입력해주세요" className="input" name="skeyword" accessKey="s" />
                                    <button className="search_btn" type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="m_nav_box">
                        <div className="frame">
                            <ul className="slidee">

                                <li><Link to="/verifiedsite" title="보증사이트"><div className="icon"><i className="fas fa-medal" aria-hidden="true"></i></div><div className="txt">보증사이트</div></Link></li>
                                <li><Link to="/slotgamereview" title="슬롯리뷰"><div className="icon"><i className="fas fa-video" aria-hidden="true"></i></div><div className="txt">슬롯리뷰</div></Link></li>
                                {/* <li><Link to="/bestreview" title="베스트리뷰"><div className="icon"><i className="fas fa-video" aria-hidden="true"></i></div><div className="txt">베스트리뷰</div></Link></li> */}
                                <li><Link to="/slotvideo" title="슬롯리뷰영상"><div className="icon"><i className="fa-solid fa-folder-image" aria-hidden="true"></i></div><div className="txt">슬롯리뷰영상</div></Link></li>
                                <li><Link to="/friendsitereview" title="제휴사이트리뷰"><div className="icon"><i className="fa-solid fa-folder-image" aria-hidden="true"></i></div><div className="txt">제휴사이트리뷰</div></Link></li>
                                <li><Link to="/freearticle" title="자유게시판"><div className="icon"><i className="fas fa-dice" aria-hidden="true"></i></div><div className="txt">자유게시판</div></Link></li>
                                {/* <li><Link to="/followarticle" title="연재게시판"><div className="icon"><i className="fas fa-book" aria-hidden="true"></i></div><div className="txt">연재게시판</div></Link><div className="new">N</div></li> */}
                                <li><Link to="/info" title="온라인 슬롯 정보"><div className="icon"><i className="fas fa-newspaper" aria-hidden="true"></i></div><div className="txt">온라인 슬롯 정보</div></Link></li>
                                <li><Link to="/dictionary" title="슬롯 용어집"><div className="icon"><i className="fas fa-book-open" aria-hidden="true"></i></div><div className="txt">슬롯 용어집</div></Link></li>
                                {/* <li><Link to="/casinonews" title="카지노뉴스"><div className="icon"><i className="far fa-newspaper" aria-hidden="true"></i></div><div className="txt">카지노뉴스</div></Link></li> */}
                                {/* <li><Link to="/fakesite" title="먹튀사이트"><div className="icon"><i className="fas fa-skull-crossbones" aria-hidden="true"></i></div><div className="txt">먹튀사이트</div></Link></li> */}
                                <li><Link to="/pointmall" title="포인트몰"><div className="icon"><i className="fas fa-smile-plus" aria-hidden="true"></i></div><div className="txt">포인트몰</div></Link></li>
                                <li><Link to="/freeslot" title="무료슬롯"><div className="icon"><i className="fas fa-gamepad" aria-hidden="true"></i></div><div className="txt">무료슬롯</div></Link></li>
                                <li><Link to="/liveevent" title="이벤트"><div className="icon"><i className="fa-solid fa-trophy" aria-hidden="true"></i></div><div className="txt">이벤트</div></Link></li>
                                <li><Link to="/inquires" title="고객센터"><div className="icon"><i className="fas fa-user" aria-hidden="true"></i></div><div className="txt">고객센터</div></Link></li>
                                {/* <li className="pointgame"><Link to="/minigame/EOS1" title="미니게임" onClick={(e) => ifAuthenticated(e)}><div className="icon"><i className="fa-solid fa-pinball" aria-hidden="true"></i></div><div className="txt">미니게임</div></Link></li> */}
                                {/* <li className="pointgame"><Link to="/minigame/roulette" title="룰렛"><div className="icon"><i className="fa-solid fa-slot-machine" aria-hidden="true"></i></div><div className="txt">미니게임 룰렛</div></Link></li> */}
                            </ul>
                        </div>
                    </div>

                    <div className="m_main_banner">
                    </div>
                </div>

                {/************************모바일 로고 상단메뉴 ************************** */}

                {/***************************************************Brand and toggle get grouped for better mobile displa****************************** */}
                <div className="logo_box">
                    <Link to="/" className="logo" title="PG슬롯">
                        <img src="../imgs/logo_slotbuff_m_240.png" />
                    </Link>

                    <div className="headline_box">
                        {navBarTitle(type)}
                    </div>
                    <div className='navbar-right'>
                        <img src='../imgs/login/header-pannel.png' alt='right-image' style={{ width: "500px", height: "auto" }} />
                    </div>
                </div>
                <div className="nav_box " id="topmenu-navbar-collapse">
                    <ul style={{ display: "flex", width: "100%", justifyContent: "space-between", padding: "0px 70px" }}>
                        {/* <li><Link to="/" title="home" className="menu"><i className="fas fa-home-lg-alt" aria-hidden="true"></i></Link></li> */}
                        <li><Link to="/verifiedsite" title="보증사이트" className="menu">보증사이트</Link></li>
                        <li className="dropdown">
                            <dl title="커뮤니티" className="menu cursor-pointer">커뮤니티 <span className="down_arrow"><i className="fad fa-angle-down" aria-hidden="true"></i></span></dl>
                            <dl className="dropdown-menu normal_drop">
                                <dt><Link to="/slotgamereview" title="슬롯리뷰"><i className="fas fa-folder-image" aria-hidden="true"></i> 슬롯리뷰</Link></dt>
                                {/* <dt><Link to="/bestreview" title="베스트리뷰"><i className="fas fa-video" aria-hidden="true"></i> 베스트리뷰</Link></dt> */}
                                <dt><Link to="/slotvideo" title="슬롯리뷰영상"><i className="fa-solid fa-video" aria-hidden="true"></i> 슬롯리뷰영상</Link></dt>
                                <dt><Link to="/friendsitereview" title="제휴사이트리뷰"><i className="fa-solid fa-folder-image" aria-hidden="true"></i> 제휴사이트리뷰</Link></dt>
                                <dt><Link to="/freearticle" title="자유게시판" className="imgs"><i className="fas fa-dice" aria-hidden="true"></i> 자유게시판</Link></dt>
                                {/* <dt><Link to="/followarticle" title="연재게시판" className="imgs"><div className="up_icon"><img src="../imgs/serial_up_icon.png" alt="업로드되었습니다." /></div><i className="fa-solid fa-book" aria-hidden="true"></i> 연재게시판</Link></dt> */}
                            </dl>
                        </li>
                        {/*************- <li><Link to="/board/serial" title="카지노연재" className="menu">카지노연재</></li> -************/}
                        <li className="dropdown">
                            {/* <div className="new"></div> */}
                            <dl title="이벤트" className="menu cursor-pointer">이벤트 <span className="down_arrow"><i className="fad fa-angle-down" aria-hidden="true"></i></span></dl>
                            <dl className="dropdown-menu normal_drop">
                                <dt><Link to="/liveevent" title="진행중이벤트"><i className="fa-sharp fa-solid fa-calendar-check" aria-hidden="true"></i> 진행중 이벤트</Link></dt>
                                <dt><Link to="/dieevent" title="종료된이벤트"><i className="fa-solid fa-xmark-to-slot" aria-hidden="true"></i> 종료된 이벤트</Link></dt>
                            </dl>
                        </li>
                        <li className="dropdown">
                            {/* <div className="new"></div> */}
                            <dl title="슬롯 정보" className="menu cursor-pointer">슬롯 정보 <span className="down_arrow"><i className="fad fa-angle-down" aria-hidden="true"></i></span></dl>
                            <dl className="dropdown-menu normal_drop">
                                <dt><Link to="/info" title="온라인 슬롯 정보"><i className="fa-sharp fa-solid fa-calendar-check" aria-hidden="true"></i> 온라인 슬롯 정보</Link></dt>
                                <dt><Link to="/dictionary" title="슬롯 용어집"><i className="fas fa-book-open" aria-hidden="true"></i> 슬롯 용어집</Link></dt>
                            </dl>
                        </li>
                        {/* <li><Link to="/info" title="슬롯 정보" className="menu">슬롯 정보</Link></li> */}
                        {/* <li><Link to="/casinonews" title="카지노뉴스" className="menu">카지노뉴스</Link></li> */}
                        <li><Link to="/freeslot" title="무료슬롯" className="menu">무료슬롯</Link></li>
                        {/* <li><Link to="/fakesite" title="먹튀사이트" className="menu">먹튀사이트</Link></li> */}
                        <li><Link to="/pointmall" title="포인트몰" className="menu">포인트몰</Link></li>
                        {/* <li><Link to="/minigame/EOS1" title="미니게임" className="menu" onClick={(e) => ifAuthenticated(e)}>미니게임</Link></li> */}
                        <li className="dropdown">
                            <dl title="고객센터" className="menu cursor-pointer">고객센터<span className="down_arrow"><i className="fad fa-angle-down" aria-hidden="true"></i></span></dl>
                            <dl className="dropdown-menu normal_drop">
                                <dt><Link to="/notice" title="공지사항" className="menu"><i className="fa-sharp fa-solid fa-envelopes" aria-hidden="true"></i>&nbsp;공지사항</Link></dt>
                                <dt><Link to="/inquires" title="1:1 문의" className="menu"><i className="fa-sharp fa-solid fa-comments-alt" aria-hidden="true"></i>&nbsp;1:1 문의</Link></dt>
                            </dl>
                        </li>
                    </ul>
                </div>

            </div>



            {/************* Collect the nav links, forms, and other content for toggling ************/}

            {/************* /.navbar-collapse ************/}

            {/*************------   미니게임 메뉴영역 ----------************/}
            {
                type === "minigame" &&
                <div className="game_tab_menu_area">
                    <div className="tab_menu">
                        <NavLink to="/minigame/EOS1" title="EOS 1분">
                            <span className="txt">EOS 1분</span>
                        </NavLink>
                        <NavLink to="/minigame/EOS2" title="EOS 2분">
                            <span className="txt">EOS 2분</span>
                        </NavLink>
                        <NavLink to="/minigame/EOS3" title="EOS 3분">
                            <span className="txt">EOS 3분</span>
                        </NavLink>
                        <NavLink to="/minigame/EOS4" title="EOS 4분">
                            <span className="txt">EOS 4분</span>
                        </NavLink>
                        <NavLink to="/minigame/EOS5" title="EOS 5분">
                            <span className="txt">EOS 5분</span>
                        </NavLink>
                        <NavLink to="/minigame/EVO" title="EVO파워볼">
                            <span className="txt">EVO파워볼</span>
                        </NavLink>
                        <NavLink to="/minigame/nuguri" title="너구리">
                            <span className="txt">너구리</span>
                        </NavLink>
                        <NavLink to="/minigame/soccer" title="축구">
                            <span className="txt">축구</span>
                        </NavLink>
                        <NavLink to="/minigame/baseball" title="야구">
                            <span className="txt">야구</span>
                        </NavLink>
                        <NavLink to="/minigame/squidgame" title="오징어" style={{ marginRight: "0px" }}>
                            <span className="txt">오징어</span>
                        </NavLink>
                    </div>
                </div>
            }
            {/*************------ ///////////  미니게임 메뉴영역 ----------************/}
            {(showModal) &&
                <Login />
            }
        </nav>
    );
}
