import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getMiniType, isEmpty } from '../../../Utils';
import { miniActions } from '../../../store';
import { getgameGrps, prices } from '../../../Utils/names';



export default Betting;

function Betting(props) {

    const dispatch = useDispatch();
    const {rates, leftTime, state, miniCheck, data} =useSelector(state => state.mini)
    const { type } = props;
        
    const roomObj = {
        pageType: getMiniType(type),
        RoomType: getMiniType(type),
        sportType: 'all',
        gameType: 'all',
        sportId: 'all'
      }

    useEffect(()=>{

    const roomData = {
        MessageType: "RoomType",
        data: roomObj
      };
      if(window.ws.readyState === 1)
        window.ws.send(JSON.stringify(roomData));
        
        dispatch(miniActions.setBetType(type));
        dispatch(miniActions.setMiniCheck(false));
    }, [type])

    const onClickBetButton = (data) => {
        let params = {
            odd_key: data.odd_key,
            rate: data.price,
            name: isEmpty(data.name_other) ? data.name : data.name_other
        }
        dispatch(miniActions.setBetData(params));
    }
        const gameGrps = getgameGrps(type);
        const ballPrice = prices(type, rates);
        let cnt = 0;

        const buttion = (param) => {
            return (
                <li  key={param.index}
                    onClick={() => onClickBetButton(param)}>
                    <button  className={`${param.color} minigameBtn ${param.odd_key === data.odd_key && "active" }`}>
                        <b >{isNaN(param.price) ? 1 : param.price}</b>
                        <i >
                            <span >{param.name}</span>
                        </i>
                    </button>
                </li>
            )
        }
        return (
            <div  className="bg_normal_2">
                <div  className="padding-10px-all no-padding-top">
                    <div  className="mini_game_info">
                        <span  style={{ background: "#dd4cff" }}><em style={{fontStyle: "normal"}}>회차</em><b >{isNaN(state.round) ? 0 : Number(state.round).toLocaleString()}회차</b></span>
                        <span  ><em style={{fontStyle: "normal"}}>남은 시간</em> <b >{!isEmpty(state.lefttime) ? (state.lefttime < 0 ? 0 : state.lefttime) : 0}</b></span>
                    </div>
                    <div  className="position-relative">
                        <div  className="mini_button">
                            {
                                 gameGrps.map((gamegrp, grpkey) => (
                                    <div  className="flex_1dan" key={grpkey}>
                                        <em  className="title_bet_s">{gamegrp.label}</em>
                                        <ul  className={`ul_game_item item_${gamegrp.buttons.length}`}>
                                            {
                                                gamegrp.buttons.map((button, buttonkey) => {
                                                    return buttion({ ...button, price: Number(ballPrice[cnt++]), index: buttonkey })
                                                }
                                                )
                                            }
                                        </ul>
                                    </div>
                                ))
                            }
                            <div  className="mini_bet_close" style={{ display: miniCheck ? "none" : "" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        );
}