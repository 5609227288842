import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const infoLists = [
    // {
    //     name: "카지노 보증사이트", 
    //     value: "verifiedsite", 
    //     path: "/verifiedsite", 
    //     title: "카지노 보증사이트", 
    //     content: <Fragment>완벽하게 검증된 보증사이트 목록입니다.<br />먹튀시 100% 보상해 드립니다.</Fragment>, 
    //     iconClassName: "fas fa-medal"
    // },
    {
        name: "포인트몰", 
        value: "pointmall", 
        path: "/pointmall", 
        title: "포인트몰", 
        content: <Fragment> 회원가입 및 출석체크 혹은 슬롯리뷰, <br />슬롯리뷰, 빅윈, 맥스윈 영상 제보를 통한 혜택이 와르르!</Fragment>, 
        iconClassName: "fas fa-coins"
    },
    // {
    //     name: "먹튀사이트", 
    //     value: "fakesite", 
    //     path: "/fakesite", 
    //     title: "먹튀사이트", 
    //     content: <Fragment> 유저들의 제보로 이루어진<br />카지노 먹튀사이트 목록입니다.</Fragment>, 
    //     iconClassName: "fas fa-skull-crossbones"
    // },
    {
        name: "슬롯 정보", 
        value: "info", 
        path: "/info", 
        title: "슬롯 정보", 
        content: <Fragment> 카지노&amp;슬롯 관련 최신뉴스와 유용한정보를 업로드해 드립니다. 다양한 정보를 받아가시기 바랍니다.</Fragment>, 
        iconClassName: "fas fa-book-open"
    },
    // {
    //     name: "무료슬롯", 
    //     value: "freeslot", 
    //     path: "/freeslot", 
    //     title: "무료슬롯-인기슬롯게임", 
    //     content: <Fragment> 무료 슬롯 체험은 온라인 슬롯을 처음 접하거나 다양한 게임을 탐색하고 싶은 사용자들에게 이상적입니다.</Fragment>, 
    //     iconClassName: "fas fa-book"
    // },
    // {
    //     name: "포인트몰", 
    //     value: "pointmall", 
    //     path: "/pointmall", 
    //     title: "커뮤니티 포인트", 
    //     content: <Fragment> 회원가입 및 출석체크 혹은 슬롯리뷰, 슬롯리뷰, 빅윈, 맥스윈 영상 제보를 통한 혜택이 와르르! </Fragment>, 
    //     iconClassName: "far fa-newspaper"
    // },
    {
        name: "고객센터", 
        value: "inquires", 
        path: "/inquires", 
        title: "고객센터", 
        content: <Fragment> 각종 문의사항은 고객센터 게시판 혹은 텔레그램으로 문의 주시기 바랍니다.</Fragment>, 
        iconClassName: "fas fa-comments"
    },
]

export { HomeInfoBox };

function HomeInfoBox() {
    return (
        <div className="info_box_area">
            <ul>
                {
                    infoLists.map((list, index) => {
                        return (
                            <li key={index}>
                                <Link to={list.path} title={list.name} className="upper_link"></Link>
                                <div className="box">
                                    <div className="icons"><i className={list.iconClassName}></i></div>
                                    <dl>
                                        <dt>{list.title}</dt>
                                        <dd>{list.content}</dd>
                                    </dl>
                                </div>
                            </li>
                        )
                    })
                }
                {/* <li>
                    <Link to="/verifiedsite" title="카지노보증사이트" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-medal" aria-hidden="true"></i></div>
                        <dl>
                            <dt>카지노 보증사이트</dt>
                            <dd>완벽하게 검증된 보증사이트 목록입니다.<br />먹튀시 100% 보상해 드립니다.</dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/pointmall" title="포인트몰" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-poo" aria-hidden="true"></i></div>
                        <dl>
                            <dt>포인트몰</dt>
                            <dd>회원가입 및 출석체크 혹은 슬롯리뷰, <br />슬롯리뷰, 빅윈, 맥스윈 영상 제보를 통한 혜택이 와르르!</dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/fakesite" title="먹튀사이트" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-skull-crossbones" aria-hidden="true"></i></div>
                        <dl>
                            <dt>먹튀사이트</dt>
                            <dd>유저들의 제보로 이루어진<br />카지노 먹튀사이트 목록입니다.</dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/info" title="카지노정보" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-book-open" aria-hidden="true"></i></div>
                        <dl>
                            <dt>슬롯 정보</dt>
                            <dd>카지노&amp;슬롯 관련 최신뉴스와 유용한정보를 업로드해 드립니다. 다양한 정보를 받아가시기 바랍니다.</dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/freeslot" title="무료슬롯" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-book" aria-hidden="true"></i></div>
                        <dl>
                            <dt>무료슬롯-인기슬롯게임</dt>
                            <dd>무료 슬롯 체험은 온라인 슬롯을 처음 접하거나 다양한 게임을 탐색하고 싶은 사용자들에게 이상적입니다.</dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/pointmall" title="포인트몰" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="far fa-newspaper" aria-hidden="true"></i></div>
                        <dl>
                            <dt>커뮤니티 포인트</dt>
                            <dd>회원가입 및 출석체크 혹은 슬롯리뷰, 슬롯리뷰, 빅윈, 맥스윈 영상 제보를 통한 혜택이 와르르! </dd>
                        </dl>
                    </div>
                </li>
                <li>
                    <Link to="/inquires" title="고객센터" className="upper_link"></Link>
                    <div className="box">
                        <div className="icons"><i className="fas fa-comments" aria-hidden="true"></i></div>
                        <dl>
                            <dt>고객센터</dt>
                            <dd>먹튀신고, 먹튀제보, 문의사항은 텔레그램 또는 고객센터로 문의 주시기 바랍니다.</dd>
                        </dl>
                    </div>
                </li> */}
            </ul>
        </div>
    );
}