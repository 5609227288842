import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { FakeSiteContent } from '../../Components/VerifiedSite';


export { FakeSite };

function FakeSite() {
    return (
        <Fragment>
            <Navbar type="fakeSite" />
            <div className='main'>
                <FakeSiteContent />
            </div>
        </Fragment>
    );
}