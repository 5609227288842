import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';
import { eventActions, } from '../../store';
import { dateFormation, isEmpty } from '../../Utils';

export { CouponHistoryContent };

function CouponHistoryContent() {

    const dispatch = useDispatch();
    const couponHistoryList = useSelector(state => state.event.couponHistoryList);



    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(eventActions.getCouponHistoryList(params));
    }
    useEffect(() => {
        getList();
    }, []);

    let userGold = 0;

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {/* <RightSideBar /> */}
                <div className="main_area">
                    <div className="board">
                        <div style={{ padding: "0px 5px" }}>
                            <table className="table table-hover normal_board table-bordered">
                                <thead>
                                    <tr>
                                        <th> 번호 </th>
                                        <th>업체명</th>
                                        <th>상세내역</th>
                                        <th>날짜</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {couponHistoryList &&
                                        couponHistoryList.map((list, index) => {
                                            return (
                                                <tr className="notice_list important" key={index}>
                                                    <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                    <td className="center">{list.agent}</td>

                                                    <td>
                                                        {list.couponData &&
                                                            list.couponData.map((item, index) => {
                                                                if (item.count > 0) {
                                                                    return (
                                                                        <div className='d-flex justify-content-center' key={index}>
                                                                            <span className='mr10'>쿠폰머니:&nbsp;{item.gold}원</span>
                                                                            <span>쿠폰갯수:&nbsp; {item.count}개</span>
                                                                        </div>
                                                                    )
                                                                }

                                                            })
                                                        }
                                                    </td>
                                                    <td>{dateFormation(list.created_date, 9)}</td>
                                                </tr>
                                            )
                                        })

                                    }

                                </tbody>
                                {
                                    isEmpty(couponHistoryList) &&
                                    <tbody>
                                        <tr>
                                            <td colSpan="4" className="nopost">내역이 없습니다</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>

                        {!isEmpty(couponHistoryList) &&
                            <Pagination pageInfo={pageInfo} getList={getList} />
                        }
                    </div>
                </div>
                <SideBar />
            </div>
        </div>
    );

}