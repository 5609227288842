import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { request } from '../../../Utils/api';
import { api_url, resource_url } from '../../../Utils/keys';
import { changeEditorValue, dateFormation, isEmpty, toastr } from '../../../Utils';
import { Pagination } from '../../Common';

export { VerifiedDetail };


const VerifiedDetail = ({
    pageInfo,
    setPageInfo,
    onBtnClick,
    getList,
    verifiedList,
    setActive,
    active,
    length,
}) => {


    const userData = useSelector(state => state.user.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const verifiedArticleList = useSelector(state => state.site.answerList);

    const [editArticle, setEditArticle] = useState("");



    const onChange = (e) => {
        setEditArticle(e.target.value)
    }

    const onArticleSend = () => {
        if (isEmpty(editArticle)) {
            return toastr.warning("댓글을 입력해주세요.")
        }
        const params = {
            type: "VerifySites",
            answerList: editArticle,
            typeId: verifiedList.id,
        }
        request.post(api_url + "/api/Answer/answerArticle", params)
            .then(data => {
                if (data.success) {
                    toastr.success(data.message)
                    getList(1, 10, active)
                    setEditArticle("")
                }
            })
        if (window.ws.readyState === 1 || window.ws.readyState === 0) {

            window.ws.send(JSON.stringify({ MessageType: 'ChangeAnswer', data: "Status" }));
        }
    };

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
            getList(1, 10, 0)
        } else {
            setActive(key);
            getList(1, 10, key)
        }
    }

    return (
        <div className="board warranty_board">
            <div className="contents-view">

                <div id="post-content">

                    <div className="post_content">
                        <div className="new_warranty_post">
                            <div className="left_post">
                                <div className="game_logo"><br />
                                    <img src={`${resource_url}/${verifiedList.images.logoImg}`} alt={`${verifiedList.siteNameKor} &amp; 슬롯사이트`} /><br />
                                </div>
                                <div className="site_title">{verifiedList.siteNameKor}&amp; 카지노&amp;슬롯사이트</div>
                                <h1>PG슬롯에서 보증하는 {verifiedList.siteNameKor}&amp; 카지노&amp;슬롯사이트<br />100% 검증된 메이저슬롯머신사이트입니다.</h1>
                                <div className="box">
                                    <span>가입도메인 :
                                        <a href={`https://${verifiedList.siteAddress}/`} target="_blank">{verifiedList.siteAddress}</a>
                                    </span>&nbsp;
                                    <span>코드 : CITY</span>
                                </div>
                                <div className="post_img">
                                    <a href={`https://${verifiedList.siteAddress}/`} target="_blank">
                                        <img
                                            src={`${resource_url}/${verifiedList.images.mainImg}`}
                                            alt={`${verifiedList.siteNameKor} &amp; 슬롯사이트`}
                                            title={verifiedList.siteNameKor}
                                        />
                                    </a>
                                </div>
                                <div className="post_txt_box">
                                    <div>
                                        <p>가입이벤트</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ "__html": !isEmpty(verifiedList.detail.join_event) ? changeEditorValue(verifiedList.detail.join_event) : "" }}>
                                    </div>
                                </div>
                                <div className="post_txt_box">
                                    <div>
                                        <p>이벤트</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ "__html": !isEmpty(verifiedList.detail.event) ? changeEditorValue(verifiedList.detail.event) : "" }}>
                                    </div>
                                </div>

                                <div className="post_txt_box">
                                    <div>
                                        <p>정보</p>
                                    </div>
                                    <div dangerouslySetInnerHTML={{ "__html": !isEmpty(verifiedList.detail.information) ? changeEditorValue(verifiedList.detail.information) : "" }}>
                                    </div>
                                </div>
                                <div className="post_txt_box" dangerouslySetInnerHTML={{ "__html": !isEmpty(verifiedList.detail.contentImg) ? changeEditorValue(verifiedList.detail.contentImg) : "" }}>
                                </div>
                                <dl>
                                    <dt>보증제도란?</dt>
                                    <dd>PG슬롯에서 제휴중인 슬롯사이트에서 먹튀 발생 시 보유하신 금액의 100%를 보상해 주는 제도입니다. 온라인슬롯 프라그마틱의 경우 맥스윈 당첨시 적게는
                                        1천만원 많게는 1억원이 당첨되는 유저분들이 꽤 있습니다. 제휴사이트에서 1~2억원식의 보증금을 받는 사유도 회원분들의 고액 당첨 시 보상을 완벽하게 해드리기
                                        위함입니다. 이제 먹튀 걱정없이 PG슬롯에서 보증하는 제휴사이트에서 안전하게 슬롯머신을 즐겨보시길 바랍니다.</dd>
                                </dl>
                                <div className="box">
                                    <span>가입도메인 :
                                        <a href={`https://${verifiedList.siteAddress}/`} target="_blank">{verifiedList.siteAddress}</a>
                                    </span>&nbsp;
                                    <span>코드 : CITY</span>
                                </div>
                            </div>
                            <div className="right_post">
                                <a href={`https://${verifiedList.siteAddress}/`} target="_blank">
                                    <img
                                        src={`${resource_url}/${verifiedList.images.sideImg}`}
                                        alt={`${verifiedList.siteNameKor} &amp; 슬롯사이트`}
                                        title={verifiedList.siteNameKor}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="clearfix"></div>
            {isAuthenticated &&
                <div id="comment_write_box">
                    <div className="well comment_write_box_inner">
                        <div id="fcomment" acceptCharset="utf-8"
                            noValidate="novalidate">
                            <div className="comment_area">
                                <ul>
                                    <li>
                                        <textarea
                                            className="comment_write"
                                            placeholder="광고, 심한욕설의 댓글은 경고없이 삭제됩니다."
                                            name="editArticle"
                                            id="editArticle"
                                            value={editArticle}
                                            onChange={onChange}
                                        >
                                        </textarea>
                                    </li>
                                    <li>
                                        <div role="group" aria-label="...">
                                            <button type="button" className="commet_btn" id="cmt_btn_submit" onClick={onArticleSend}>댓글등록</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="comment_write_button_area">
                            </div>
                        </div>
                    </div>
                </div>}
            <div id="viewcomment">
                <div className="alert alert-auto-close alert-dismissible alert-comment-list-message" style={{ display: "none" }}><button
                    type="button" className="close alertclose">×</button><span
                        className="alert-comment-list-message-content"></span></div>


                {verifiedArticleList &&
                    verifiedArticleList.map((list, index) => {
                        return (
                            <div className="media" key={index} id="comment_199609" style={{ paddingLeft: "0%" }}>
                                <div className="media-body">
                                    <div className="media-heading">
                                        <span className="name mr10">
                                            <div className="user_levelname"><span className="lv_icon_box">
                                                <div className={`lv_icon lv_icon${list.level > 6 ? 1 : list.level}`}></div>
                                            </span><span className="user_name">{list.loginId}</span></div>
                                        </span>
                                        <span className="time"><i className="fa fa-clock-o mr10" aria-hidden="true"></i>{dateFormation(list.created_date, 3)}</span>
                                    </div>
                                    <div className="media-content">{list.answerList}</div>
                                </div>
                            </div>
                        )
                    })
                }
                {isEmpty(verifiedArticleList) &&
                    <div className="media" id="comment_199609" style={{ paddingLeft: "0%" }}>
                        <div className="media-body">
                            <div className="media-content">내역이 없습니다.</div>
                        </div>
                    </div>
                }
                {!isEmpty(verifiedArticleList) &&
                    <Pagination
                        pageInfo={pageInfo}
                        setPageInfo={(params) => setPageInfo(params)}
                        getList={getList} />
                }

            </div>

            <div className="border_button mt20 mb20">
                <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                    <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                </div>
            </div>
        </div>
    );
};
