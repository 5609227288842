import React, { Fragment } from 'react';
import { HomeContent } from '../../Components/HomeDetail';
import { Navbar } from '../../Components/Layout';


export { Home };

function Home() {
    return (
        <Fragment>
            <Navbar type="home" />
            <div className='main'>
                <HomeContent />
            </div>
        </Fragment>
    );


}