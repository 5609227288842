import React from "react";

export { ModalBack };


function ModalBack(props) {
    const { onClose, transparent } = props;
    return (
        <div
            className="modal-backdrop fade show"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: transparent ? "transparent" : "#000000AA" }}
            aria-hidden="true"
            onClick={onClose}>
        </div>
    );
}

