
import React from 'react';
import { changeEditorValue, isEmpty } from '../../../Utils';
import { resource_url } from '../../../Utils/keys';

export { SlotPhraseDetail };



const SlotPhraseDetail = ({
    onBtnClick,
    slotDictionary,
    setActive,
    active,
    length,
}) => {

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }

    const rlt = [];
    let titleLists = [];
    slotDictionary.forEach(item => {
        titleLists.push(item)
    });
    if (titleLists.length > 0) {
        for (let i = 0; i < titleLists.length; i++) {
            if (titleLists[i].parent == "") {
                rlt.push(titleLists[i]);
                titleLists.splice(i, 1);
                break;
            }
        }
        get_rlt(rlt[0]);
        function get_rlt(pnt) {
            if (!isEmpty(pnt)) {
                for (let i = 0; i < titleLists.length; i++) {
                    if (pnt.id == titleLists[i].parent) {
                        rlt.push(titleLists[i]);
                        titleLists.splice(i, 1);
                        get_rlt(rlt[rlt.length - 1]);
                    }
                }
            }
        }
    }


    return (<>
        <div className="page_seo_tit_box">
            <h2>슬롯용어집안내</h2>
            <p>PG슬롯은 온라인 카지노의 전반적인 정보를 제공하는 최고의 안내 플랫폼입니다.
                사용자에게 먹튀 없는 안전한 카지노 사이트 추천부터 신규 슬롯 게임 소식, 전략 및 팁에 이르기까지 깊이 있는
                정보를 전달합니다.
                PG슬롯와 함께라면 높은 품질의 온라인 카지노 경험을 제공해 드립니다.
            </p>
        </div>
        {
            !isEmpty(rlt) &&
            <div className="magazine_board">
                <h4>{rlt[0].title}</h4>
                <div style={{ padding: "30px 0" }}>
                    <img src={`${resource_url}/${rlt[0].image}`} style={{ width: "100%", maxWidth: "100%", marginBottom: "20px" }} alt='' />
                    <div className=' contents-view' dangerouslySetInnerHTML={{ __html: rlt[0].detail }}></div>
                </div>
                {rlt.length > 0 &&
                    <>
                        <h4>목차</h4>
                        <ul className="contents-view">
                            {rlt &&
                                rlt.map((list, index) => {
                                    if (index > 0) {
                                        return (
                                            <li key={index}>
                                                <a href={`#${list.id}`} style={{ color: "#fff" }}>{list.title}</a>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </>
                }
                {slotDictionary &&
                    slotDictionary.map((list, index) => {
                        return (
                            <div className="contents-view" key={index}>
                                <div id={list.id}>
                                    <h3>{list.title}:</h3>
                                    <div className="" dangerouslySetInnerHTML={{ "__html": !isEmpty(list.detail) ? changeEditorValue(list.detail) : "" }}></div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="pull-right mb20">
                </div>
                <div className="clearfix"></div>

                {/* <div className="border_button mt20 mb20">
                        <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                            <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                            <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                            <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                        </div>
                    </div> */}
            </div>
        }
    </>
    );
};
