import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { MyRecommendationContent, MyPageMenu } from '../../Components/Account';

export { MyRecommendation };


function MyRecommendation() {
    return (
        <Fragment>
            <Navbar type="myrecommendation" />
            <div className='main'>
                <MyPageMenu>
                    <MyRecommendationContent />
                </MyPageMenu>
            </div>
        </Fragment>
    );
}
