import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { changeEditorValue, dateFormation, isEmpty } from '../../../Utils';

export { DieEventDetail };


const DieEventDetail = ({
    onBtnClick,
    dieEventList,
    setActive,
    active,
    length,
}) => {

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }

    return (
        <div className="main_area">
            <div className="board event_board">
                <ul className="list-group">
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>진행기간</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>2024년 4월 1일 ~ 2024년 4월 30일</div>
                    </li>
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>진행사이트</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>올림푸스</div>
                    </li>
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>등록갯수</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>1</div>
                    </li>
                </ul>
                <div className="contents-view">
                    <h1>{dieEventList.title}</h1>
                    <div className="event_date">
                        <span className="txt">종료된 이벤트입니다.</span>
                    </div>
                    <div id="post-content">
                        <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(dieEventList.detail) ? changeEditorValue(dieEventList.detail) : "" }}></div>
                        <div className="hidden_keyword"></div>

                    </div>
                </div>
                <div className="pull-right mb20">
                </div>
                <div className="clearfix"></div>

                <div className="border_button mt20 mb20">
                    <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                        <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                    </div>
                </div>
                {/* <div className="event_order_list">
                    <h2>이벤트 신청 내역</h2>
                    <ul>
                        <li>
                            <div className="box">
                                <div className="box_top">
                                    <span className="buff_id">
                                        <div className="user_levelname">
                                            <span className="lv_icon_box">
                                                <div className="lv_icon lv_icon25">
                                                </div>
                                            </span>
                                            <span className="user_name">린가드</span>
                                        </div>
                                    </span>
                                    <span className="date">당첨날짜 - 03/29/2024</span>
                                </div>
                                <div className="box_center">
                                    <div className="imgs">
                                        <img loading="lazy" src="https://slotbuff2.com/uploads/post/2024/03/8e2cdcb763167c140cae23e1bd19ae56.webp" alt="이벤트를 신청합니다." />
                                    </div>
                                    <div className="txt">
                                        <dl>
                                            <dt>슈가러쉬 1000</dt>
                                            <dd>링크 : https://replay.pragmaticplay.net/l9*******</dd>
                                            <dd>사이트 : 레이브</dd>
                                            <dd>아이디 : j*****</dd>
                                        </dl>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};
