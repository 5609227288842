import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { dateFormation, isEmpty } from '../../../Utils';
import { Pagination } from '../../Common';
import { userActions } from '../../../store';

export { AnswerHistoryDetail };


const AnswerHistoryDetail = () => {

    const dispatch = useDispatch();
    const answerHistory = useSelector(state => state.user.answerHistory);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getAnswerList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(userActions.getAnswerHistory(params));
    }

    useEffect(() => {
        getAnswerList();
    }, []);

    return (
        <>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>날짜</th>
                    </tr>
                </thead>
                {
                    isEmpty(answerHistory) &&
                    <tbody>
                        <tr>
                            <td colSpan="4" className="nopost">회원님이 작성하신 글이 없습니다</td>
                        </tr>
                    </tbody>
                }
                <tbody >

                    {answerHistory &&
                        answerHistory.map((list, index) => {
                            return (
                                <tr key={index}>
                                    <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                    <td>{list.answerList}</td>
                                    <td>{dateFormation(list.created_date, 9)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <Pagination pageInfo={pageInfo} getList={getAnswerList} />
        </>
    );
};
