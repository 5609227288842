import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { ChangeNum_Letter, dateFormation, getLocalStringNumber, history, isEmpty, toastr } from '../../../Utils';
import { request } from '../../../Utils/api';
import { api_url } from '../../../Utils/keys';
import { ModalBack } from '../../Common/modal';
import { resource_url } from '../../../Utils/keys';

export { PointMallDetail };


const PointMallDetail = ({ onShowDetail, couponList }) => {

    const userData = useSelector(state => state.user.data)

    const [couponType, setCouponType] = useState(isEmpty(couponList.agents) ? "" : (isEmpty(couponList.agents[0].name) ? "" : couponList.agents[0].name))
    const [money, setMoney] = useState({
    });
    const [checkbox, setCheckbox] = useState({
    });
    const [showModal, setShowModal] = useState(false);

    const onShowModal = (e) => {
        e.preventDefault();
        setShowModal(!showModal)
    }

    useEffect(() => {
        if (!isEmpty(couponList) && !isEmpty(couponList.cost)) {
            const param = {}
            const params = {}
            couponList.cost.forEach((cost, index) => {
                param[`money${index}`] = 0;
                params[`checkbox${index}`] = false;
            });
            setMoney(param);
            setCheckbox(params);
        }
    }, [couponList])

    const onPlusBtn = (index) => {
        setMoney({ ...money, [`money${index}`]: money[`money${index}`] + 1 })
    }

    const onMinusBtn = (index) => {
        if (money[`money${index}`] > 0) {
            setMoney({ ...money, [`money${index}`]: money[`money${index}`] - 1 })
        }
    }

    const onBuyCoupon = () => {
        setShowModal(!showModal)
        if (totalCost > userData.gold) {
            return toastr.warning("보유골드가 부족합니다.")
        }
        let data = [];
        couponList.cost.forEach((cost, index) => {
            data.push({
                gold: cost.amount,
                count: money[`money${index}`]
            })
        });

        const params = {
            agent: couponType,
            data: data
        }
        request.post(api_url + "/api/Mypage/buycoupon", params)
            .then(data => {
                if (data.success) {
                    toastr.success(data.message)
                }
            })

    }
    let totalCost = 0;
    couponList.cost.forEach((item, index) => {
        totalCost = totalCost + (item.amount * money[`money${index}`] < 0 ? 0 : item.amount * money[`money${index}`])
    });

    const getNormalImg = e => {
        e.target.src = `../imgs/login/slotCT_coupon.png`;
    };

    return (
        <div className="main_area">
            <div className="market" id="item">
                <div className="product-box mb20">
                    <div className="product-left col-xs-12 col-lg-6">
                        <div className="prd-slide">
                            <div className="bx-wrapper" style={{ maxWidth: "100%", margin: "0px auto" }}>
                                <div className="bx-viewport"
                                    style={{ width: "100%", overflow: "hidden", position: "relative", height: "auto" }}>
                                    <img
                                    src={`${resource_url}/${couponList.img}`}
                                    alt="PG슬롯"
                                    title="PG슬롯"
                                    onError={getNormalImg}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                    />
                                </div>
                            </div>
                            <div className="bx-controls"></div>
                        </div>
                    </div>
                </div>
                <div className="product-right col-xs-12 col-lg-6">
                    <div className="board_form_box">
                        <div className="array_area" style={{ width: "100%" }}>
                            <select
                                name="couponType"
                                value={couponType}
                                onChange={(e) => { setCouponType(e.target.value) }}
                                style={{ width: "100%" }}
                            >
                                {couponList.agents &&
                                    couponList.agents.map((item, index) => {
                                        if(item.status === 1) {
                                            return ("")
                                        }
                                        return (
                                            <option
                                                key={index}
                                                value={item.name}

                                            >
                                                {item.name}
                                            </option>
                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>
                    <div className="product-no">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>쿠폰입금시간</td>
                                    <td style={{ fontWeight: "bold" }}>평일 {dateFormation(couponList.start_time, 5)} ~ {dateFormation(couponList.end_time, 5)} (주말제외)</td>
                                </tr>
                                <tr>
                                    <td colSpan="2"><span className="point_txt">PG슬롯 제휴사이트에서 이용내역이 있을시에만 지급가능합니다.</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div className="product-option">

                            <ul>
                                {couponList.cost &&
                                    couponList.cost.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="opt-name">
                                                    <span className="span-chk">
                                                        <input
                                                            type="checkbox"
                                                            name={"checkbox" + index}
                                                            value={checkbox[`checkbox${index}`]}
                                                            checked={checkbox[`checkbox${index}`] ? true : false}
                                                            onChange={() => setCheckbox({...checkbox, [`checkbox${index}`]: !checkbox[`checkbox${index}`]})}
                                                        />
                                                    </span>
                                                    {ChangeNum_Letter(item.amount)}
                                                </div>
                                                <div>
                                                    <span className="span-qty">
                                                        <div className="btn-group" role="group" aria-label="...">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-xs btn-change-qty"
                                                                data-change-type="minus"
                                                                disabled={checkbox[`checkbox${index}`] ? false : true}
                                                                onClick={() => onMinusBtn(index)}
                                                            >
                                                                <i className='fa fa-minus'></i>
                                                            </button>
                                                            <input
                                                                type="text"
                                                                name={"money" + index}
                                                                className="btn btn-primary btn-xs detail_qty"
                                                                readOnly
                                                                disabled={checkbox[`checkbox${index}`] ? false : true}
                                                                value={getLocalStringNumber(money[`money${index}`] < 0 ? 0 : money[`money${index}`])}
                                                            />
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-xs btn-change-qty"
                                                                data-change-type="plus"
                                                                disabled={checkbox[`checkbox${index}`] ? false : true}
                                                                onClick={() => onPlusBtn(index)}
                                                            >
                                                                <i className='fa fa-plus'></i>
                                                            </button>
                                                        </div>
                                                    </span>
                                                    <span className="detail_price">
                                                        <span>{getLocalStringNumber(item.amount * money[`money${index}`] < 0 ? 0 : item.amount * money[`money${index}`])}</span>원
                                                    </span>
                                                </div>
                                            </li>
                                        )
                                    })

                                }
                            </ul>

                            <div className="cart_total_price">총금액 :
                                <span id="total_order_price" name="totalMoney"  >

                                    {getLocalStringNumber(totalCost)}</span>원</div>
                        </div>
                        <div className="item-btn col-lg-offset-5">
                            <button className="btn btn-primary" onClick={onShowModal}>쿠폰구입</button>
                        </div>
                    </div>

                </div>
            </div>

            <div className="product-info mb20">
                <div className="product-detail">
                    <p style={{ lineHeight: "2.5" }}>
                        <span style={{ fontSize: "16px", color: "rgb(187 30 196)" }}><b>※</b></span>
                        <span style={{ fontSize: "12pt" }}>PG슬롯 회원가입후 이용하실 수 있는 상품입니다.</span>
                    </p>
                    <p><span style={{ fontSize: "13.3333px" }}>◈ 쿠폰은 PG슬롯&nbsp;</span>
                        <span style={{ fontSize: "13.3333px", color: "rgb(187 30 196)" }}><b>가입코드(PG SLOT)</b></span>
                        <span style={{ fontSize: "13.3333px" }}>를 통해 가입한 회원 한해 사용이
                            가능합니다.&nbsp;&nbsp;</span>&nbsp;
                    </p>
                    <p style={{ lineHeight: "2" }}>
                        <span>
                            <span style={{ fontSize: "13.3333px" }}>◈&nbsp;<b>
                                <span style={{ color: "rgb(187 30 196)" }}>쿠폰 입금시간은 평일 오전 {dateFormation(couponList.start_time, 12)}시부터 오후
                                    {dateFormation(couponList.end_time, 12)}시까지(주말제외)</span></b>&nbsp;입니다.</span></span>
                    </p>
                    <p style={{ lineHeight: "2" }}>
                        <span>
                            <span style={{ fontSize: "13.3333px" }}>◈ 주문주신 쿠폰내역에 따라 관리자 확인 후, 게임사이트 보유머니로 자동충전해 드리고
                                있습니다.</span></span>
                    </p>
                    <p style={{ marginLeft: "40px", lineHeight: "2" }}>
                        <span>
                            <span style={{ fontSize: "9pt" }}>- 기본적으로 PG슬롯 제휴사이트에서 이용내역이 있을 경우에만
                                지급이
                                가능하며,</span></span>
                    </p>
                    <p style={{ marginLeft: "40px", lineHeight: "2" }}>
                        <span style={{ fontSize: "9pt" }}>&nbsp; 보유하신 머니가
                            없거나, 환전처리가 완료되었을 때 지급처리가 진행됩니다.</span>
                    </p>
                    <p style={{ marginLeft: "40px", lineHeight: "2" }}><span>
                        <span style={{ fontSize: "9pt" }}>- 지급된 포인트는 현금과 동일하게 사용 가능하며, 300% 롤링
                            후
                            환전 가능합니다.</span></span></p>
                    <p style={{ marginLeft: "40px", lineHeight: "2" }}><span>
                        <span style={{ fontSize: "9pt" }}>- 다만, 제휴사이트에서 실시간으로 게임을 이용중이신 경우에는
                            지급처리가 불가한 점 유의하여 주세요.</span></span></p>
                    <p style={{ lineHeight: "2" }}>
                        <span>
                            <span style={{ fontSize: "13.3333px" }}>◈&nbsp;
                                <span style={{ color: "rgb(187 30 196)" }}><b>구매내역은 쿠폰함</b></span>
                                에서 확인하실 수 있습니다.
                            </span>
                        </span>
                    </p>
                </div>
            </div>
            <div className="border_button mt20 mb20">
                <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                    <button className="btn btn-primary btn-sm col-lg-offset-5" onClick={onShowDetail}>목록</button>
                </div>
            </div>
            {showModal &&
                <div className="dialog" style={{ display: showModal ? "flex" : "none", backgroundColor: !showModal && "#00000000", transition: "0.5s", scale: showModal ? "1" : "0" }} >
                    <ModalBack onClose={onShowModal} />
                    <div className="container popup_color" style={{ display: "block", zIndex: "1090" }}>
                        <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
                            <div className="swal2-icon-content">!</div>
                        </div>
                        <div className="justify-center  d-flex betting_modal_text">
                            <span style={{ width: "100%", textAlign: "center" }}>
                                쿠폰을 구입하시겠습니까?
                            </span>
                        </div>
                        <div className=" d-flex w-100 justify-content-center padding-15 betting_modal_text">
                            <button className="btn btn-success btn-sm" style={{ width: "59px" }} onClick={onBuyCoupon}>네</button>
                            <button className="btn btn-danger btn-sm" onClick={onShowModal}>아니오</button>
                        </div>
                    </div>
                </div>

            }
        </div>
    );
};
