import React, { useState } from 'react';
import { isEmpty } from '../../Utils';


export { HomeFAQ };

const questionList = [
    {
        title: `- 온라인 슬롯 사이트 보증 업체는 어디가 좋은가요?`,
        content: `온라인 슬롯 사이트 보증 업체는 어디가 좋은가요?"라는 질문은 많은 온라인 도박 플레이어들이 종종 하는 질문 중 하나입니다.
                이러한 질문에 대한 답은 다양한 요인에 따라 다르게 될 수 있으므로, 구체적인 업체를 추천하기는 어렵습니다.
                그러나 온라인 슬롯 사이트 보증 업체를 선택할 때 고려해야 할 몇 가지 주요 포인트를 제시해 드리겠습니다.
            
            이러한 기준을 토대로 여러 슬롯 보증 업체들을 비교해 보고, 자신의 상황과 필요에 가장 부합하는 업체를 선택하는 것이 좋습니다.`,
        contentOne: [
            {
                head: `1. 슬롯 사이트 신뢰도`,
                con: ` : 슬롯 보증 업체의 평판과 리뷰를 확인해보세요. 오랜 기간동안 안정적으로 운영되고 있고, 사용자로부터 좋은 평가를 받는 업체는 더욱 신뢰할 만 합니다.`
            },
            {
                head: `2. 라이센스`,
                con: ` : 슬롯사이트의 공급업체가 올바른 권한과 라이센스를 가지고 있는지 확인하세요. 이는 업체의 합법성과 신뢰성을 보장합니다.`,
            },
            {
                head: `3. 고객 서비스`,
                con: ` : 문제 발생 시 빠른 대응과 솔루션을 제시하는 고객 서비스는 사용자의 만족도를 높여줍니다.`,
            },
            {
                head: `4. 사용자 경험`,
                con: ` : 사이트의 사용자 경험, 게임의 품질, 프로모션 및 보너스 등도 고려 대상입니다.`,
            },
            {
                head: `5. 안전성`,
                con: ` : 개인정보 보호와 금융 거래의 안전성도 중요한 요소입니다. 해당 업체가 최신 암호화 기술을 사용하는지 확인하세요.`,
            },
        ]
    },
    {
        title: `- 슬롯 사이트 게임을 통해 이익을 얻을 수 있을까요?`,
        content: `슬롯 사이트 게임은 기본적으로 환수율(R.T.P)가 설정되어 있어, 게임사가 어느 정도의 이점을 가지고 있습니다.
                    이것은 쉽게 말해 카지노가 기본적으로 이기는 상황에 놓여 있다는 것입니다.
                    그렇지만 운이 좋다면 플레이어도 큰 수익을 거둘 수 있습니다.
                    그렇다고 계속 게임을 한다면, 결국 손실이 더 커질 가능성이 크죠. 만약 이익을 얻었다면 바로 그 돈을 인출하는 것이 좋습니다.
                    게임을 계속한다면, 승리 확률은 점차 감소합니다.
                    PG슬롯 회원 중에는 큰 이익을 얻었음에도 계속 게임을 해서 금방 모든 돈을 잃는 경우도 많았습니다.
                    큰 이익을 얻었다면 바로 인출하는 것이 승리의 실질적인 확정이라고 볼 수 있습니다.
                `
    },
    {
        title: `- 온라인 카지노 사이트 플레이하는 것은 안전한가요?`,
        content: `유명하고 신뢰받는 온라인 카지노 사이트들이 있지만, 그렇지 않은 곳도 상당히 있습니다.
                    투명하게 운영되는 카지노사이트를 선택하게 된다면, 편안하게 최상의 서비스를 받으며 게임을 즐길 수 있을 것이고, 규칙을 지킨다면 어떠한 이슈도 겪지 않을 것입니다.
                    반면, 부정적인 평가를 받는 카지노에서는 불이익이나 다양한 문제에 직면할 위험이 있습니다.
                    따라서 PG슬롯에서는 신뢰성 있는 카지노 사이트만을 소개하며, 올바른 카지노 사이트 선택은 크게 중요합니다.
                `
    },
    {
        title: `- 슬롯 커뮤니티 등록된 슬롯 사이트 업체들은 공평한가요?`,
        content: `모든 슬롯 사이트에서는 기본적으로 카지노 측이 이 어드벤티지(advantage)를 가지고 있습니다. 이로 인해 플레이어가 이길 확률보다는 손실할 가능성이 높아집니다.
                    다만, 카지노가 홍보하는 하우스 엣지(House Eddge)를 초과해 부당한 이익을 추구하거나 게임을 조작하는 것은 불가능합니다.
                    왜냐하면 대부분의 카지노게임은 독립된 전문 게임 제작 회사에서 제공되기 때문에, 카지노 자체는 게임 결과에 직접적인 영향을 미칠 수 없습니다.
                    몇몇 부정직한 카지노에서는 플레이어를 속이는 가짜 게임을 운영하기도 합니다.
                    이러한 게임은 정직한 카지노에서는 접할 수 없으며, PG슬롯은 이런 게임을 슬롯 커뮤니티 PG슬롯에서 철저히 검증하여 안전하게 플레이할 수 있도록 도와줍니다.

                `
    },
    {
        title: `- 신뢰할 수 있는 슬롯 사이트 업체는 어떻게 찾나요?`,
        content: `슬롯 사이트 선택 시 높은 수익률과 활발한 유저 활동을 갖는 곳을 고려하는 것이 중요합니다.
                    최고의 온라인 슬롯 사이트는 일반적으로 안전하게 운영되며, 큰 이익을 보장하므로 출금에도 문제가 없습니다.
                    PG슬롯은 제휴카지노와의 협약을 통해 플레이어 피해가 발생하면 100% 보상을 약속하고 있습니다.
                    슬롯사이트를 선택하기 전, 해당 사이트의 리뷰(슬롯 일반 리뷰)를 꼭 확인하고, 플레이어에게 최적인지 여부를 판단하세요.

                `
    },
    {
        title: `- 온라인 슬롯 사이트 기기들은 어떤걸 지원하나요?`,
        content: `대다수의 온라인 슬롯 사이트 업체들은 다양한 인터넷 연결 장치와 브라우저를 통해 접속이 용이합니다.
                    주요 컴퓨터 운영체제인 윈도우, 맥, 리눅스뿐만 아니라, 스마트폰과 태블릿의 안드로이드, 아이폰, 아이패드 등도 지원됩니다.
                    슬롯커뮤니티와 제휴된 카지노사이트들은 모든 모바일 환경에 맞게 최적화되어 있습니다.

                `
    },
    {
        title: `- 온라인 카지노 사이트 입금 방법은 무엇인가요?`,
        content: `카지노사이트에서는 다양한 입금 방법이 제공되며, 주로 가상계좌를 통한 방식이 선호됩니다.
                    가상계좌를 사용할 때 별도의 가입과 인증 절차가 필요하긴 하지만, 이는 카지노 운영진과 플레이어의 보안 및 안전을 위한 조치입니다.
                    약간의 불편함에도 불구하고, 이러한 안전한 방법을 사용하는 것이 좋습니다.
                `
    },
    {
        title: `- 큰 상금을 지불하는 온라인 슬롯 사이트 업체는 어디인가요?`,
        content: `큰 상금을 지불하는 온라인 슬롯 사이트 업체를 찾으려면 여러 요소를 고려해야 합니다.
                    우선, 해당 슬롯 사이트가 보유하고 있는 라이센스와 규제는 해당 사이트의 신뢰성을 나타내는 중요한 지표입니다.
                    정당한 라이센스를 보유한 사이트는 투명한 운영을 위해 엄격한 기준을 준수하며, 플레이어의 권리와 안전을 보장합니다.

                    또한, 다양한 사용자 리뷰와 평가는 실제 플레이어들의 경험을 통해 해당 사이트의 운영 상태와 지급 능력을 파악하는데 큰 도움이 됩니다.
                    실제로 큰 상금을 지불받은 사용자의 후기나 추천은 해당 사이트의 신뢰도를 높여줍니다.

                    하지만, 언제나 조심해야 할 점은 모든 정보와 리뷰를 무조건적으로 신뢰하지 않는 것입니다.
                    각각의 플레이어가 직접 연구하고, 다양한 정보를 종합적으로 분석하여 결정을 내리는 것이 가장 안전하고 현명한 방법입니다.

                `,
    },
    {
        title: `- 슬롯 커뮤니티 PG슬롯 이용방법은?`,
        content: `'PG슬롯'는 온라인 슬롯 게임을 즐기는 사용자들을 위한 슬롯 커뮤니티로 다양한 정보와 리뷰, 그리고 팁을 공유하는 공간입니다.`,
        contentOne: [
            {
                head: `1. 회원가입`,
                con: ` : "PG슬롯"는 다양한 포럼을 제공합니다. 이곳에서는 각종 슬롯 게임 리뷰, 전략, 그리고 사용자 경험 등이 공유됩니다.`
            },
            {
                head: `2. 포럼 활용`,
                con: ` : "PG슬롯"는 다양한 포럼을 제공합니다. 이곳에서는 각종 슬롯 게임 리뷰, 전략, 그리고 사용자 경험 등이 공유됩니다.`,
            },
            {
                head: `3. 정보 검색`,
                con: ` : 커뮤니티 내에는 검색 기능이 탑재되어 있어 원하는 정보나 특정 게임에 대한 토론을 쉽게 찾아볼 수 있습니다.`,
            },
            {
                head: `4. 무료슬롯 체험`,
                con: ` : "PG슬롯"에서는 실제 돈을 사용하지 않고 게임을 즐길 수 있는 무료슬롯을 제공합니다. 이를 통해 게임의 룰을 익히거나, 새로운 게임을 미리 체험해볼 수 있습니다.`,
            },
            {
                head: `5. 게임 추천 및 경험 공유`,
                con: ` : 사용자들은 자신이 좋아하는 게임을 추천하거나, 특정 게임에서의 승리 경험, 팁 등을 공유하여 다른 사용자와의 소통을 즐길 수 있습니다.`,
            },
            {
                head: `6. 안전한 슬롯 사이트 추천`,
                con: ` : "PG슬롯" 커뮤니티는 회원들로부터의 정보를 바탕으로 안전하게 이용할 수 있는 슬롯 사이트들을 추천해주는 섹션도 제공될 수 있습니다.`,
            },
            {
                head: `7. 커뮤니티 이벤트`,
                con: ` : 각종 이벤트나 프로모션 정보를 통해 사용자들은 더 많은 혜택을 누릴 수 있습니다.`,
            },
        ]
    },

]

function HomeFAQ() {

    const [active, setActive] = useState(-1)

    const onActive = (index) => {
        if (active === index) {
            setActive(-1)
        } else {
            setActive(index)
        }
    }

    return (
        <div className="accordion-box">
            <div className="tit">
                <h2>PG슬롯 FAQ</h2>
                <p>PG슬롯와 관련된 모든 궁금증을 해결해드립니다! 자주 묻는 질문들을 통해 슬롯 게임의 기초부터 전략, 플레이 팁까지 모든 정보를 손쉽게 찾아보세요.
                    초보자부터 베테랑 플레이어까지, PG슬롯의 세계를 더 깊게 이해하실 수 있습니다.</p>
            </div>
            <ul className="list">

                {questionList.map((list, index) => {
                    return (
                        <li key={index}>
                            <div className="title" onClick={() => onActive(index)}>
                                <h2>{list.title}</h2>
                                <span className="arrow">
                                    <i className={`fa-solid ${active === index ? "fa-angle-down" : "fa-angle-up"}`} aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="con" style={{ display: active === index ? "block" : "none" }}>
                                <p>{list.content}</p>

                                {!isEmpty(list.contentOne) &&
                                    <dl>
                                        {list.contentOne.map((item, index) => {
                                            return (
                                                <dt key={index}>
                                                    <h3>{item.head}</h3>
                                                    <p>{item.con}</p>
                                                </dt>
                                            )
                                        })}
                                    </dl>
                                }

                            </div>
                        </li>
                    )
                })
                }
            </ul>

        </div>
    );
}