import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';
import { eventActions, siteActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { NotificationDetail } from './components';

export { NotificationContent };

function NotificationContent() {

    const dispatch = useDispatch();
    const notificationList = useSelector(state => state.event.notificationList);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
    });

    const { searchType } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }


    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
        }
        dispatch(eventActions.getNotificationList(params));
    }
    useEffect(() => {
        getList();
    }, []);

    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "notice",
            typeId: notificationList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
            {/* <RightSideBar /> */}
                {showDetail && <NotificationDetail
                    length={notificationList.length}
                    active={active}
                    onBtnClick={onBtnClick}
                    setActive={setActive}
                    notificationList={notificationList[active]}
                />
                }
                {!showDetail &&
                    <div className="main_area">
                        {/* <div className="video_banner">
                        <img src="../imgs/benner_cs.png" alt="고객센터 궁금하신 사항이 있으시면 언제든지 문의글을 남겨주시면 성심성의껏 답변드리겠습니다." />
                    </div> */}
                        <div className="board">
                            {/* <div className="board_form_box">
                                <div className="array_area">
                                    <select name="searchType" value={searchType} onChange={onChange} >
                                        <option value="">전체</option>
                                        <option value="date">날짜순</option>
                                        <option value="viewCnt">조회수</option>
                                    </select>
                                </div>
                            </div> */}
                            <div style={{padding: "0px 5px"}}>
                                <table className="table table-hover normal_board table-bordered">
                                    <thead>
                                        <tr>
                                            <th> 번호 </th>
                                            <th>제목</th>
                                            <th>날짜</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notificationList &&
                                            notificationList.map((list, index) => {
                                                return (
                                                    <tr className="notice_list important" key={index} onClick={() => onShowDetail(index)}>
                                                        <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                        <td className="center">
                                                            <Link to="" title={list.title}>{list.title}</Link>
                                                        </td>
                                                        <td>{dateFormation(list.created_date, 9)}</td>
                                                    </tr>
                                                )
                                            })

                                        }

                                    </tbody>
                                    {
                                        isEmpty(notificationList) &&
                                        <tbody>
                                            <tr>
                                                <td colSpan="3" className="nopost">내역이 없습니다</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {/* <div className="border_button">
                            <div className="pull-left mr10">
                                <a href="../board/custom?" className="btn
                                btn-default btn-sm">목록</a>
                            </div>
                        </div> */}
                            {!isEmpty(notificationList) &&
                                <Pagination pageInfo={pageInfo} getList={getList} />
                            }
                        </div>
                    </div>
                }

                <SideBar />
            </div>
        </div>
    );

}