import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { MyLogsContent, MyPageMenu } from '../../Components/Account';

export { MyLogs };


function MyLogs() {
    return (
        <Fragment>
            <Navbar type="mylogs" />
            <div className='main'>
                <MyPageMenu>
                    <MyLogsContent />
                </MyPageMenu>
            </div>
        </Fragment>
    );
}
