import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../Utils/api';

import { api_url } from '../Utils/keys';
import { isEmpty, showMessage, toastr } from '../Utils';
import { pageActions } from './page.slice';
import { store, userActions } from '.';

// create slice

const name = 'mini';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const miniActions = { ...slice.actions, ...extraActions };
export const miniReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        type: 1,
        bettingList: [],      // minigame betting list data
        data: {
            odd_key: '',
            rate: 0,
            name: '-'
        },
        rates: {
            normal: {
                evenOdd: 1,
                overUnder: 1,
                big: 1,
                medium: 1,
                small: 1,
                odd: 1,
                even: 1,
                nugurileft: 1,
                nuguriright: 1,
                line3: 1,
                line4: 1,
                left: 1,
                right: 1,
            },
            power: {
                evenOdd: 1,
                overUnder: 1
            },
            strike: {
                left: 1,
                right: 1,
                middle: 1
            },
            homerun: {
                left: 1,
                right: 1,
                middle: 1
            },
            kicker: {
                kicker_left: 1,
                kicker_right: 1
            },
            keeper: {
                keeper_left: 1,
                keeper_right: 1
            },
            goal: {
                goal_left: 1,
                goal_right: 1
            }
        },
        state: {},
        leftTime: "",
        miniCheck: true,
    }
}

function createReducers() {
    return {
        setMiniGameType,
        setPowerBallBettingList,
        setBetData,
        setBetType,
        setPowerBallRates,
        setMiniCheck,
    };

    function setMiniGameType(state, action) {
        state.type = action.payload;
    }
    function setPowerBallBettingList(state, action) {
        state.bettingList = action.payload;
    }
    function setBetData(state, action) {
        state.data = action.payload;
    }
    function setBetType(state, action) {
        state.type = action.payload;
    }
    function setPowerBallRates(state, action) {
        state.rates = { ...state.rates, ...action.payload.rates };
        state.state = action.payload.state;
        state.leftTime = action.payload.leftTime;
    }
    function setMiniCheck(state, action) {
        state.miniCheck = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        clearMiniBetStore: clearMiniBetStore(),
        getPowerBallBettingList: getPowerBallBettingList(),
        getBettingList: getBettingList(),
        doMiniBetting: doMiniBetting(),

    };

    function clearMiniBetStore() {
        return createAsyncThunk(
            `${name}/clearMiniBetStore`,
            async function (params, { dispatch }) {
                dispatch(miniActions.setBetData({
                    odd_key: '',
                    rate: 0,
                    name: '-'
                }));
            }
        );
    }

    function getPowerBallBettingList() {
        return createAsyncThunk(
            `${name}/getPowerBallBettingList`,
            async function (params, { dispatch }) {
                dispatch(miniActions.setPowerBallBettingList(params));
            }
        );
    }
    function getBettingList() {
        return createAsyncThunk(
            `${name}/getBettingList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Minigame/getBettingHistory", params)
                        .then(data => {
                            dispatch(miniActions.setPowerBallBettingList(data.data.bets));
                            dispatch(pageActions.setPageData(data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function doMiniBetting() {
        return createAsyncThunk(
            `${name}/doMiniBetting`,
            async function (params, { dispatch }) {
                const { data, type } = store.getState().mini;
                const page = store.getState().page;
                if (isEmpty(data.odd_key) || isEmpty(data.rate) || data.name === '-') {
                    return toastr.warning('베팅을 선택해주세요.')
                }
                dispatch(miniActions.clearMiniBetStore());
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Minigame/betting", {
                        type: (type).toString(),
                        amount: params,
                        odd_key: data.odd_key,
                        name: data.name
                    })
                        .then(data => {
                            if(data.success) {
                               return toastr.success(data.message)
                            }
                            showMessage(data.message);
                            dispatch(miniActions.clearMiniBetStore());
                            dispatch(miniActions.getBettingList({ type: type.toString(), page: 1, perPage: page.perPage, gameResult: "" }));
                            dispatch(userActions.getUserInfo());
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

}