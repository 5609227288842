import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { AttendanceContent } from '../../Components/Account';



export {Attendance};

function Attendance() {
    return (
        <Fragment>
            <Navbar type="mypage"/>
            <div className='main'>
                <AttendanceContent />
            </div>
        </Fragment>
    );
}