import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Navbar } from '../Layout/Navbar';
import { SideBar } from '../Layout/SideBar';
import { checkLoginIdstr, history, isEmpty, toastr } from '../../Utils';
import { userActions } from '../../store';
import { api_url } from '../../Utils/keys';
import { request } from '../../Utils/api';
import { RightSideBar } from '../Layout';


export { Register };

function Register() {

    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        loginId: "",
        username: "",
        email: "",
        userPassword: "",
        userPasswordConfirm: "",

    });

    const {
        loginId,
        username,
        email,
        userPassword,
        userPasswordConfirm,
    } = inputs;

    const onChange = (e) => {
        const { name, value } = e.target
        setInputs({
            ...inputs,
            [name]: value
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (isEmpty(loginId)) {
            return toastr.warning("아이디를 입력해주세요.")
        }
        if (checkLoginIdstr(loginId)) {
            return toastr.warning("아이디에 특수문자를 포함할수 없습니다.")
        }
        if (isEmpty(email)) {
            return toastr.warning("메일을 입력해주세요.")
        }
        if (isEmpty(userPassword)) {
            return toastr.warning("비밀번호을 입력해주세요.")
        }
        if ((userPassword !== userPasswordConfirm)) {
            return toastr.warning("비밀번호를 확인해주세요.")
        }
        if (isEmpty(username)) {
            return toastr.warning("이름을 입력해주세요.")
        }
        if (checkLoginIdstr(username)) {
            return toastr.warning("닉네임에 특수문자를 포함할수 없습니다.")
        }

        const params = {
            loginId: loginId,
            name: username,
            email: email,
            loginPwd: userPassword,
        }
        dispatch(userActions.signUp(params))
    }

    const onCheckId = () => {
        if (isEmpty(loginId)) {
            return toastr.warning("아이디를 입력해주세요.")
        }
        if (checkLoginIdstr(loginId)) {
            return toastr.warning("아이디에 특수문자를 포함할수 없습니다.")
        }
        const params = { loginid: loginId }
        request.post(api_url + "/api/User/checkId", params)
            .then(res => {
                toastr.success(res.message);

            })
    }

    const onReset = () => {
        history.navigate("/")
    }

    return (
        <div className="main">
            <Navbar type="" />
            <div className="container">
                <div className="row" style={{ margin: "0" }}>
                    {/* <RightSideBar /> */}
                    <div className="main_area">
                        <div className="register col-md-10 col-md-offset-1">
                            <div className="panel panel-custom">
                                <div className="panel-heading">회원 가입</div>
                                <div className="panel-body">
                                    <div className="form-horizontal">

                                        <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="loginId">아이디</label>
                                            <div className="col-lg-8" style={{ position: "relative" }}>
                                                <input
                                                    type="text"
                                                    id="loginId"
                                                    name="loginId"
                                                    className="form-control input"
                                                    value={loginId}
                                                    required=""
                                                    aria-required="true"
                                                    style={{ padding: "0px 77px 0px 10px" }}
                                                    onChange={onChange}
                                                />
                                                <span className="repeat_id cursor-pointer" onClick={onCheckId}>중복확인</span>
                                                <p className="help-block">영문자, 숫자, _ 만 입력 가능. 최소 3자이상 입력하세요</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="email">메일</label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    className="form-control input"
                                                    value={email}
                                                    required=""
                                                    aria-required="true"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="userPassword">비밀번호</label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="password"
                                                    id="userPassword"
                                                    name="userPassword"
                                                    className="form-control input"
                                                    value={userPassword}
                                                    minLength="4"
                                                    onChange={onChange}
                                                />
                                                <p className="help-block">비밀번호는 4자리 이상이어야 합니다</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="userPasswordConfirm">비밀번호 확인</label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="password"
                                                    id="userPasswordConfirm"
                                                    name="userPasswordConfirm"
                                                    className="form-control input"
                                                    value={userPasswordConfirm}
                                                    minLength="4"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="username">닉네임</label>
                                            <div className="col-lg-8">
                                                <input
                                                    type="text"
                                                    id="username"
                                                    name="username"
                                                    className="form-control input"
                                                    value={username}
                                                    required=""
                                                    aria-required="true"
                                                    onChange={onChange}
                                                />
                                                <p className="help-block">공백없이 한글, 영문, 숫자만 입력 가능 2글자 이상<br />닉네임을 입력하시면 앞으로 60일 이내에는 변경할 수 없습니다</p>
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                            <label className="col-lg-3 control-label" htmlFor="mem_open_profile">정보공개</label>
                                            <div className="col-lg-8">
                                                <div className="checkbox">
                                                    <label htmlFor="mem_open_profile">
                                                        <input type="checkbox" name="mem_open_profile" id="mem_open_profile" value="1" checked="checked" />
                                                        다른분들이 나의 정보를 볼 수 있도록 합니다.
                                                    </label>
                                                    <p className="help-block">정보공개 설정은 60일 이내에는 변경할 수 없습니다</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label">쪽지기능사용</label>
                                            <div className="col-lg-8">
                                                <div className="checkbox">
                                                    <label htmlFor="mem_use_note">
                                                        <input type="checkbox" name="mem_use_note" id="mem_use_note" value="1" checked="checked" />
                                                        쪽지를 주고 받을 수 있습니다.
                                                    </label>
                                                    <p className="help-block">쪽지 기능 사용 설정은 60일 이내에는 변경할 수 없습니다</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label">메일 수신여부</label>
                                            <div className="col-lg-8">
                                                <div className="checkbox">
                                                    <label htmlFor="mem_receive_email">
                                                        <input type="checkbox" name="mem_receive_email" id="mem_receive_email" value="1" checked="checked" /> 수신
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label">SMS 문자 수신</label>
                                            <div className="col-lg-8	">
                                                <div className="checkbox">
                                                    <label htmlFor="mem_receive_sms">
                                                        <input type="checkbox" name="mem_receive_sms" id="mem_receive_sms" value="1" checked="checked" /> 수신
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-lg-3 control-label"><img src="https://slotbuff1.com/uploads/captcha/1712799813.6494.jpg" width="160" height="40" id="captcha" alt="captcha" title="captcha" /></label>
                                            <div className="col-lg-8	">
                                                <input type="text" name="captcha_key" id="captcha_key" className="form-control px150" value="" />
                                                <p className="help-block">좌측에 보이는 숫자를 입력해주세요</p>
                                            </div>
                                        </div> */}
                                        <div className="form-group">
                                            <div className="col-lg-9 col-lg-offset-3">
                                                <button type="submit" className="btn btn-success btn-sm" onClick={onSubmit}>회원가입</button>
                                                <button href="https://slotbuff1.com/" className="btn btn-danger btn-sm" onClick={onReset}>취소</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SideBar />
                </div>
            </div>
        </div>
    );
}