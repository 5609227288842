import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from '../../store';
import { Pagination } from '../Common';
import { dateFormation, isEmpty } from '../../Utils';
import { resource_url } from '../../Utils/keys';
import { AnswerHistoryDetail } from './components';

export { MyPostContent };

function MyPostContent() {

    const [showArticle, setShowArticle] = useState("realArticle")

    const onShowArticle = (type) => {
        setShowArticle(type)
    }

    const dispatch = useDispatch();
    const articleHistory = useSelector(state => state.user.articleHistory);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getArticleList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(userActions.getArticleHistory(params));
    }
    

    useEffect(() => {
        getArticleList();
    }, []);



    return (
        <>
            <div className="btn-group btn-group-justified mb20" role="group" aria-label="...">
                <div className="btn-group" role="group">
                    <button className="btn btn-warning btn-sm" title="원글" onClick={() => onShowArticle("realArticle")}>원글</button>
                </div>
                <div className="btn-group" role="group">
                    <button className="btn btn-success btn-sm" title="댓글" onClick={() => onShowArticle("article")}>댓글</button>
                </div>
            </div>

            {showArticle === "realArticle" &&
                <>
                    <table className="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>이미지</th>
                                <th>제목</th>
                                <th>날짜</th>
                            </tr>
                        </thead>
                        {
                            isEmpty(articleHistory) &&
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="nopost">회원님이 작성하신 글이 없습니다</td>
                                </tr>
                            </tbody>
                        }
                        <tbody >

                            {articleHistory &&
                                articleHistory.map((list, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                            <td> <img src={list.titleImg} style={{width: "50px", height: "auto"}} /></td>
                                            <td>{list.title}</td>
                                            <td>{dateFormation(list.created_date, 9)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <Pagination pageInfo={pageInfo} getList={getArticleList} />
                </>
            }

            {showArticle === "article" &&
                <AnswerHistoryDetail />
            }
        </>
    );
}
