import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { CasinoInfoContent } from '../../Components/CasinoInfo';



export { CasinoInfo };

function CasinoInfo() {
    return (
        <Fragment>
            <Navbar type="casinoInfo" />
            <div className='main'>
                <CasinoInfoContent />
            </div>

        </Fragment>
    );

}