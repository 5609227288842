import React, { useEffect } from 'react';
import Betting from './Betting';
import { ResizeGopickFrame, ResizePowerballFrame, getId } from '../../../Utils';

export { MiniGameVideo };


function MiniGameVideo({type, url}) {

    useEffect(()=>{
        ResizeGopickFrame();
        ResizePowerballFrame();
      }, [type])
    return (
        <div className="betting_area">
            <iframe
            src={url} 
            style={{ width: "100%", height: "631px", overflow: "hidden" }}
            scrolling="no"
            className='minigame-iframe'
            >
            </iframe>
            <Betting type={type} />
        </div>
    );
}
