import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { SideBar } from '../Layout/SideBar';
import { eventActions } from '../../store';
import { RightSideBar } from '../Layout';
import { PointMallDetail } from './components/PointMallDetail';
import { toastr } from '../../Utils';
import { resource_url } from '../../Utils/keys';


export { PointMallContent };

function PointMallContent() {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
    const pointSettingList = useSelector(state => state.event.pointSettingList);
    const couponList = useSelector(state => state.event.couponList);
    const [showDetail, setShowDetail] = useState(false);


    const onShowDetail = () => {
        if(!isAuthenticated) {
            return toastr.warning("로그인하고 이용해주세요.")
        }
        setShowDetail(!showDetail)
    }
    const getList = () => {
        dispatch(eventActions.getPointSettingList());
        dispatch(eventActions.getCouponList());
    }
    useEffect(() => {
        getList();
    }, []);

    const getNormalImg = e => {
        e.target.src = `../imgs/login/slotCT_coupon.png`;
    };

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {/* <RightSideBar /> */}
                {showDetail &&
                    <PointMallDetail onShowDetail={onShowDetail}  couponList={couponList}/>
                }
                {!showDetail &&
                    <div className="main_area">
                        <div className="video_banner">
                            <img src="../imgs/title_coupon.png" alt="POINT MALL" style={{width: "100%", border: "1px solid #1c1e2f"}} />
                        </div>
                        <div className="cmall_info">
                            <dl>
                                <dt>PG슬롯 포인트 적립안내</dt>
                                <dd>포인트는 <strong>1:1 (포인트=상품권) 구매</strong>가 가능합니다.</dd>
                            </dl>
                            <ul>
                                <li>
                                    <div className="box">
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="회원가입 5000포인트" />
                                            <div className="imgs-content imgs-content-register">
                                                <span className="imgs-title"> 회원가입</span>
                                                <span className="imgs-line imgs-line-register"></span>
                                                <span className="imgs-point"> {pointSettingList.signUpPoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="1일 첫 로그인 30포인트" />
                                            <div className="imgs-content imgs-content-login" style={{ left: "32px" }}>
                                                <span className="imgs-title">1일 첫 로그인</span>
                                                <span className="imgs-line imgs-line-login" style={{ left: "-14px" }}></span>
                                                <span className="imgs-point">{pointSettingList.loginPoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="출석체크 50포인트" />
                                            <div className="imgs-content imgs-content-attendance">
                                                <span className="imgs-title">출석체크</span>
                                                <span className="imgs-line imgs-line-attendance"></span>
                                                <span className="imgs-point">{pointSettingList.attPoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="댓글작성 30포인트" />
                                            <div className="imgs-content imgs-content-article">
                                                <span className="imgs-title">댓글작성</span>
                                                <span className="imgs-line imgs-line-article"></span>
                                                <span className="imgs-point">{pointSettingList.answerPoint}P</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="box">
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="자유게시판글작성 50포인트" />
                                            <div className="imgs-content imgs-content-free-article" style={{ left: "27px" }}>
                                                <span className="imgs-title" style={{ fontSize: "16px" }}>자유게시판글작성</span>
                                                <span className="imgs-line imgs-line-free-article" style={{ left: "-7px" }}></span>
                                                <span className="imgs-point" style={{ paddingTop: "20px" }}>{pointSettingList.freeArticlePoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="제휴사이트글작성 50포인트" />
                                            <div className="imgs-content imgs-content-free-article" style={{ left: "27px" }}>
                                                <span className="imgs-title" style={{ fontSize: "16px" }}>제휴사이트글작성</span>
                                                <span className="imgs-line imgs-line-free-article" style={{ left: "-7px" }}></span>
                                                <span className="imgs-point" style={{ paddingTop: "20px" }}>{pointSettingList.cooPoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="슬롯리뷰(일반)글작성 300포인트" />
                                            <div className="imgs-content imgs-content-review" style={{ left: "27px" }}>
                                                <span className="imgs-title imgs-title-review" style={{ fontSize: "14px" }}>슬롯리뷰(일반)글작성</span>
                                                <span className="imgs-line imgs-line-review" style={{ left: "-7px" }}></span>
                                                <span className="imgs-point" style={{ paddingTop: "20px" }}>{pointSettingList.slotReviewPoint}P</span>
                                            </div>
                                        </div>
                                        <div className="imgs">
                                            <img src="../imgs/point_1.png" alt="슬롯리뷰(영상)제보 3,000~10,000포인트" />
                                            <div className="imgs-content imgs-content-video" style={{ left: "27px" }}>
                                                <span className="imgs-title imgs-title-video" style={{ fontSize: "16px" }}>슬롯리뷰(영상)제보</span>
                                                <span className="imgs-line imgs-line-video" style={{ left: "-7px" }}></span>
                                                <span className="imgs-point" style={{ paddingTop: "20px" }}>{pointSettingList.slotVideoPoint}P</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="notice">기본적으로 위와 같은 포인트 적립이 제공됩니다.<br />자세한 사항은 PG슬롯 고객센터에 게시된 '
                                <Link to="/notice" style={{ textDecoration: "underline", color: "var(--txtColor01)" }} title="포인트 이용안내
                        바로가기">포인트 이용안내
                                </Link>'를 참고해 주세요.<br /><strong>악용사례로 판단될 경우 포인트 지급 및 사용에 대한 제재가 있을 수 있습니다.</strong>
                            </div>
                        </div>
                        <h4 style={{ color: "var(--txtColor01)" }}>제휴쿠폰 구매</h4>
                        <div className="cmall-list">
                            <ul className="row">
                                <li className="col-xs-6 col-sm-6 col-md-4 col-lg-4 cmall-list-col">
                                    <div className="thumbnail">
                                        <div className="cursor-pointer img_a" title={couponList.title} onClick={onShowDetail}>
                                            <img
                                            style={{maxWidth: "100%", height: "auto"}}
                                            alt={couponList.title}
                                            onError={getNormalImg}
                                            src={`${resource_url}/${couponList.img}`}
                                            />
                                        </div>
                                        <p className="cmall-tit">
                                            <a href="" title={couponList.title} >{couponList.title}</a>
                                        </p>
                                        <p className="cmall-txt">{couponList.comment}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
                <SideBar />
            </div>
        </div>
    );
}