import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, toastr } from '../../Utils';
import { request } from '../../Utils/api';
import { api_url } from '../../Utils/keys';
import { userActions } from '../../store';

export { MyInfoContent };

function MyInfoContent() {

    const userData = useSelector(state => state.user.data);
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
    });
    const { oldPassword, newPassword, newPasswordConfirm } = inputs;

    const onChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const onSubmit = () => {
        if (isEmpty(oldPassword)) {
            return toastr.warning("현재 비밀번호를 입력해주세요.")
        }
        if (isEmpty(newPassword)) {
            return toastr.warning("새 비밀번호을 입력해주세요.")
        }
        if (newPassword !== newPasswordConfirm) {
            return toastr.warning("새 비밀번호를 확인해주세요.")
        }

        const params = {
            oldPwd: oldPassword,
            loginPwd: newPassword,
            loginPwd_confirm: newPasswordConfirm,
        }

        request.post(api_url + "/api/User/changePassword", params)
            .then((res) => {
                toastr.success(res.message);
                dispatch(userActions.getUserInfo());
            });
    }

    return (
        <div className="form-box mt20">
            <div className="form-horizontal" name="fconfirmpassword" id="fconfirmpassword" >

                <div className="form-group">
                    <label htmlFor="mem_userid" className="col-sm-3 control-label">아이디</label>
                    <div className="col-sm-9 username">
                        <p className="form-control-static"><strong>{userData.loginId}</strong></p>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="oldPassword" className="col-sm-3 control-label">현재비밀번호</label>
                    <div className="col-sm-9">
                        <input
                            type="password"
                            className="form-control px150"
                            id="oldPassword"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="mem_password" className="col-sm-3 control-label">새 비밀번호</label>
                    <div className="col-sm-9 new-password">
                        <input
                            type="password"
                            className="form-control px150"
                            id="newPassword"
                            name="newPassword"
                            value={newPassword}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="mem_password" className="col-sm-3 control-label">새 비밀번호확인</label>
                    <div className="col-sm-9 password-confirm">
                        <input
                            type="password"
                            className="form-control px150"
                            id="newPasswordConfirm"
                            name="newPasswordConfirm"
                            value={newPasswordConfirm}
                            onChange={onChange}
                        />
                        <button type="submit" className="btn btn-primary btn-sm ml10" onClick={onSubmit}>확인</button>
                        <span className="help-block">
                            <span className="fa fa-exclamation-circle" aria-hidden="true"></span>
                            외부로부터 회원님의 정보를 안전하게 보호하기 위해 비밀번호를 확인하셔야 합니다.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
