import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { BaseballContent } from '../../Components/MiniGame';
import { RightSideBar, SideBar } from '../../Components/Layout';

export { Baseball };


function Baseball() {
    return (
        <Fragment>
            <Navbar type="minigame" />
            <div className='main'>
                <div className="container">
                    <div className="row" style={{ margin: "0" }}>
                        {/* <RightSideBar /> */}
                        <BaseballContent />
                        <SideBar />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
