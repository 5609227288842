import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { CasinoNewsContent } from '../../Components/CasinoInfo';


export { CasinoNews };

function CasinoNews() {
    return (
        <Fragment>
            <Navbar type="casinoNews" />
            <div className='main'>
                <CasinoNewsContent />
            </div>
        </Fragment>
    );

}