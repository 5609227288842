import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userActions } from '../../store';
import { isEmpty, isMobilePage, toastr } from '../../Utils';

export {Login} ;

function Login() {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    const [inputs, setInputs] = useState({
        loginId: "",
        password: "",
        deviceType: isMobilePage() ? "MOBILE" : "PC",
    })
    const { loginId, loginPwd } = inputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setInputs({
            ...inputs,
            [name]: value 
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (isEmpty(loginId)) {
            return toastr.warning("아이디를 입력해주세요.")
        }
        if (isEmpty(loginPwd)) {
            return toastr.warning("비밀번호을 입력해주세요.")
        }
        const params = {
            loginId: loginId,
            loginPwd: loginPwd
        }
        dispatch(userActions.loginUser(params))

    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
          onSubmit(e);
        }
    }

    const ifAuthenticated = (e) => {
        if (!isAuthenticated) {
            e.preventDefault();
            return toastr.warning("로그인하고 이용해주세요.")
        }
    }

    return (
        <div className="form-horizontal" name="fsidelogin" id="fsidelogin" >
            <div className="loginbox"  onKeyDown={onEnter}>
                <input
                type="text"
                className="login_form"
                name="loginId"
                value={loginId}
                placeholder="아이디"
                autoComplete="true"
                onChange={onChange}
                />
                <input
                type="password"
                className="login_form"
                name="loginPwd"
                value={loginPwd}
                placeholder="비번"
                autoComplete="true"
                onChange={onChange}
                />
                <ul>
                    <li><button className="login_btn" title="로그인" onClick={onSubmit}>로그인</button></li>
                    <li><Link to="/register" title="회원가입">회원가입</Link></li>
                </ul>

            </div>
        </div>
    );


}