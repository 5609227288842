import React from 'react';
import { ModalBack } from './ModalBack';

export { LogoutModal };


const LogoutModal = ({ showLogout, logout, onLogout }) => {
    return (
        <div className="dialog" style={{ display: showLogout ? "flex" : "none", backgroundColor: !showLogout && "#00000000", transition: "0.5s", scale: showLogout ? "1" : "0" }} >
            <ModalBack onClose={onLogout} />
            <div className="container popup_color" style={{ display: "block", zIndex: "1090" }}>
                <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
                    <div className="swal2-icon-content">?</div>
                </div>
                <div className="justify-center  d-flex betting_modal_text">
                    <span style={{ width: "100%", textAlign: "center" }}>
                        로그아웃하시겠습니까?
                    </span>
                </div>
                <div className=" d-flex w-100 justify-content-center padding-15 betting_modal_text">
                    <button className="btn btn-success btn-sm" style={{width: "59px"}} onClick={logout}>네</button>
                    <button className="btn btn-danger btn-sm" onClick={onLogout}>아니오</button>
                </div>
            </div>
        </div>
    );
};
