import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { request } from '../../Utils/api';
import { api_url } from '../../Utils/keys';
import { isEmpty, toastr } from '../../Utils';
import { userActions } from '../../store';

export { LeaveContent };


function LeaveContent() {

    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.data);
    const [inputs, setInputs] = useState({
        userPassword: "",

    });

    const {
        userPassword,
    } = inputs;

    const onChange = (e) => {
        const { name, value } = e.target
        setInputs({
            ...inputs,
            [name]: value
        })
    }

    const onLeave = () => {
        if (isEmpty(userPassword)) {
            return toastr.warning("비번을 입력해주세요.")
        }
        const params = { loginPwd: userPassword }
        request.post(api_url + "/api/User/Signout", params)
            .then(res => {
                toastr.success(res.message);
                dispatch(userActions.logout())
            })
    }

    return (
        <div className="form-box mt20">
            <div className="form-horizontal" name="fconfirmpassword" id="fconfirmpassword" >

                <div className="infoalert">
                    회원 탈퇴를 위한 패스워드 입력페이지입니다.<br />
                    패스워드를 입력하시면 회원탈퇴가 정상적으로 진행됩니다.<br />
                    탈퇴한 회원정보는 복구할 수 없으므로, 신중히 선택해주시기 바랍니다.
                </div>
                <div className="form-group">
                    <label htmlFor="mem_userid" className="col-sm-3 control-label">아이디</label>
                    <div className="col-sm-9 leave-username">
                        <p className="form-control-static"><strong>{userData.loginId}</strong></p>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="userPassword" className="col-sm-3 control-label">비밀번호</label>
                    <div className="col-sm-9">
                        <input
                            type="password"
                            className="form-control px150"
                            name="userPassword"
                            value={userPassword}
                            onChange={onChange}
                        />
                        <button className="btn btn-primary btn-sm ml10" onClick={onLeave}>확인</button>
                        <span className="help-block">
                            <span className="fa fa-exclamation-circle" aria-hidden="true"></span>
                            외부로부터 회원님의 정보를 안전하게 보호하기 위해 비밀번호를 확인하셔야 합니다.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
