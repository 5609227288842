import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { SlotPhraseContent } from '../../Components/CasinoInfo';



export { SlotPhrase };

function SlotPhrase() {
    return (
        <Fragment>
            <Navbar type="SlotPhrase" />
            <div className='main'>
                <SlotPhraseContent />
            </div>

        </Fragment>
    );

}