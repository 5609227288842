import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from '../Common';
import { eventActions } from '../../store';
import { SlotPhraseDetail } from './components';
import { api_url, resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { request } from '../../Utils/api';
import { RightSideBar, SideBar } from '../Layout';

export { SlotPhraseContent };

function SlotPhraseContent() {


    const dispatch = useDispatch();
    const slotDictionary = useSelector(state => state.event.slotDictionary);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        const params = {
            type: "slotvocabularies",
            typeId: slotDictionary[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(eventActions.getSlotDictionary(params));
    }
    useEffect(() => {
        getList();
    }, []);


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {/* <RightSideBar /> */}
                <div className='main_area' >
                    <SlotPhraseDetail
                        onBtnClick={onBtnClick}
                        slotDictionary={slotDictionary}
                        setActive={setActive}
                        active={active}
                        length={slotDictionary.length}
                    />
                    {/* {!showDetail &&
                        <>
                            <div className="page_seo_tit_box">
                                <h2>슬롯용어집안내</h2>
                                <p>PG슬롯은 온라인 카지노의 전반적인 정보를 제공하는 최고의 안내 플랫폼입니다.
                                    사용자에게 먹튀 없는 안전한 카지노 사이트 추천부터 신규 슬롯 게임 소식, 전략 및 팁에 이르기까지 깊이 있는
                                    정보를 전달합니다.
                                    PG슬롯와 함께라면 높은 품질의 온라인 카지노 경험을 제공해 드립니다.
                                </p>
                            </div>
                            <div className="board">
                                <div className="board_form_box">
                                    <div className="array_area">
                                        <select name="searchType" value={searchType} onChange={onChange} >
                                            <option value="">전체</option>
                                            <option value="date">날짜순</option>
                                            <option value="viewCnt">조회수</option>
                                        </select>
                                    </div>
                                    <div className="search_area">
                                        <div className="searchbox">
                                            <div>
                                                <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange} onKeyDown={onEnter} /></span>
                                                <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_list full_gallery_list magazine_list">
                                        <ul>
                                            {slotDictionary &&
                                                slotDictionary.map((list, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="box" id={list._id}>
                                                                <div className="imgs cursor-pointer" onClick={() => onShowDetail(index)}>
                                                                    <img src={`${resource_url}/${list.image}`} alt={list.title} />
                                                                </div>
                                                                <div className="txt">
                                                                    <p>{list.content}</p>
                                                                    <div className="data">
                                                                        <dl>
                                                                            <dd>
                                                                                <span className="icon">
                                                                                    <i className="fa fa-clock-o mr10" aria-hidden="true"></i>
                                                                                </span>
                                                                                <span className="con">{dateFormation(list.created_date, 1)}</span>
                                                                            </dd>
                                                                            <dd>
                                                                                <span className="icon">
                                                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                                                </span>
                                                                                <span className="con">{list.viewCnt}</span>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                            {isEmpty(slotDictionary) &&
                                                <li>
                                                    <div className="box">
                                                        <div className="txt">
                                                            <p style={{ paddingTop: "50px" }}>내역이 없습니다.</p>
                                                        </div>
                                                    </div>
                                                </li>

                                            }

                                        </ul>
                                    </div>
                                </div>
                                {!isEmpty(slotDictionary) &&
                                    <Pagination pageInfo={pageInfo} getList={getList} />
                                }
                            </div>
                        </>
                    } */}
                </div>
                <SideBar />
            </div>
        </div>
    );
}