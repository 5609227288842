import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { changeEditorValue, dateFormation, isEmpty } from '../../../Utils';

export { LiveEventDetail };


const LiveEventDetail = ({
    onBtnClick,
    liveEventList,
    setActive,
    active,
    length,
}) => {

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }

    return (
        <div className="main_area">
            <div className="board event_board">
                <ul className="list-group">
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>진행기간</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>2024년 4월 1일 ~ 2024년 4월 30일</div>
                    </li>
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>진행사이트</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>올림푸스</div>
                    </li>
                    <li className="list-group-item">
                        <div className="col-sm-2"> <strong>등록갯수</strong></div>
                        <div className="list-group-item-text" style={{ minHeight: "20px" }}>1</div>
                    </li>
                </ul>
                <div className="contents-view">
                    <h1>{liveEventList.title}</h1>
                    <div className="event_date">
                        <span className="txt">
                            이벤트 기간 :
                            {dateFormation(liveEventList.start_date, 3)} ~ {dateFormation(liveEventList.end_date, 3)} </span>
                    </div>
                    <div id="post-content">
                        <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(liveEventList.detail) ? changeEditorValue(liveEventList.detail) : "" }}></div>
                        <div className="hidden_keyword"></div>

                    </div>
                </div>
                <div className="pull-right mb20">
                </div>
                <div className="clearfix"></div>

                <div className="border_button mt20 mb20">
                    <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                        <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                    </div>
                </div>
            </div>
            {/* <div className="event_order_btn">
                <a href="https://slotbuff2.com/write/event_order?origin=48627" title="이벤트 신청하기">
                    이벤트 신청</a>
            </div> */}
        </div>
    );
};
