import React, { useState, useEffect } from 'react';
import { resource_url } from '../../../Utils/keys';
import { dateFormation } from '../../../Utils';
import { ArticleDetail } from './ArticleDetail';

export { FollowArticleDetail };


const FollowArticleDetail = ({
    followArticleList,
    onBtnClick
}) => {

    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0)

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnDetailClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    return (
        <div className="main_area">

            {showDetail &&
                <ArticleDetail
                onBtnDetailClick={onBtnDetailClick}
                active={active}
                setActive={setActive}
                followArticleList={followArticleList}
                articleList={followArticleList.detail[active]}
                length={followArticleList.detail.length}
                />}

            {!showDetail &&
                <>
                    <div className="page_seo_tit_box">
                        <h2>흥미로운 연재게시판</h2>
                        <p>온라인슬롯에 지친 슬롯유저들을 위한 힐링타임! PG슬롯만의 재밌는 독점 컨텐츠가 준비되었습니다.</p>
                    </div>
                    <div className="serial_cover">
                        <div className="imgs">
                            <img src={`${resource_url}/${followArticleList.image}`} alt={followArticleList.title} />
                        </div>
                        <div className="txt">
                            <h3><i className="fas fa-book mr10" style={{ color: "#919197" }} aria-hidden="true"></i>{followArticleList.title}</h3>
                            <p>{followArticleList.content}</p>
                        </div>
                    </div>

                    <div className="board">
                        <div name="fboardlist" id="fboardlist"
                            acceptCharset="utf-8">

                            <table className="table table-hover serial_board">
                                <thead>
                                </thead>
                                <tbody>
                                    {followArticleList.detail &&
                                        followArticleList.detail.map((item, index) => {
                                            return (
                                                <tr key={index} onClick={() => onShowDetail(index)}>
                                                    <td><span style={{ background: "rgb(159 31 235 / 29%)", color: "rgb(191 83 255)", padding: "2px 6px", borderRadius: "5px" }}>{index + 1}화</span>
                                                    </td>
                                                    <td className="center">
                                                        <div className='cursor-pointer' title={`${followArticleList.title}(${index + 1})`}>
                                                            <i className="fas fa-book mr10" style={{ color: "#919197" }} aria-hidden="true"></i>{followArticleList.title}
                                                            ({index + 1}화)</div>
                                                    </td>
                                                    <td>{dateFormation(item.created_date, 3)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="border_button">
                            <div className="pull-left mr10">
                                <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                            </div>
                        </div>
                        <nav></nav>
                    </div>
                </>}
        </div>
    );
};
