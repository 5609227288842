import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { InquireContent } from '../../Components/Account';


export {Inquire};

function Inquire() {
    return (
        <Fragment>
            <Navbar type="inquires" />
            <div className='main'>
                <InquireContent />
            </div>
        </Fragment>
    );

}