import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { VerifiedSiteContent } from '../../Components/VerifiedSite';


export { VerifiedSite };

function VerifiedSite() {
    return (
        <Fragment>
            <Navbar type="verifiedSite" />
            <div className='main'>
                <VerifiedSiteContent />
            </div>
        </Fragment>
    );
}