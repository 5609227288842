import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { MyScrapContent, MyPageMenu } from '../../Components/Account';

export { MyScrap };

function MyScrap() {
    return (
        <Fragment>
            <Navbar type="myscrap" />
            <div className='main'>
                <MyPageMenu>
                    <MyScrapContent />
                </MyPageMenu>
            </div>
        </Fragment>
    );
}
