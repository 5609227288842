import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../Utils/api';

import { isEmpty, toastr, setAuthToken } from '../Utils/index';
import { api_url } from '../Utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'user';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        isAuthenticated: false,
        data: {},
        loginHistory: [],
        articleHistory: [],
        answerHistory: [],
        pointHistory: [],
        attendanceList: [],
        myAttendanceList: [
            {
                serialAtt: 0,
            },
        ],
        recommendHistory: [],
    }
}

function createReducers() {
    return {
        setUserInfo,
        setLoginHistory,
        setArticleHistory,
        setAnswerHistory,
        setPointHistory,
        setAttendanceList,
        setRecommendList,
        setMyAttendanceList
    };

    function setUserInfo(state, action) {
        state.data = action.payload;
        state.isAuthenticated = !isEmpty(action.payload);
    }
    function setLoginHistory(state, action) {
        state.loginHistory = action.payload;
    }
    function setArticleHistory(state, action) {
        state.articleHistory = action.payload;
    }
    function setAnswerHistory(state, action) {
        state.answerHistory = action.payload;
    }
    function setPointHistory(state, action) {
        state.pointHistory = action.payload;
    }
    function setAttendanceList(state, action) {
        state.attendanceList = action.payload;
    }
    function setMyAttendanceList(state, action) {
        state.myAttendanceList = action.payload;
    }
    function setRecommendList(state, action) {
        state.recommendHistory = action.payload;
    }

}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        loginUser: loginUser(),
        signUp: signUp(),
        logout: logout(),
        getUserInfo: getUserInfo(),
        getLoginHistory: getLoginHistory(),
        getArticleHistory: getArticleHistory(),
        getRecommendHistory: getRecommendHistory(),
        getAnswerHistory: getAnswerHistory(),
        getPointHistory: getPointHistory(),
        getAttendanceList: getAttendanceList(),
        getMyAttendanceList: getMyAttendanceList(),
    };

    function loginUser() {
        return createAsyncThunk(
            `${name}/loginUser`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/User/login", params)
                        .then(data => {
                            if (data.data.bonus_status) {
                                toastr.info("금일 첫 로그인으로 30포인트를 지급받으셧습니다. ");
                            }
                            toastr.success(data.message);
                            dispatch(pageActions.setLoading(false));
                            setAuthToken(data.data.token);
                            localStorage.setItem("jwtToken", data.data.token);
                            dispatch(userActions.setUserInfo(data.data.userData));
                            dispatch(userActions.getUserInfo());

                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }


    function getUserInfo() {
        return createAsyncThunk(
            `${name}/getUserInfo`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/User/getUserinfo")
                        .then(data => {
                            dispatch(userActions.setUserInfo(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getLoginHistory() {
        return createAsyncThunk(
            `${name}/getLoginHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/loginHist", params)
                        .then(data => {
                            dispatch(userActions.setLoginHistory(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getArticleHistory() {
        return createAsyncThunk(
            `${name}/getArticleHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/myArticleHists", params)
                        .then(data => {
                            dispatch(userActions.setArticleHistory(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getRecommendHistory() {
        return createAsyncThunk(
            `${name}/getRecommendHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/recomList", params)
                        .then(data => {
                            dispatch(userActions.setRecommendList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getAnswerHistory() {
        return createAsyncThunk(
            `${name}/getAnswerHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/myanswerHists", params)
                        .then(data => {
                            dispatch(userActions.setAnswerHistory(data.data.lsit));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getPointHistory() {
        return createAsyncThunk(
            `${name}/getPointHistory`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/pointHist", params)
                        .then(data => {
                            dispatch(userActions.setPointHistory(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getAttendanceList() {
        return createAsyncThunk(
            `${name}/getAttendanceList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/attendanceLists", params)
                        .then(data => {
                            dispatch(userActions.setAttendanceList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getMyAttendanceList() {
        return createAsyncThunk(
            `${name}/getMyAttendanceList`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Mypage/AttCheck")
                        .then(data => {
                            dispatch(userActions.setMyAttendanceList(data.data.list));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function signUp() {
        return createAsyncThunk(
            `${name}/signUp`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/User/SignUp", params)
                        .then(data => {
                            toastr.success(data.message);
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            async function (params, { getState, dispatch }) {
                if (getState().user.isAuthenticated) {
                    toastr.warning("로그아웃되었습니다.");
                    request.post(api_url + "/api/User/logout", {
                        ipAddress: localStorage.getItem('client-ip')
                    })
                        .then(res => {

                        })
                        .catch(error => {
                            console.error(error);
                        })
                }
                localStorage.removeItem("jwtToken");
                dispatch(userActions.setUserInfo({}));
                setAuthToken(false);
            }
        );
    }
}