import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from '../Common';
import { eventActions } from '../../store';
import { CasinoInfoDetail } from './components';
import { api_url, resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { request } from '../../Utils/api';
import { RightSideBar, SideBar } from '../Layout';

export { CasinoInfoContent };

function CasinoInfoContent() {


    const dispatch = useDispatch();
    const casinoInfoList = useSelector(state => state.event.casinoInfoList);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        const params = {
            type: "slotInfo",
            typeId: casinoInfoList[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(eventActions.getCasinoInfoList(params));
    }
    useEffect(() => {
        getList();
    }, []);


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {/* <RightSideBar /> */}
                <div className='main_area' >
                    {showDetail &&
                        <CasinoInfoDetail
                            onBtnClick={onBtnClick}
                            casinoInfoList={casinoInfoList[active]}
                            setActive={setActive}
                            active={active}
                            length={casinoInfoList.length}
                        />

                    }
                    {!showDetail &&
                        <>
                            <div className="page_seo_tit_box">
                                <h2>온라인 슬롯정보안내</h2>
                                <p>PG슬롯은 온라인 카지노의 전반적인 정보를 제공하는 최고의 안내 플랫폼입니다.
                                    사용자에게 먹튀 없는 안전한 카지노 사이트 추천부터 신규 슬롯 게임 소식, 전략 및 팁에 이르기까지 깊이 있는
                                    정보를 전달합니다.
                                    PG슬롯와 함께라면 높은 품질의 온라인 카지노 경험을 제공해 드립니다.
                                </p>
                            </div>
                            <div className="board">
                                <div className="board_form_box">
                                    <div className="array_area">
                                        <select name="searchType" value={searchType} onChange={onChange} >
                                            <option value="">전체</option>
                                            <option value="date">날짜순</option>
                                            <option value="viewCnt">조회수</option>
                                        </select>
                                    </div>
                                    <div className="search_area">
                                        <div className="searchbox">
                                            <div>
                                                <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange} onKeyDown={onEnter} /></span>
                                                <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="gallery_list full_gallery_list magazine_list">
                                        <ul>
                                            {casinoInfoList &&
                                                casinoInfoList.map((list, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="box">
                                                                <div className="imgs cursor-pointer" onClick={() => onShowDetail(index)}>
                                                                    <img src={`${resource_url}/${list.image}`} alt={list.title} />
                                                                </div>
                                                                <div className="txt">
                                                                    <h2>{list.title}</h2>
                                                                    <p>{list.content}</p>
                                                                    <div className="data">
                                                                        <dl>
                                                                            <dd>
                                                                                <span className="icon">
                                                                                    <i className="fa fa-clock-o mr10" aria-hidden="true"></i>
                                                                                </span>
                                                                                <span className="con">{dateFormation(list.created_date, 1)}</span>
                                                                            </dd>
                                                                            {/* <dd>
                                                                    <span className="icon">
                                                                        <i className="fa fa-user" aria-hidden="true"></i>
                                                                    </span>
                                                                    <span className="con">김둘리</span>
                                                                </dd> */}
                                                                            <dd>
                                                                                <span className="icon">
                                                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                                                </span>
                                                                                <span className="con">{list.viewCnt}</span>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                            {isEmpty(casinoInfoList) &&
                                                <li>
                                                    <div className="box">
                                                        <div className="txt">
                                                            <p style={{ paddingTop: "50px" }}>내역이 없습니다.</p>
                                                        </div>
                                                    </div>
                                                </li>

                                            }

                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="border_button">
                                <div className="pull-left mr10">
                                    <a href="https://slotbuff1.com/board/info?"
                                        className="btn btn-primary btn-sm">목록</a>
                                </div>
                            </div> */}
                                {!isEmpty(casinoInfoList) &&
                                    <Pagination pageInfo={pageInfo} getList={getList} />
                                }
                            </div>

                            <div className="page_seo_tit_box">
                                <h2>온라인 카지노 모든 정보를 공유</h2>
                                <p>인터넷을 통한 온라인 카지노 게임의 전반적인 지식과 동향, 그리고 다양한 게임 및 소프트웨어 제공업체에 대한 깊은
                                    이해를 제공하는 컴프리헨시브한 안내자료입니다.</p>
                            </div>

                            <div className="page_seo_conts_box">
                                <div className="section_box">
                                    <h3>✔ 온라인 바카라 정보</h3>
                                    <p>이 게임의 기본 규칙, 전략, 최신 트렌드, 그리고 온라인 바카라에서의 플레이 방법에 관한 정보</p>
                                    <ul>
                                        <li>- 에볼루션게이밍 : 바카라의 라이브 딜러 게임의 선두주자로 인지되는 회사의 특징</li>
                                        <li>- 아시안게이밍 : 아시아 지역에서 선두를 달리는 바카라 게임 제공업체의 주요 특징</li>
                                        <li>- 프라그마틱 바카라 : 프라그마틱의 바카라 게임에 대한 특징 및 독특한 기능</li>
                                        <li>- 드림게이밍 : 다양한 라이브 바카라 게임을 제공하는 회사의 특징</li>
                                        <li>- WM카지노 : WM의 바카라 게임 및 서비스에 대한 정보</li>
                                    </ul>
                                </div>

                                <div className="section_box">
                                    <h3>✔ 온라인 슬롯 정보</h3>
                                    <p>인터넷을 통한 슬롯 게임의 다양한 특징 및 팁</p>
                                    <ul>
                                        <li>- 프라그마틱 : 이 브랜드의 독특한 슬롯 게임 및 기능에 관한 소개</li>
                                        <li>- 노리밋시티 : 노리밋시티에서 제공하는 주요 슬롯 게임과 그 특징</li>
                                        <li>- PG 소프트 : PG 소프트의 슬롯 게임 디자인 및 주요 게임에 대한 정보</li>
                                        <li>- 플레이엔고 : 이 브랜드의 인기 슬롯 게임과 특징을 중심으로한 소개</li>
                                        <li>- 릴렉스게이밍 : 혁신적인 슬롯 게임과 주요 제품에 대한 소개</li>
                                        <li>- CQ9 : 아시아 시장을 중심으로 활약하는 슬롯 제공업체의 주요 게임 및 특징</li>
                                        <li>- 하바네로 : 다양한 테마와 기능을 갖춘 슬롯 게임의 소개</li>
                                        <li>- 부운고 : 전세계적으로 인기 있는 슬롯 게임들의 특징</li>
                                        <li>- 게임아트 : 아트와 디자인이 돋보이는 독특한 슬롯 게임들의 소개</li>
                                        <li>- 와즈단 : 신선한 주제와 다양한 보너스 기능을 제공하는 슬롯 게임들의 특징</li>
                                    </ul>
                                </div>

                            </div>

                            <div className="page_seo_conts_box">
                                <div className="section_box">
                                    <h3>✔ 호텔 카지노 정보</h3>
                                    <p>세계 곳곳의 호텔 카지노의 독특한 매력과 서비스를 탐험.
                                        다양한 게임부터 특별한 이벤트, 호텔의 편의 시설까지 전반적인 정보를 한 눈에 파악할 수 있는 최상의 경험을
                                        위한 가이드!</p>
                                    <section>
                                        <h4>✅ 필리핀 카지노</h4>
                                        <p>필리핀은 동남아시아에서 빠르게 성장하는 카지노 명소로 떠오르고 있습니다.
                                            맑은 바다와 화려한 도시 경관 사이에 자리잡은 이 카지노들은 방문객들에게 독특한 게임 경험과 함께
                                            다양한 엔터테인먼트를 제공합니다.
                                            특히, 매닐라와 클락 같은 주요 도시에서는 대형 카지노 리조트가 집중적으로 위치해 있어, 세계
                                            각국에서 오는 관광객들의 발걸음을 끌어당기고 있습니다.</p>
                                        <ul>
                                            <li><p>1. 시티 오브 드림 카지노 : 매닐라 베이 지역에 자리잡은 대형 카지노 리조트로,
                                                세계 최고의 게임 시설과 럭셔리한 숙박 시설, 그리고 다양한 쇼와 이벤트로 이름을
                                                알리고 있습니다.</p></li>
                                            <li><p>2. 솔레오 카지노 : 필리핀 클락 지역의 플래그십 카지노로, 현대적인 디자인과 다양한
                                                게임 테이블, 그리고 훌륭한 레스토랑과 쇼를 자랑합니다.</p></li>
                                            <li><p>3. 오카다 카지노 : 엔터테인먼트 시티에 위치한 이 카지노는 화려한 인테리어와 넓은
                                                게임 공간을 자랑하며, 최고 수준의 서비스와 함께 방문객들에게 깊은 인상을 남깁니다.</p></li>
                                        </ul>
                                    </section>
                                    <section>
                                        <h4>✅ 마카오 카지노</h4>
                                        <p>마카오는 세계적으로 유명한 카지노 도시로, '아시아의 라스베이거스'라는 별명을 갖고 있습니다.
                                            국제적인 카지노 리조트들이 즐비하게 위치하며, 전세계에서 방문하는 관광객들에게 화려한 게임과
                                            엔터테인먼트 경험을 제공합니다.
                                            섬 전체가 유네스코 세계문화유산에 등재된 마카오는 그 특별한 문화적 배경 속에서 최첨단 카지노
                                            리조트의 화려함을 뽐내며 방문객들을 매료시킵니다.</p>
                                        <ul>
                                            <li><p>1. 갤럭시 카지노 : 현대적인 디자인과 함께 마카오 코타이 스트립의 중심에 자리한
                                                대형 리조트로, 다양한 게임 시설 및 프리미엄 호텔, 쇼핑몰, 레스토랑 등을 보유하고
                                                있다.</p></li>
                                            <li><p>2. 베네시안 카지노 : 마카오의 랜드마크 중 하나로, 이탈리아 베네치아를 테마로 한
                                                대규모 카지노 리조트. 그랜드 운하와 고리오도를 볼 수 있는 독특한 경험을 제공한다.</p></li>
                                            <li><p>3. 리스보아 카지노 : 마카오의 카지노 역사와 전통을 대표하는 곳으로, 클래식한
                                                스타일의 카지노와 함께 최고의 서비스를 제공한다.</p></li>
                                            <li><p>4. MGM 카지노 : 럭셔리한 디자인과 함께 다양한 엔터테인먼트 시설을 자랑하는
                                                대표적인 카지노 리조트.</p></li>
                                            <li><p>5. 윈 펠리스 카지노 : 코타이 스트립의 중심부에 위치하며, 아름다운 호수와 분수
                                                쇼로 유명하다.</p></li>
                                            <li><p>6. 더 파리지앵 카지노 : 프랑스 파리를 테마로 한 이 리조트는 에펠탑 미니어처와
                                                함께 호텔, 쇼핑, 다이닝, 카지노 등 다양한 엔터테인먼트를 제공한다.</p></li>
                                            <li><p>7. 스튜디오 시티 카지노 : 영화와 엔터테인먼트를 주제로 한 대형 카지노 리조트로,
                                                다양한 쇼와 이벤트를 통해 관광객들에게 특별한 경험을 제공한다.</p></li>
                                        </ul>
                                    </section>
                                </div>
                            </div>

                            <div className="page_seo_conts_box">
                                <div className="section_box">
                                    <h3>✔ 카지노 게임 정보</h3>
                                    <p>다양한 카지노 게임들의 규칙, 전략, 그리고 팁을 한 눈에 파악하세요.
                                        바카라부터 블랙잭, 룰렛, 슬롯머신까지, 모든 주요 카지노 게임의 핵심 정보를 제공합니다.
                                        초보자와 전문가 모두를 위한 완벽한 카지노 게임 가이드!</p>
                                    <ul>
                                        <li><h4>1. 바카라 (Baccarat) : 카드 게임 중 하나로, 플레이어와 뱅커 중 어느 쪽의 카드
                                            합계가 9에 가까울지를 예측하여 베팅하는 게임입니다.</h4></li>
                                        <li><h4>2. 블랙잭 (Blackjack): 플레이어의 카드 합계가 21을 초과하지 않으면서 딜러보다
                                            높을 때 승리하는 게임입니다.</h4></li>
                                        <li><h4>3. 룰렛 (Roulette): 작은 구슬을 회전하는 휠 위에 던져서, 어떤 번호나 색상에
                                            구슬이 멈출지 예측하여 베팅하는 게임입니다.</h4></li>
                                        <li><h4>4. 크랩스 (Craps): 주사위 두 개를 던져서 특정 숫자 조합을 얻는 게임입니다.</h4></li>
                                        <li><h4>5. 슬롯머신 (Slots): 동전 또는 토큰을 넣고 레버나 버튼을 누르면, 회전하는 릴에서
                                            이미지가 나열되는 게임입니다. 이미지의 조합에 따라 상금이 달라집니다.</h4></li>
                                        <li><h4>6. 포커 (Poker): 여러 종류의 카드 게임을 포함하는 카테고리로, 플레이어들 간의 합계
                                            또는 카드 조합을 통해 승리자를 결정합니다.</h4></li>
                                        <li><h4>7. 비디오 포커 (Video Poker): 전자화된 스크린에서 슬롯 머신과 비슷한 형태로
                                            진행되는 포커 게임입니다.</h4></li>
                                        <li><h4>8. 케노 (Keno): 숫자를 선택하고, 그 숫자들이 무작위로 추출되는지 관찰하는
                                            게임입니다.</h4></li>
                                        <li><h4>9. 시카고 (Sic Bo): 주사위 세 개를 사용하는 중국의 전통 게임으로, 주사위의 합계나
                                            특정 조합을 예측하여 베팅합니다.</h4></li>
                                    </ul>
                                    <p>이 외에도 카지노마다 제공하는 게임이 다를 수 있으며, 위의 게임들은 가장 대표적인 카지노 게임들 중
                                        일부입니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>✔ 카지노 정보 - 결론</h3>
                                    <p>카지노는 전세계 많은 사람들에게 엔터테인먼트의 중심지로 자리 잡고 있으며,
                                        게임의 스릴과 높은 스테이크, 그리고 더불어 거액의 상금 획득의 기회를 제공합니다.
                                        이러한 매력 때문에 많은 사람들이 카지노를 찾게 되며, 이에 따라 카지노 관련 정보도 큰 관심을 받고
                                        있습니다.</p>

                                    <p>그러나 카지노를 이용할 때에는 항상 정보에 대한 검증이 중요합니다.
                                        어떤 게임의 규칙이 어떻게 되는지, 어떤 카지노가 신뢰할 수 있는지,
                                        그리고 어떻게 안전하게 게임을 즐길 수 있는지는 모든 이용자들이 알아야 할 기본적인 사항들입니다.
                                        무엇보다 중요한 것은 도박은 항상 책임감 있게, 그리고 자신의 경제적 상황을 고려하여 접근해야 한다는
                                        것입니다.</p>

                                    <p>결국, 카지노 정보는 높은 품질의 엔터테인먼트를 위한 핵심적인 요소이지만, 정보를 올바르게 활용하고,
                                        항상 책임감 있는 자세로 카지노 활동에 임해야 한다는 것을 명심해야 합니다.
                                        이를 통해 우리는 안전하고 즐거운 카지노 경험을 할 수 있을 것입니다.</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <SideBar />
            </div>
        </div>
    );
}