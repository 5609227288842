import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from '../Common/Pagination';
import { eventActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { CasinoNewsDetail } from './components';



export { CasinoNewsContent };

function CasinoNewsContent() {

    const dispatch = useDispatch();
    const casinoNewsList = useSelector(state => state.event.casinoNewsList);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(eventActions.getCasinoNewsList(params));
    }
    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail &&
                    <CasinoNewsDetail
                        onBtnClick={onBtnClick}
                        casinoNewsList={casinoNewsList[active]}
                        setActive={setActive}
                        active={active}
                        length={casinoNewsList.length}
                    />

                }
                {!showDetail &&
                    <>
                        <div className="page_seo_tit_box">
                            <h2>온오프라인 최신 카지노 뉴스 안내</h2>
                            <p>카지노 뉴스 업데이트는 전 세계 카지노 산업의 최신 소식을 한눈에 볼 수 있는 허브입니다. 현장의 뜨거운 이슈부터
                                새롭게 출시되는 게임, 전략 및 팁, 그리고 카지노 관련 이벤트 소식까지 다양한 정보를 제공합니다. 오프라인
                                카지노의 화려한 이벤트와 온라인 카지노의 신기술 및 트렌드, 모든 것을 놓치지 않고 업데이트하여 카지노 애호가들의
                                정보 획득을 도와드립니다. 항상 최신 카지노 소식을 알고 싶다면 이곳이 바로 당신이 찾던 곳입니다.</p>
                        </div>
                        <div className="board">
                            <div className="board_form_box">
                                <div className="array_area">
                                    <select name="searchType" value={searchType} onChange={onChange} >
                                        <option value="">정렬하기</option>
                                        <option value="date">날짜순</option>
                                        <option value="viewCnt">조회수</option>
                                    </select>
                                </div>
                                <div className="search_area">
                                    <div className="searchbox">
                                        <div>
                                            <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange}  onKeyDown={onEnter}/></span>
                                            <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div className="gallery_list full_gallery_list">
                                    <ul>
                                        {casinoNewsList &&
                                            casinoNewsList.map((list, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="box">
                                                            <div className="imgs cursor-pointer" onClick={() => onShowDetail(index)}>
                                                                <div className="news_icon">
                                                                    <img src="../imgs/news_logo.jpg" alt="버프뉴스 아이콘" />
                                                                </div>
                                                                <img src={`${resource_url}/${list.image}`} alt={list.title} />
                                                            </div>
                                                            <div className="txt">
                                                                <h2>{list.title}</h2>
                                                                <p>{list.content}</p>
                                                                <div className="data">
                                                                    <dl>
                                                                        <dd>
                                                                            <span className="icon">
                                                                                <i className="fa fa-clock-o mr10" aria-hidden="true"></i>
                                                                            </span>
                                                                            <span className="con">{dateFormation(list.created_date, 1)}</span>
                                                                        </dd>
                                                                        {/* <dd>
                                                                    <span className="icon">
                                                                        <i className="fa fa-user" aria-hidden="true"></i>
                                                                    </span>
                                                                    <span className="con">김둘리</span>
                                                                </dd> */}
                                                                        <dd>
                                                                            <span className="icon">
                                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </span>
                                                                            <span className="con">{list.viewCnt}</span>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                        {isEmpty(casinoNewsList) &&
                                            <li>
                                                <div className="box">
                                                    <div className="txt">
                                                        <p style={{ paddingTop: "50px" }}>내역이 없습니다.</p>
                                                    </div>
                                                </div>
                                            </li>

                                        }

                                    </ul>

                                </div>
                            </div>
                            {/* <div className="border_button">
                        <div className="pull-left mr10">
                            <a href="../board/news?" className="btn
                        btn-default btn-sm">목록</a>
                        </div>
                    </div> */}
                            {!isEmpty(casinoNewsList) &&
                                <Pagination pageInfo={pageInfo} getList={getList} />
                            }
                        </div>
                    </>}

            </div>

        </div>
    );
}