import React from 'react';
import { changeEditorValue, isEmpty } from '../../../Utils';

export { ArticleDetail };


const ArticleDetail = ({
    onBtnDetailClick,
    articleList,
    followArticleList,
    active,
    setActive,
    length
}) => {

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }   

    return (
        <div className="board item_board mukti_board">
            <h1 className="post_title">
                {followArticleList.title}
                ({active + 1}화)
            </h1>
            <div className="contents-view">
                <div className="contents-view-img">
                </div>

                <div id="post-content">
                    <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(articleList.content) ? changeEditorValue(articleList.content) : "" }}>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <div id="viewcomment">
                <div className="alert alert-auto-close alert-dismissible alert-comment-list-message" style={{ display: "none" }}>
                    <button type="button" className="close alertclose">×</button>
                    <span className="alert-comment-list-message-content"></span>
                </div>
            </div>

            <div className="border_button mt20 mb20">
                <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                    <button className="btn btn-primary btn-sm" onClick={onBtnDetailClick}>목록</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                    <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                </div>
            </div>
        </div>
    );
};
