import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { FreeSlotContent } from '../../Components/FreeSlot';


export { FreeSlot };

function FreeSlot() {
    return (
        <Fragment>
            <Navbar type="freeslot" />
            <div className='main'>
                <FreeSlotContent />
            </div>
        </Fragment>
    );
}