import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Edit, Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';
import { communityActions, siteActions } from '../../store';
import { dateFormation, isEmpty, toastr } from '../../Utils';
import { SlotGameReviewDetail } from './components';
import { request } from '../../Utils/api';
import { api_url, resource_url } from '../../Utils/keys';
import { BestReviewContent } from './BestReviewContent';


export { SlotGameReviewContent };

function SlotGameReviewContent() {

    const dispatch = useDispatch();
    const slotGameReviewList = useSelector(state => state.community.slotGameReviewList);
    const logoList = useSelector(state => state.community.logoList);
    const userData = useSelector(state => state.user.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowModal = () => {
        if (!isAuthenticated) {
            toastr.warning("로그인하고 이용해주세요.")
        } else {
            setShowModal(!showModal)
        }
    }

    const onEditComplete = (params) => {
        const sendParams = {
            titleImg: params.titleImg,
            detail: params.articleList,
            title: params.title,
            siteImg: params.siteImg,
        }
        dispatch(communityActions.sendSlotGameReview(sendParams));
        onShowModal();
    }
    // let formData = new FormData();

    // const onEditComplete = (params) => {
    //     formData.append("titleImg", params.titleImg);
    //     formData.append("siteImg", params.siteImg);

    //     const sendParams = {
    //         loginId: userData.loginId,
    //         detail: params.articleList,
    //         title: params.title
    //     }
    //     for (let param in sendParams) {
    //         formData.append(param, sendParams[param]);
    //     }
    //     dispatch(communityActions.sendSlotGameReview(formData));
    // }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
        const params = {
            type: "slotGameReviewLists",
            typeId: slotGameReviewList[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
        // window.scrollTo(0, verifiedSiteRef.current.offsetTop)
    }
    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "slotGameReviewLists",
            typeId: slotGameReviewList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(communityActions.getSlotGameReviewList(params));
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <div className="container">
                <div className="row" style={{ margin: "0" }}>
                    {/* <RightSideBar /> */}
                    {showModal &&
                        <Edit
                            titleType="슬롯리뷰 글쓰기"
                            type="slotGameReview"
                            showModal={showModal}
                            onShowModal={onShowModal}
                            onEditComplete={onEditComplete}
                            logoList={logoList}
                        />
                    }
                    {showDetail && <SlotGameReviewDetail
                        length={slotGameReviewList.length}
                        active={active}
                        onBtnClick={onBtnClick}
                        setActive={setActive}
                        slotGameReviewList={slotGameReviewList[active]}
                        pageInfo={pageInfo}
                        getList={getAnswerList} />
                    }
                    {(!showModal && !showDetail) &&
                        <>
                            <BestReviewContent />
                            <div className="main_area">
                                <div className="page_seo_tit_box">
                                    <h2>슬롯리뷰</h2>
                                </div>
                                <div className="board">
                                    <div className="board_form_box">
                                        <div className="array_area">
                                            <select name="searchType" value={searchType} onChange={onChange} >
                                                <option value="">전체</option>
                                                <option value="date">날짜순</option>
                                                <option value="viewCnt">조회수</option>
                                            </select>
                                        </div>
                                        <div className="search_area">
                                            <div className="searchbox">
                                                <div>
                                                    <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange} onKeyDown={onEnter} /></span>
                                                    <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='board_form_box_content'>
                                        <table className="table table-hover normal_board table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>번호</th>
                                                    <th>이미지</th>
                                                    <th>제목</th>
                                                    <th>사이트</th>
                                                    <th>글쓴이</th>
                                                    <th>날짜</th>
                                                    <th>조회</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {slotGameReviewList &&
                                                    slotGameReviewList.map((list, index) => {
                                                        return (
                                                            <tr key={index} onClick={() => onShowDetail(index)}>
                                                                <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                                <td className="imgs">
                                                                    <img src={list.titleImg} alt={list.title} />
                                                                </td>
                                                                <td className="center" style={{ whiteSpace: "nowrap" }}>
                                                                    <span className="user_name">{list.title}</span>
                                                                    <span className="new_icon">N</span>
                                                                    {/* <span className="comment_icon">+1</span> */}
                                                                </td>
                                                                <td className="game_site">
                                                                    <img src={`${resource_url}/${list.siteImg}`} alt={list.title} />
                                                                </td>
                                                                <td>
                                                                    <div className="user_levelname" style={{ display: "flex" }}>
                                                                        <span className="lv_icon_box">
                                                                            <div className={`lv_icon lv_icon${list.level > 6 ? 1 : list.level}`}></div>
                                                                        </span>
                                                                        <span className="user_name">{list.loginId}</span>
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }}>{dateFormation(list.created_date, 4)}</td>
                                                                <td>{list.viewCnt}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }


                                            </tbody>
                                            {
                                                isEmpty(slotGameReviewList) &&
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="7" className="nopost">내역이 없습니다</td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    <div className="border_button">
                                        {/* <div className="pull-left mr10">
                                    <button className="btn btn-primary btn-sm">목록</button>
                                </div> */}
                                        {isAuthenticated &&
                                            <div className="pull-right">
                                                <button className="btn btn-success btn-sm" onClick={onShowModal}>글쓰기</button>
                                            </div>}
                                    </div>
                                    {!isEmpty(slotGameReviewList) &&
                                        <Pagination pageInfo={pageInfo} getList={getList} />
                                    }
                                </div>
                            </div>
                        </>
                    }
                    <SideBar />
                </div>
            </div>
        </>
    );
}