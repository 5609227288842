import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { FriendSiteReviewContent } from '../../Components/Community';


export { FriendSiteReview };

function FriendSiteReview() {
    return (
        <Fragment>
            <Navbar type="friendsitereview" />
            <div className='main'>
                <FriendSiteReviewContent />
            </div>
        </Fragment>
    );

}