import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { changeEditorValue, dateFormation, isEmpty, toastr } from '../../../Utils';

export { NotificationDetail };


function NotificationDetail({
    onBtnClick,
    setActive,
    active,
    length,
    notificationList,
}) {
    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
        } else {
            setActive(key);
        }
    }

    return (
        <div className="main_area">
            <div className="board">
                <div className="post_title">
                    <h1 style={{ fontSize: "18px" }}>{isEmpty(notificationList.title) ? "" : notificationList.title}</h1>
                </div>
                <ul className="info">
                    <li>
                        <div className="user_levelname">
                            <span className="lv_icon_box"></span>
                            {/* <span className="user_name">{notificationList.editor.name}</span> */}
                        </div>
                    </li>
                    <li className="pull-right time"><i className="fa fa-clock-o mr10" aria-hidden="true"></i> {dateFormation(notificationList.created_date, 6)}</li>
                </ul>
                <div className="contents-view">

                    <div id="post-content">
                        <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(notificationList.detail) ? changeEditorValue(notificationList.detail) : "" }}>
                        </div>
                        <div className="hidden_keyword"></div>

                    </div>

                </div>
                <div className="clearfix"></div>

                <div id="viewcomment">
                    <div className="alert alert-auto-close alert-dismissible alert-comment-list-message" style={{ display: "none" }}><button
                        type="button" className="close alertclose">×</button><span
                            className="alert-comment-list-message-content"></span></div>
                </div>

                <div className="border_button mt20 mb20">
                    <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                        <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
