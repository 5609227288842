import React from 'react';

export { MyScrapContent };

function MyScrapContent() {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>번호</th>
                    <th>게시판</th>
                    <th>제목</th>
                    <th>보관일시</th>
                    <th>제목수정</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan="6" className="nopost">회원님이 스크랩하신 글이 없습니다</td>
                </tr>
            </tbody>
        </table>
    );
}
