import React from 'react';
import { Link } from "react-router-dom";





export { Footer };

const footerMenu = [
    {
        name: "보증사이트",
        path: "/verifiedsite"
    },
    {
        name: "커뮤니티",
        path: "/slotvideo"
    },
    {
        name: "온라인슬롯정보",
        path: "/info"
    },
    // {
    //     name: "먹튀사이트",
    //     path: "/fakesite"
    // },
    {
        name: "포인트몰",
        path: "/pointmall"
    },
    {
        name: "무료슬롯",
        path: "/freeslot"
    },
    {
        name: "고객센터",
        path: "/inquires"
    },

]
const anchorMenu = [
    {
        name: "PG슬롯",
        path: "https://PGslot"
    },
    {
        name: "슬롯 무료체험",
        path: "https://slotfreeexperience"
    },
    {
        name: "슬롯 사이트 추천",
        path: "https://slotsiterecommendation"
    },
    {
        name: "온라인 슬롯사이트",
        path: "https://onlineslotsite"
    },
    {
        name: "안전 슬롯 사이트",
        path: "https://safeslotsite"
    },
    {
        name: "슬롯 커뮤니티",
        path: "https://slotcommunity"
    },
    {
        name: "슬롯머신 하는법",
        path: "https://slotmethod"
    },
    {
        name: "온라인 슬롯 배팅법",
        path: "https://slotbetting"
    },

]

function Footer() {
    return (
        <footer className='footer w-ba'>
            <div className="container">
                <div className="sitemap">
                    <ul>
                        {/* {
                            footerMenu.map((menu, index) => {
                                return (
                                    <li key={index}><Link to={menu.path} title={menu.name}>{menu.name}</Link></li>
                                )
                            })
                        } */}
                        <li><a href='https://www.pragmaticplay.com'><img src='../imgs/logo/pragmatic.png' alt='logo12' /></a></li>
                        <li><a href='https://blueprintgaming.com'><img src='../imgs/logo/blueprint.png' alt='logo2' /></a></li>
                        <li><a href='https://www.cq9.com'><img src='../imgs/logo/cq9.png' alt='logo3' /></a></li>
                        <li><a href='https://www.dragonsoft.com'><img src='../imgs/logo/dragonsoft.png' alt='logo4' /></a></li>
                        <li><a href='https://www.evolution.com'><img style={{ filter: "invert(1)" }} src='../imgs/logo/evolution_logo.png' alt='logo5' /></a></li>
                        <li><a href='https://habanerosystems.com'><img src='../imgs/logo/habanero_white.png' alt='logo6' /></a></li>
                        <li><a href='https://microgaming.com'><img src='../imgs/logo/logo.png' alt='logo7' /></a></li>
                        <li><a href='https://netent.com'><img src='../imgs/logo/netent.png' alt='logo8' /></a></li>
                        <li><a href='https://www.nolimitcity.com'><img src='../imgs/logo/nolimit-city.png' alt='logo9' /></a></li>
                        <li><a href='https://pgsofot.com'><img style={{ filter: "invert(1)" }} src='../imgs/logo/pgsofot.png' alt='logo10' /></a></li>
                        <li><a href='https://www.playngo.com'><img src='../imgs/logo/playngo_logo_on_black.png' alt='logo11' /></a></li>
                        <li><a href='https://www.asiagaming.com'><img style={{ filter: "invert(1)" }} src='../imgs/logo/asiagaming.png' alt='logo1' /></a></li>
                        <li><a href='https://quickspin.com'><img src='../imgs/logo/quickspin-logo.png' alt='logo13' /></a></li>
                        <li><a href='https://relax-gaming.com'><img src='../imgs/logo/relax-gaming.png' alt='logo14' /></a></li>
                    </ul>
                </div>


                <div className="footer_info_txt">
                    <dl>
                        <dt>온라인 슬롯 커뮤니티 - PG슬롯</dt>
                        <dd>
                            PG슬롯은 최고의 슬롯 커뮤니티를 지향하며, 사용자의 이익과 만족을 위해 엄선된 온라인 슬롯사이트만을 추천합니다.
                            이곳에서는 100% 먹튀 보증제로 안전한 게임 환경을 제공하며, 다양한 '신규 온라인 슬롯'을 빠르게 업데이트합니다.
                            또한, '무료 슬롯 체험'을 통해 게임을 먼저 경험해 볼수 있습니다.
                        </dd>
                    </dl>
                    <dl>
                        <dt>PG슬롯 메뉴 (사이트맵)</dt>
                        <dd>
                            <ul>
                                {
                                    footerMenu.map((menu, index) => {
                                        return (

                                            <li key={index}><a href={menu.path} title={menu.name} className="menu">{menu.name}</a></li>
                                        )
                                    })
                                }
                            </ul>
                        </dd>
                    </dl>
                </div>
                <div className="sitemap anchor">
                    <ul>
                        {
                            anchorMenu.map((menu, index) => {
                                return (

                                    <li key={index}><a href={menu.path} title={menu.name} target='_blank' className="menu">{menu.name}</a></li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="site_info">
                    {/* <div className="logo"><img loading="lazy" src="../imgs/footer-logo.png" alt="PG슬롯" width="135" height="59" /></div> */}
                    <p>
                        Head Office : 16, Pangyoyeok-ro 192beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do Republic of Korea<br />
                        Australia Branch : Corner Pacific Highway and, Bryants Rd, Loganholme QLD 4129 Australia<br />
                        Business information : (c)PG SLOT 220-83-75493 | TEAM CITY | (bc)online service |
                        <a href="mailto:hello@PG SLOT.com?Subject=Hello%20again" target="_top">hello@PG SLOT.com</a>
                    </p>
                    <div className="telegram">
                        <ul>
                            <li><a href="https://t.me/PG SLOT" target="_blank" title="텔레그램문의"><i className="fab fa-telegram-plane" aria-hidden="true"></i> 텔레그램</a></li>
                            <li><Link to="/inquires" title="고객센터문의"><i className="fas fa-comments" aria-hidden="true"></i> 고객센터</Link></li>
                        </ul>
                    </div>
                </div>
                {/* <div className="sns">
                    <ul>
                        <li><Link to="https://twitter.com/PG SLOT4" title="PG슬롯 트위터 바로가기" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://PG SLOT3.blogspot.com/" title="PG슬롯 블로그스팟 바로가기" target="_blank"><i className="fab fa-blogger-b" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/uPMf6g" title="PG슬롯 레딧 바로가기" target="_blank"><i className="fab fa-reddit"></i></Link></li>
                        <li><Link to="https://medium.com/@tgbuff01/" title="PG슬롯 미디움 바로가기" target="_blank"><i className="fab fa-medium-m" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/8IrBrNa" title="PG슬롯 핀터레스트 바로가기" target="_blank"><i className="fab fa-pinterest" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://www.youtube.com/channel/UCTpJ7c4nxO6CS-fk8bl0UpQ?app=desktop" title="PG슬롯 유튜브 바로가기" target="_blank"><i className="fab fa-youtube" aria-hidden="true"></i></Link></li>
                    </ul>
                </div> */}
            </div>
            <div className="copyright">
                <div className="logo"><img loading="lazy" src="../imgs/footer-logo.png" alt="PG슬롯" width="135" height="59" /></div>
                <div className="sns">
                    <ul>
                        <li><Link to="https://twitter.com/PG SLOT4" title="PG슬롯 트위터 바로가기" target="_blank"><i className="fab fa-twitter" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://PG SLOT3.blogspot.com/" title="PG슬롯 블로그스팟 바로가기" target="_blank"><i className="fab fa-blogger-b" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/uPMf6g" title="PG슬롯 레딧 바로가기" target="_blank"><i className="fab fa-reddit"></i></Link></li>
                        <li><Link to="https://medium.com/@tgbuff01/" title="PG슬롯 미디움 바로가기" target="_blank"><i className="fab fa-medium-m" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://buly.kr/8IrBrNa" title="PG슬롯 핀터레스트 바로가기" target="_blank"><i className="fab fa-pinterest" aria-hidden="true"></i></Link></li>
                        <li><Link to="https://www.youtube.com/channel/UCTpJ7c4nxO6CS-fk8bl0UpQ?app=desktop" title="PG슬롯 유튜브 바로가기" target="_blank"><i className="fab fa-youtube" aria-hidden="true"></i></Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    );

}