import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { convertClass, convertTypeString, dateFormation, infiniteScroll, isEmpty } from '../../../Utils';
import { miniActions } from '../../../store';

export { MiniBettingHist };


const thead = [
    {
        name: "회차",
        value: ""
    },
    {
        name: "베팅시간",
        value: ""
    },
    {
        name: "게임명",
        value: ""
    },
    {
        name: "베팅금액",
        value: ""
    },
    {
        name: "배당",
        value: ""
    },
    {
        name: "적중금액",
        value: ""
    },
    {
        name: "베팅결과",
        value: ""
    },
]

function MiniBettingHist({ type }) {

    const dispatch = useDispatch();
    const bettingList = useSelector(state => state.mini.bettingList)
    const page = useSelector(state => state.page)

    useEffect(() => {
        infiniteScroll();
    }, [])

    useEffect(() => {
        GetMiniLists(1)
    }, [type])

    const GetMiniLists = (param) => {
        dispatch(miniActions.getBettingList({
            type: type,
            page: param,
            perPage: page.perPage,
        }))
    }

    return (
        <div className="bet_list">
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        {
                            thead.map((head, index) => {
                                return (<th key={index} style={{ whiteSpace: "nowrap" }}>{head.name}</th>)
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {!isEmpty(bettingList) ?
                        bettingList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.round}</td>
                                    <td>{dateFormation(item.created_date, 9)}</td>
                                    <td>{item.odd.name}{item.odd.name}</td>
                                    <td>{Number(item.balance.bet).toLocaleString()}</td>
                                    <td>{item.odd.rate}</td>
                                    <td>{Number(item.balance.win).toLocaleString()}</td>
                                    <td className={convertClass(item.type)}>{convertTypeString(item.type)}</td>
                                </tr>
                            )
                        }) :
                        <tr>
                            <td colSpan="7">내역이 없습니다.</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
