import { Fragment } from "react"
import { useSelector } from 'react-redux'

export { Spinner }

const Spinner = () => {
    const loading = useSelector(state => state.page.loading)
    return (
        <Fragment>
            <div id="preloader" className={loading ? "" : "d-none"} >
                <div className="loader">
                    <div className="sk-chase sk-primary">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                </div>
            </div>
            {/* <div id="preloader" className={loading ? "" : "d-none"} >
                <div className="loader">
                    <div className="sk-grid sk-primary">
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                        <div className="sk-grid-cube"></div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}