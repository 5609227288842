import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { resource_url } from '../../../Utils/keys';
import { dateFormation } from '../../../Utils';



export { TabBoardBox1 };
function TabBoardBox1({
    slotReviewLists,
    slotVideoLists,
    casinoInfoLists,
    cooperationReviewLists
}) {

    const [showSlot, setShowSlot] = useState("slotVideo");

    const onShowSlot = (type) => {
        setShowSlot(type)
    }

    return (
        <div className="tab_board_box tab_board_box1 mr10">
            <div className="tab_menu">
                <div className={`menu_list ${showSlot === "slotVideo" ? "active" : ""}`} rel="latest_01" onClick={() => onShowSlot("slotVideo")}>슬롯영상</div>
                <div className={`menu_list ${showSlot === "slotReview" ? "active" : ""}`} rel="latest_02" onClick={() => onShowSlot("slotReview")}>슬롯리뷰</div>
                <div className={`menu_list ${showSlot === "casinoInfo" ? "active" : ""}`} rel="latest_03" onClick={() => onShowSlot("casinoInfo")}>제휴사이트리뷰</div>
            </div>
            {showSlot === "slotVideo" &&
                <div>
                    <div className="board_conts" id="latest_01">

                        <div className="img_box">
                            {slotVideoLists &&
                                slotVideoLists.map((list, index) => {
                                    if (index > 2) {
                                        return;
                                    } else {
                                        return (
                                            <div className="best_list" key={index}>
                                                <Link to="/slotvideo" className="upper_link" title={list.title}>
                                                    <div className="num">N</div>
                                                    <div className="imgs">
                                                        <img loading="lazy" src={`${resource_url}/${list.titleImg}`} alt={list.title} title={list.title} className="img-responsive" />
                                                    </div>
                                                    <div className="txt">{list.title}</div>

                                                </Link>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                        <div className="normal_list_box">
                            {slotVideoLists &&
                                slotVideoLists.map((list, index) => {
                                    if (index < 3) {
                                        return;
                                    } else {
                                        return (
                                            <div className="normal_list" key={index}>
                                                <Link to="/slotvideo" className="upper_link" title={list.title}></Link>
                                                <div className="num">N</div>
                                                <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                                <div className="date">{dateFormation(list.created_date, 4)}</div>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                    </div>


                </div>
            }

            {showSlot === "slotReview" &&
                <div>
                    <div className="board_conts" id="latest_02">

                        <div className="img_box">
                            {slotReviewLists &&
                                slotReviewLists.map((list, index) => {
                                    if (index > 2) {
                                        return;
                                    } else {
                                        return (
                                            <div className="best_list" key={index}>
                                                <Link to="/slotgamereview" className="upper_link" title={list.title}>
                                                    <div className="num">N</div>
                                                    <div className="imgs">
                                                        <img loading="lazy" src={list.titleImg} alt={list.title} title={list.title} className="img-responsive" />
                                                    </div>
                                                    <div className="txt">{list.title}</div>

                                                </Link>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                        <div className="normal_list_box">
                            {slotReviewLists &&
                                slotReviewLists.map((list, index) => {
                                    if (index < 3) {
                                        return;
                                    } else {
                                        return (
                                            <div className="normal_list" key={index}>
                                                <Link to="/slotgamereview" className="upper_link" title={list.title}></Link>
                                                <div className="num">N</div>
                                                <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                                <div className="date">{dateFormation(list.created_date, 4)}</div>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                    </div>
                </div>
            }

            {showSlot === "casinoInfo" &&
                <div>
                    <div className="board_conts" id="latest_03">

                        <div className="img_box">
                            {cooperationReviewLists &&
                                cooperationReviewLists.map((list, index) => {
                                    if (index > 2) {
                                        return;
                                    } else {
                                        return (
                                            <div className="best_list" key={index}>
                                                <Link to="/friendsitereview" className="upper_link" title={list.title}>
                                                    <div className="num">N</div>
                                                    <div className="imgs">
                                                        <img loading="lazy" src={list.titleImg} alt={list.title} title={list.title} className="img-responsive" />
                                                    </div>
                                                    <div className="txt">{list.title}</div>

                                                </Link>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                        <div className="normal_list_box">
                            {cooperationReviewLists &&
                                cooperationReviewLists.map((list, index) => {
                                    if (index < 3) {
                                        return;
                                    } else {
                                        return (
                                            <div className="normal_list" key={index}>
                                                <Link to="/friendsitereview" className="upper_link" title={list.title}></Link>
                                                <div className="num">N</div>
                                                <div className="txt">{list.title}<span className="latest_comment_count"></span></div>
                                                <div className="date">{dateFormation(list.created_date, 4)}</div>
                                            </div>
                                        )
                                    }
                                })

                            }
                        </div>
                    </div>
                </div>
            }

        </div>
    );
}
