import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout';
import { FreeArticleContent } from '../../Components/Community';


export { FreeArticle };

function FreeArticle() {
    return (
        <Fragment>
            <Navbar type="freeArticle" />
            <div className='main'>
                <FreeArticleContent />
            </div>

        </Fragment>
    );
}