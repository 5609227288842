import React from 'react';

export { ContentWrapper };

function ContentWrapper({ children }) {
    return(
        <div className="main App">
            {children}
        </div>
    )
}