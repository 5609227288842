import { Register } from "../Components/Auth/Register";
import {
    Attendance,
    Inquire,
    Leave,
    MyInfo,
    MyLogs,
    MyPage,
    MyPost,
    MyRecommendation,
    MyScrap,
    Notice,
    Point,
    Roulette,
    Notification,
    PointChange,
    CouponHistory,
} from "../Containers/Account";
import { CasinoInfo, CasinoNews, SlotPhrase } from "../Containers/CasinoInfo";
import { BestReview, FollowArticle, FreeArticle, FriendSiteReview, SlotGameReview, SlotReviewVideo } from "../Containers/Community";
import { FreeSlot } from "../Containers/FreeSlot";
import { Home } from "../Containers/Home";
import { Baseball, EOS1, EOS2, EOS3, EOS4, EOS5, EVOPowerball, Nuguri, Soccer, Squidgame } from "../Containers/MiniGame";
import { PointMall } from "../Containers/Point/PointMall";
import { DieEvent, LiveEvent } from "../Containers/SiteEvent";
import { FakeSite, VerifiedSite } from "../Containers/VerifiedSite";


export const routers = [
    {
        pathname: '/',
        component: Home,
        private: false,
    },

    {
        pathname: '/verifiedsite',
        component: VerifiedSite,
        private: false,
    },

    {
        pathname: '/slotvideo',
        component: SlotReviewVideo,
        private: false,
    },

    {
        pathname: '/slotgamereview',
        component: SlotGameReview,
        private: false,
    },
    {
        pathname: '/friendsitereview',
        component: FriendSiteReview,
        private: false,
    },
    {
        pathname: '/bestreview',
        component: BestReview,
        private: false,
    },

    {
        pathname: '/freearticle',
        component: FreeArticle,
        private: false,
    },

    {
        pathname: '/followarticle',
        component: FollowArticle,
        private: false,
    },

    {
        pathname: '/liveevent',
        component: LiveEvent,
        private: false,
    },

    {
        pathname: '/dieevent',
        component: DieEvent,
        private: false,
    },

    {
        pathname: '/info',
        component: CasinoInfo,
        private: false,
    },

    {
        pathname: '/casinonews',
        component: CasinoNews,
        private: false,
    },

    {
        pathname: '/freeslot',
        component: FreeSlot,
        private: false,
    },

    {
        pathname: '/fakesite',
        component: FakeSite,
        private: false,
    },

    {
        pathname: '/pointmall',
        component: PointMall,
        private: false,
    },

    {
        pathname: '/inquires',
        component: Inquire,
        private: false,
    },

    {
        pathname: '/attendance',
        component: Attendance,
        private: true,
    },

    {
        pathname: '/mypage',
        component: MyPage,
        private: true,
    },

    {
        pathname: '/mypost',
        component: MyPost,
        private: true,
    },

    {
        pathname: '/point',
        component: Point,
        private: true,
    },

    {
        pathname: '/myrecommendation',
        component: MyRecommendation,
        private: true,
    },

    {
        pathname: '/myscrap',
        component: MyScrap,
        private: true,
    },

    {
        pathname: '/mylogs',
        component: MyLogs,
        private: true,
    },

    {
        pathname: '/myinfo',
        component: MyInfo,
        private: true,
    },

    {
        pathname: '/leave',
        component: Leave,
        private: true,
    },

    {
        pathname: '/minigame/EOS1',
        component: EOS1,
        private: true,
    },
    {
        pathname: '/minigame/EOS2',
        component: EOS2,
        private: true,
    },
    {
        pathname: '/minigame/EOS3',
        component: EOS3,
        private: true,
    },
    {
        pathname: '/minigame/EOS4',
        component: EOS4,
        private: true,
    },
    {
        pathname: '/minigame/EOS5',
        component: EOS5,
        private: true,
    },
    {
        pathname: '/minigame/baseball',
        component: Baseball,
        private: true,
    },
    {
        pathname: '/minigame/EVO',
        component: EVOPowerball,
        private: true,
    },
    {
        pathname: '/minigame/nuguri',
        component: Nuguri,
        private: true,
    },
    {
        pathname: '/minigame/soccer',
        component: Soccer,
        private: true,
    },
    {
        pathname: '/minigame/squidgame',
        component: Squidgame,
        private: true,
    },

    {
        pathname: '/minigame/roulette',
        component: Roulette,
        private: true,
    },

    {
        pathname: '/register',
        component: Register,
        private: false,
    },
    {
        pathname: '/notice',
        component: Notice,
        private: false,
    },
    {
        pathname: '/roulette',
        component: Roulette,
        private: true,
    },
    {
        pathname: '/notification',
        component: Notification,
        private: true,
    },
    {
        pathname: '/pointchange',
        component: PointChange,
        private: true,
    },
    {
        pathname: '/couponhistory',
        component: CouponHistory,
        private: true,
    },
    {
        pathname: '/dictionary',
        component: SlotPhrase,
        private: false,
    },
]